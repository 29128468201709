import { areAnyNil } from '../../app/utils/utils';
import { UserModel, UserModelArgs } from '../../domain/models/user_model';
import { ConvertionError } from '../errors/convertion_error';
import { IEntity } from '../interfaces/entity_interface';

export class UserEntity extends UserModel implements IEntity {
    _type = 'UserEntity';

    constructor({ id, username, email, name, surname, identificationNumber, businessName }: UserModelArgs) {
        super({ id, username, email, name, surname, identificationNumber, businessName });
    }

    toModel(): UserModel {
        return new UserModel(this);
    }

    static fromObject(obj: any): UserEntity {
        const { id, username, email, name, surname } = obj;
        const identification_number = obj.identificationNumber ? obj.identificationNumber : obj.identification_number;
        const business_name = obj.businessName ? obj.businessName : obj.business_name;
        if (areAnyNil([id, username, email, name, surname, identification_number, business_name])) {
            throw new ConvertionError(JSON.stringify(obj), 'UserEntity');
        }
        return new UserEntity({
            id,
            username,
            email,
            name,
            surname,
            identificationNumber: identification_number,
            businessName: business_name,
        });
    }
}
