import { AxiosInstance } from 'axios';
import { ValidationError } from '../../../../core/data/errors/validation_error';
import { IDataTableValidations } from '../../../../core/data/interfaces/datatable_validation_interface';
import { httpToError } from '../../../../core/data/utils/http_to_error';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IAxiosDataSource } from '../../../authentication/data/datasources/interfaces/axios_datasource_interface';
import { CouponEntity } from '../entities/coupon_entity';
import { ICouponValidations } from '../validations/coupon_validations_interface';
import { ICouponDataSource } from './interfaces/coupon_datasource_interface';

export class AxiosCouponDataSource implements IAxiosDataSource, ICouponDataSource {
    axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    async getUserCoupons(datatableParams: DataTableParams): Promise<DataTableResponse<CouponEntity>> {
        try {
            const { size, page, order, filters } = datatableParams;
            const { data } = await this.axiosInstance.get(`coupons`, {
                params: {
                    size,
                    page,
                    filters: JSON.stringify(filters),
                    order: JSON.stringify(order),
                },
            });
            return { data: data.data.map((item: any) => CouponEntity.fromObject(item)), total: data.total };
        } catch (error) {
            return Promise.reject(httpToError<IDataTableValidations>(error));
        }
    }
    /* istanbul ignore next */
    private entityToFormData(coupon: CouponEntity): FormData {
        const { title, description, price, tags, image, terms, finishDate, startDate, stock, category, store } = coupon;
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('price', price.toString());
        formData.append('image', image);
        formData.append('tags', tags);
        formData.append('terms', terms);
        formData.append('finish_date', finishDate);
        formData.append('start_date', startDate);
        formData.append('stock', stock.toString());
        formData.append('category_id', category.id.toString());
        formData.append('store_id', store.id.toString());
        return formData;
    }
    async createCoupon(coupon: CouponEntity): Promise<CouponEntity> {
        const formData = this.entityToFormData(coupon);

        try {
            const { data } = await this.axiosInstance.post('coupons', formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            });
            return CouponEntity.fromObject(data);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                return Promise.reject(
                    new ValidationError<ICouponValidations>({
                        ...error.response.data,
                        startDate: error.response.data.start_date,
                        finishDate: error.response.data.finish_date,
                        category: error.response.data.category_id,
                        store: error.response.data.store_id,
                    }),
                );
            }
            return Promise.reject(httpToError<ICouponValidations>(error));
        }
    }
    async updateCoupon(coupon: CouponEntity): Promise<CouponEntity> {
        const formData = this.entityToFormData(coupon);
        formData.append('_method', 'PUT');
        try {
            const { data } = await this.axiosInstance.post(`coupons/${coupon.id}`, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            });
            return CouponEntity.fromObject(data);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                return Promise.reject(
                    new ValidationError<ICouponValidations>({
                        ...error.response.data,
                        startDate: error.response.data.start_date,
                        finishDate: error.response.data.finish_date,
                        category: error.response.data.category_id,
                        store: error.response.data.store_id,
                    }),
                );
            }
            return Promise.reject(httpToError<ICouponValidations>(error));
        }
    }
    async deleteCoupon(ecopoint: CouponEntity): Promise<void> {
        const { id } = ecopoint;
        try {
            return await this.axiosInstance.delete(`coupons/${id}`);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                return Promise.reject(
                    new ValidationError<ICouponValidations>({
                        ...error.response.data,
                        startDate: error.response.data.start_date,
                        finishDate: error.response.data.finish_date,
                        category: error.response.data.category_id,
                        store: error.response.data.store_id,
                    }),
                );
            }
            return Promise.reject(httpToError<ICouponValidations>(error));
        }
    }
}
