import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { CategoryModel } from '../models/category_model';
import { ICategoryRepository } from '../repositories/category_repository_interface';

export class GetCategories implements IUseCase<CategoryModel[], void> {
    categoryRepository: ICategoryRepository;

    constructor(categoryRepository: ICategoryRepository) {
        this.categoryRepository = categoryRepository;
    }
    async execute(): Promise<CategoryModel[] | IFailure> {
        const result = await this.categoryRepository.getCategories();
        return Promise.resolve(result as IFailure);
    }
}
