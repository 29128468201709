import { Failure } from '../../../../core/domain/failures/failure';
import { Coordinates } from '../../../../core/domain/interfaces/coordinates_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { FormState } from '../../../../core/presentation/bloc/form_states/form_states';
import { ModalBloc } from '../../../../core/presentation/bloc/modal_bloc/modal_bloc';
import { ResidueTypeModel } from '../../../residues/domain/models/residue_type_model';
import { EcoPointModel } from '../../domain/models/ecopoint_model';
export type EcopointFormInputs = {
    ecopoint: EcoPointModel;
    residueTypes: ResidueTypeModel[];
};
export const emptyInputs: EcopointFormInputs = {
    ecopoint: new EcoPointModel({
        id: -1,
        name: '',
        address: '',
        coordinates: { lat: 0, lng: 0 },
        schedules: '',
        residueTypes: [],
    }),
    residueTypes: [],
};
export type EcopointFormState = FormState<EcopointFormInputs, EcoPointModel>;
export class EcoPointFormBloc extends ModalBloc<EcopointFormState> {
    createEcoPointUseCase: IUseCase<EcoPointModel, EcoPointModel>;
    updateEcoPointUseCase: IUseCase<EcoPointModel, EcoPointModel>;
    getResidueTypesUseCase: IUseCase<ResidueTypeModel[], void>;

    constructor(
        createEcoPointUseCase: IUseCase<EcoPointModel, EcoPointModel>,
        updateEcoPointUseCase: IUseCase<EcoPointModel, EcoPointModel>,
        getResidueTypesUseCase: IUseCase<ResidueTypeModel[], void>,
    ) {
        super({
            open: false,
            block: false,
            _type: 'Idle',
            inputs: emptyInputs,
            action: 'read',
        });
        this.createEcoPointUseCase = createEcoPointUseCase;
        this.updateEcoPointUseCase = updateEcoPointUseCase;
        this.getResidueTypesUseCase = getResidueTypesUseCase;
    }

    private async getEcoPointUseCase(action: string) {
        if (action === 'create') return await this.createEcoPointUseCase.execute(this.state.inputs.ecopoint);
        if (action === 'update') return await this.updateEcoPointUseCase.execute(this.state.inputs.ecopoint);
    }

    async submit(): Promise<void> {
        const { open, inputs, action } = this.state;
        if (action === 'read') return;
        this.changeState({ action, inputs, open, _type: 'Submitting', block: true });
        const result = await this.getEcoPointUseCase(action);
        if (result instanceof Failure) {
            this.changeState({ action, inputs, open, block: false, _type: 'Failure', failure: result });
            return;
        }
        if (result instanceof EcoPointModel)
            this.changeState({ action, inputs, open, block: false, _type: 'Success', data: result as EcoPointModel });
    }

    async open(): Promise<void> {
        this.openFor('create');
    }

    async openFor(action: 'read' | 'update' | 'create', data?: EcoPointModel) {
        if ((action === 'read' || action === 'update') && data !== undefined)
            this.changeState({
                _type: 'Loaded',
                open: true,
                block: false,
                inputs: { ecopoint: data, residueTypes: [] },
                action,
            });
        if (action === 'create')
            this.changeState({
                _type: 'Loaded',
                open: true,
                block: false,
                inputs: emptyInputs,
                action,
            });
    }

    async changeInput(
        input: 'name' | 'address' | 'schedules' | 'coordinates' | 'residueTypes',
        value: string | boolean | Coordinates | number[],
    ) {
        this.changeState({
            ...this.state,
            _type: 'InputChanged',
            inputs: { ...this.state.inputs, ecopoint: { ...this.state.inputs.ecopoint, [input]: value } },
        });
    }

    async fillSelectors(): Promise<void> {
        const residueTypesResponse = await this.getResidueTypesUseCase.execute();
        const residueTypes = residueTypesResponse instanceof Failure ? [] : residueTypesResponse;
        const prevState = this.state;
        this.changeState({
            ...prevState,
            inputs: { ...prevState.inputs, residueTypes: residueTypes as ResidueTypeModel[] },
        });
    }
}
