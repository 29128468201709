import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/es';
import { IDateFormatter } from './interfaces/date_formatter_interface';

export class MomentSpanishDateFormatter implements IDateFormatter {
    format(format: string, date: string): string {
        moment.locale('es');
        return moment(date).clone().tz('America/Argentina/Buenos_Aires').format(format);
    }
}
