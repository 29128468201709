import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: { width: '100%', padding: '1rem' },
        table: {
            maxWidth: 'calc( 100vw - 3rem)',
        },
    }),
);
