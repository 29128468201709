import { IModel } from '../../../../core/domain/interfaces/model_interface';

export type ResidueTypeModelArgs = {
    id: number;
    name: string;
};
export class ResidueTypeModel implements IModel {
    _type = 'ResidueTypeModel';
    id: number;
    name: string;

    constructor({ id, name }: ResidueTypeModelArgs) {
        (this.id = id), (this.name = name);
    }
}
