import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { EcoPointModel } from '../../../ecopoints/domain/models/ecopoint_model';
import { ICollectionRepository } from '../repositories/collection_repository_interface';

export class GetCachedEcoPoint implements IUseCase<EcoPointModel, void> {
    collectionRepository: ICollectionRepository;

    constructor(collectionRepository: ICollectionRepository) {
        this.collectionRepository = collectionRepository;
    }

    async execute(): Promise<EcoPointModel | IFailure> {
        return this.collectionRepository.getCachedEcoPoint();
    }
}
