import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { StoreModel } from '../../../stores/domain/models/store_model';
import { PurchaseModel } from '../models/purchase_model';
import { IPurchaseRepository } from '../repositories/purchase_repository_interface';

export type RedeemPurchaseParams = {
    purchaseCode: string;
    store: StoreModel;
};

export class RedeemPurchase implements IUseCase<PurchaseModel, RedeemPurchaseParams> {
    purchaseRepository: IPurchaseRepository;

    constructor(purchaseRepository: IPurchaseRepository) {
        this.purchaseRepository = purchaseRepository;
    }

    async execute({ purchaseCode, store }: RedeemPurchaseParams): Promise<PurchaseModel | IFailure> {
        return this.purchaseRepository.redeemPurchase({ purchaseCode, store });
    }
}
