import { AxiosInstance } from 'axios';
import { httpToError } from '../../../../core/data/utils/http_to_error';
import { IAxiosDataSource } from '../../../authentication/data/datasources/interfaces/axios_datasource_interface';
import { CategoryEntity } from '../entities/category_entity';
import { ICategoryDataSource } from './interfaces/category_datasource_interface';

export class AxiosCategoryDataSource implements ICategoryDataSource, IAxiosDataSource {
    axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    async getCategories(): Promise<CategoryEntity[]> {
        try {
            const { data } = await this.axiosInstance.get('categories');
            return Promise.resolve((data as Array<any>).map((item: any) => CategoryEntity.fromObject(item)));
        } catch (error) {
            return Promise.reject(httpToError<void>(error));
        }
    }
}
