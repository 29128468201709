import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { EcoPointModel } from '../../../ecopoints/domain/models/ecopoint_model';
import { CollectionModel } from '../models/collection_model';
import { ICollectionRepository } from '../repositories/collection_repository_interface';

export type ValidateCollectionsParams = {
    userCode: string;
    ecopoint: EcoPointModel;
};

export class ValidateCollections implements IUseCase<CollectionModel[], ValidateCollectionsParams> {
    collectionRepository: ICollectionRepository;

    constructor(collectionRepository: ICollectionRepository) {
        this.collectionRepository = collectionRepository;
    }

    async execute({ userCode, ecopoint }: ValidateCollectionsParams): Promise<CollectionModel[] | IFailure> {
        return this.collectionRepository.validateCollections({ userCode, ecopoint });
    }
}
