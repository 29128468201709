import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { StoreModel } from '../models/store_model';
import { IStoreRepository } from '../repositories/store_repository_interface';

export class GetProviders implements IUseCase<StoreModel[], void> {
    storeRepository: IStoreRepository;

    constructor(storeRepository: IStoreRepository) {
        this.storeRepository = storeRepository;
    }
    async execute(): Promise<StoreModel[] | IFailure> {
        const result = await this.storeRepository.getProviders();
        return Promise.resolve(result as IFailure);
    }
}
