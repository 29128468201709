export interface DataTableStrings {
    applyFilters: string;
    noMatch: string;
    sortToolTip: string;
    columnHeaderTooltip: string;
    nextPage: string;
    previousPage: string;
    rowsPerPage: string;
    displayRows: string;
    search: string;
    downloadCsv: string;
    print: string;
    viewColumns: string;
    filterTable: string;
    filterAll: string;
    filtersTitle: string;
    filterReset: string;
    columnsTitle: string;
    columnsTitleAria: string;
    rowsSelected: string;
    delete: string;
    deleteAria: string;
    booleanTrue: string;
    booleanFalse: string;
}

export const dataTableStrings: DataTableStrings = {
    applyFilters: 'Aplicar Filtros',
    noMatch: 'No se han encontrado resultados',
    sortToolTip: 'Ordenar',
    columnHeaderTooltip: `Ordernar por`,
    nextPage: 'Siguiente',
    previousPage: 'Anterior',
    rowsPerPage: 'Filas por página:',
    displayRows: 'de',
    search: 'Buscar',
    downloadCsv: 'Descargar CSV',
    print: 'Imprimir',
    viewColumns: 'Ocultar Columnas',
    filterTable: 'Filtrar Resultados',
    filterAll: 'Todo',
    filtersTitle: 'FILTROS',
    filterReset: 'LIMPIAR',
    columnsTitle: 'Ocultar Columnas',
    columnsTitleAria: 'Mostrar/Ocultar Columnas',
    rowsSelected: 'fila(s) seleccionada(s)',
    delete: 'Eliminar',
    deleteAria: 'Eliminar filas seleccionadas',
    booleanTrue: 'Si',
    booleanFalse: 'No',
};
