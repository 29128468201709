import { IFailure } from '../../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../../core/domain/interfaces/usecase_interface';
import { IAuthenticationRepository } from '../../repositories/interfaces/authentication_repository_interface';

export class RequestPasswordRecovery implements IUseCase<void, string> {
    repository: IAuthenticationRepository;

    constructor(repository: IAuthenticationRepository) {
        this.repository = repository;
    }

    execute(email: string): Promise<void | IFailure> {
        return this.repository.requestPasswordRecovery(email);
    }
}
