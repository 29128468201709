import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        app: {
            overflowX: 'hidden',
            minHeight: '100vh',
        },
    }),
);
