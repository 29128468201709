import { areAnyNil } from '../../../../core/app/utils/utils';
import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { IEntity } from '../../../../core/data/interfaces/entity_interface';
import { EcoPointModel, EcoPointModelArgs } from '../../domain/models/ecopoint_model';

export class EcoPointEntity extends EcoPointModel implements IEntity {
    _type = 'EcoPointEntity';

    constructor({ id, name, address, coordinates, schedules, residueTypes }: EcoPointModelArgs) {
        super({ id, name, address, coordinates, schedules, residueTypes });
    }

    toModel(): EcoPointModel {
        return new EcoPointModel(this);
    }
    static fromObject(obj: any) {
        const { id, name, address, coord_lat, coord_lng, schedules, residue_types } = obj;
        if (areAnyNil([id, name, address, coord_lat, coord_lng, schedules, residue_types])) {
            throw new ConvertionError(JSON.stringify(obj), 'EcoPointEntity');
        }
        return new EcoPointEntity({
            id,
            name,
            address,
            coordinates: { lat: coord_lat, lng: coord_lng },
            schedules,
            residueTypes: residue_types,
        });
    }
}
