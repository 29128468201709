import { areAnyNil } from '../../../../core/app/utils/utils';
import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { IEntity } from '../../../../core/data/interfaces/entity_interface';
import { IModel } from '../../../../core/domain/interfaces/model_interface';
import { ResidueModel, ResidueModelArgs } from '../../domain/models/residue_model';

type ResidueEntityArgs = {
    id: number;
};
export class ResidueEntity extends ResidueModel implements IEntity {
    _type = 'ResidueEntity';
    id: number;
    constructor({ id, name, image }: ResidueEntityArgs & ResidueModelArgs) {
        super({ name, image });
        this.id = id;
    }

    toModel(): IModel {
        return new ResidueModel(this);
    }

    static fromObject(obj: any): ResidueEntity {
        try {
            const { id, name, image } = obj;
            if (areAnyNil([id, name])) {
                throw new ConvertionError(JSON.stringify(obj), 'ResidueEntity');
            }
            return new ResidueEntity({
                id,
                name,
                image,
            });
        } catch (error) {
            throw new ConvertionError(JSON.stringify(obj), 'ResidueEntity');
        }
    }
}
