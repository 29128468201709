import { CacheFailure } from '../../../../core/domain/failures/cache_failure';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { GetStorePurchasesParams } from '../../domain/usecases/get_store_purchases';
import { ICacheDataSource } from '../../../authentication/data/datasources/interfaces/cache_datasource_interface';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IPurchaseDataSource } from '../datasources/interfaces/purchase_datasource_interface';
import { IPurchaseRepository } from '../../domain/repositories/purchase_repository_interface';
import { IRedeemPurchaseValidations } from '../validations/redeem_purchase_validations_Interface';
import { PurchaseEntity } from '../entities/purchase_entity';
import { PurchaseModel } from '../../domain/models/purchase_model';
import { RedeemPurchaseParams } from '../../domain/usecases/redeem_purchase';
import { StoreEntity } from '../../../stores/data/entities/store_entity';
import { StoreModel } from '../../../stores/domain/models/store_model';
import { UserEntity } from '../../../../core/data/entities/user_entity';
import { errorToFailure } from '../../../../core/data/utils/error_to_failure';

export class PurchaseRepository implements IPurchaseRepository {
    purchaseDataSource: IPurchaseDataSource;
    cacheDataSource: ICacheDataSource;

    constructor(purchaseDataSource: IPurchaseDataSource, cacheDataSource: ICacheDataSource) {
        this.purchaseDataSource = purchaseDataSource;
        this.cacheDataSource = cacheDataSource;
    }
    async getStorePurchases({
        store,
        datatableParams,
    }: GetStorePurchasesParams): Promise<DataTableResponse<PurchaseModel> | IFailure> {
        try {
            const result: DataTableResponse<PurchaseEntity> = await this.purchaseDataSource.getStorePurchases({
                store: new StoreEntity({ ...store, seller: new UserEntity({ ...store.seller }) }),

                datatableParams,
            });
            return { data: result.data.map((item) => item.toModel()), total: result.total };
        } catch (error) {
            return Promise.resolve(errorToFailure(error));
        }
    }

    async getCachedStore(): Promise<StoreModel | IFailure> {
        try {
            const result = await this.cacheDataSource.get('store');
            const entity = new StoreEntity({ ...result, seller: new UserEntity({ ...result.seller }) });
            return entity.toModel();
        } catch (error) {
            return Promise.resolve(new CacheFailure(error.message));
        }
    }
    async cacheStore(store: StoreModel): Promise<boolean | IFailure> {
        try {
            const result = await this.cacheDataSource.set(
                'store',
                new StoreEntity({ ...store, seller: new UserEntity({ ...store.seller }) }),
            );
            if (!result) return Promise.resolve(new CacheFailure(`Cannot save ${store} in cache`));
            return Promise.resolve(result);
        } catch (error) {
            return Promise.resolve(new CacheFailure(error.message));
        }
    }

    async redeemPurchase({ purchaseCode, store }: RedeemPurchaseParams): Promise<PurchaseModel | IFailure> {
        try {
            const result = await this.purchaseDataSource.redeemPurchase({
                purchaseCode,
                store: new StoreEntity({ ...store, seller: new UserEntity({ ...store.seller }) }),
            });
            return result.toModel();
        } catch (error) {
            return Promise.resolve(errorToFailure<IRedeemPurchaseValidations>(error));
        }
    }
}
