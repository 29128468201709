import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            maxWidth: '800px',
            margin: '0 auto',
            paddingTop: '1rem',
        },
        pb: {
            marginBottom: '1.5rem',
        },
        map: {
            height: 'calc(100vh - 472px)',
            marginBottom: '1rem',
        },
    }),
);
