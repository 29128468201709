import di from '../../../../core/app/dependency_injection/di';
import { areAnyNil } from '../../../../core/app/utils/utils';
import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { IEntity } from '../../../../core/data/interfaces/entity_interface';
import { StoreEntity } from '../../../stores/data/entities/store_entity';
import { CouponModel } from '../../domain/models/coupon_model';
import { CategoryEntity } from './category_entity';

type CouponEntityArgs = {
    id: number;
    title: string;
    tags: string;
    description: string;
    price: number;
    image: File | string;
    terms: string;
    startDate: string;
    finishDate: string;
    stock: number;
    store: StoreEntity;
    category: CategoryEntity;
};

export class CouponEntity implements IEntity {
    _type = 'CouponEntity';
    id: number;
    title: string;
    tags: string;
    description: string;
    price: number;
    image: File | string;
    terms: string;
    startDate: string;
    finishDate: string;
    stock: number;
    store: StoreEntity;
    category: CategoryEntity;

    constructor({
        id,
        title,
        description,
        price,
        tags,
        image,
        terms,
        finishDate,
        startDate,
        stock,
        category,
        store,
    }: CouponEntityArgs) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.price = price;
        this.image = image;
        this.terms = terms;
        this.tags = tags;
        this.startDate = startDate;
        this.finishDate = finishDate;
        this.stock = stock;
        this.store = store;
        this.category = category;
    }
    toModel(): CouponModel {
        return new CouponModel({
            ...this,
            category: this.category.toModel(),
            store: this.store.toModel(),
        });
    }

    static fromObject(obj: any): CouponEntity {
        const { id, title, description, price, tags, image, terms, finish_date, start_date, stock, category, store } =
            obj;
        if (
            areAnyNil([
                id,
                title,
                description,
                price,
                image,
                terms,
                finish_date,
                start_date,
                finish_date?.date,
                start_date?.date,
                stock,
                category,
                store,
            ])
        ) {
            throw new ConvertionError(JSON.stringify(obj), 'CouponEntity');
        }
        return new CouponEntity({
            id,
            title,
            description,
            price,
            tags: tags || '',
            image,
            terms,
            finishDate: di.dateFormatter().format('YYYY-MM-DD', finish_date.date),
            startDate: di.dateFormatter().format('YYYY-MM-DD', start_date.date),
            stock,
            category: CategoryEntity.fromObject(category),
            store: StoreEntity.fromObject(store),
        });
    }
}
