import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useAuthBloc } from '../../../../../core/presentation/contexts/contexts';
import { Language } from '../../../../../core/presentation/strings/LanguageManager';

type LogoutProps = {
    error?: boolean;
    errorType?: 'authorization';
};

export const Logout = ({ error, errorType }: LogoutProps) => {
    const authenticationBloc = useAuthBloc();
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        if (error) {
            switch (errorType) {
                case 'authorization':
                    enqueueSnackbar(Language.strings.loggedOut, { variant: 'error' });
                    break;
                default:
                    break;
            }
        }
        authenticationBloc.logout();
    }, []);
    return <></>;
};
