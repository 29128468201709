export interface CollectionStrings {
    collection: string;
    collections: string;
    userCode: string;
    collectionDetails: string;
    collectionResidue: string;
    collectionAmount: string;
    collectionCount: string;
    collectionSubtotal: string;
    collectionTotal: string;
    collectionState: string;
    collectionValidate: string;
    collectionValidationSuccess: string;
    //Errors
    collection_userCode_required_error: string;
    collection_userCode_exists_error: string;
    collection_ecopoint_required_error: string;
    collection_ecopoint_exists_error: string;
    collection_updateCollection_redeemed_collection_error: string;
}

export const collectionStrings: CollectionStrings = {
    collection: 'Recolección',
    collections: 'Recolecciones',
    userCode: 'Código del usuario',
    collectionValidate: 'Validar',
    collectionDetails: 'Detalles de Recolección',
    collectionResidue: 'Residuo',
    collectionAmount: 'Puntos x Unid.',
    collectionCount: 'Cantidad',
    collectionSubtotal: 'Total x Residuo',
    collectionTotal: 'Total',
    collectionState: 'Validado',
    collectionValidationSuccess: '¡Los residuos han sido validados correctamente!',
    //Errors
    collection_userCode_required_error: 'El campo Código del usuario es requerido.',
    collection_userCode_exists_error: 'El usuario con este código no existe.',
    collection_ecopoint_required_error: 'El campo EcoPunto es requerido',
    collection_ecopoint_exists_error: 'El EcoPunto seleccionado no existe',
    collection_updateCollection_redeemed_collection_error: 'Error: El Usuario no posee recolecciones para validar.',
};
