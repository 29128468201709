import React from 'react';
import di from '../../../../core/app/dependency_injection/di';
import { purchaseDataTableBlocContext, purchaseFormContext } from '../../../../core/presentation/contexts/contexts';
import { PurchasesDataTable } from '../components/purchase_datatable/purchase_datatable';
import { PurchaseForm } from '../components/purchase_form/purchase_form';

export const PurchasesPage = () => {
    const purchaseBloc = di.purchaseFormBloc();
    const purchaseDataTableBloc = di.purchasesDataTableBloc();
    return (
        <>
            <purchaseFormContext.Provider value={purchaseBloc}>
                <PurchaseForm />
                <purchaseDataTableBlocContext.Provider value={purchaseDataTableBloc}>
                    <PurchasesDataTable />
                </purchaseDataTableBlocContext.Provider>
            </purchaseFormContext.Provider>
        </>
    );
};
