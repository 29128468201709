import { Bloc } from '..';
import { Failure } from '../../../domain/failures/failure';
import { IUseCase } from '../../../domain/interfaces/usecase_interface';
import { DeletionState } from './deletion_state';

export class DeletionBloc<T> extends Bloc<DeletionState<T>> {
    deleteUseCase: IUseCase<void, T>;

    constructor(deleteUseCase: IUseCase<void, T>, initialState: DeletionState<T>) {
        super(initialState);
        this.deleteUseCase = deleteUseCase;
    }

    async askConfirmation(model: T): Promise<void> {
        this.changeState({ _type: 'WaitingConfirmation', model });
    }

    async denyConfirmation(): Promise<void> {
        this.changeState({ _type: 'Denied', model: undefined });
    }

    async delete(): Promise<void> {
        if (this.state.model) {
            this.changeState({ ...this.state, _type: 'Deleting' });
            const result = await this.deleteUseCase.execute(this.state.model);
            if (result instanceof Failure) {
                this.changeState({ ...this.state, _type: 'Failure', failure: result });
            } else {
                this.changeState({ ...this.state, _type: 'Success' });
            }
        }
    }
}
