export interface LoginStrings {
    login_failed: string;
    login_username_required_error: string;
    login_password_required_error: string;
    login_password_min_error: string;
    login: string;
    logout: string;
    forgot_password: string;
}

export const loginStrings: LoginStrings = {
    login_failed: 'Usuario o contraseña incorrectos',
    login_username_required_error: 'El campo usuario es requerido',
    login_password_required_error: 'El campo contraseña es requerido',
    login_password_min_error: 'El contraseña debe tener al menos 8 carateres',
    login: 'Acceder',
    logout: 'Cerrar Sesión',
    forgot_password: 'Cambiar Contraseña',
};
