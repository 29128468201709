import { useSnackbar } from 'notistack';
import React from 'react';
import di from '../../../../../core/app/dependency_injection/di';
import { AuthorizationFailure } from '../../../../../core/domain/failures/authorization_failure';
import { Failure } from '../../../../../core/domain/failures/failure';
import { ValidationFailure } from '../../../../../core/domain/failures/validation_failure';
import { DataTableSelection } from '../../../../../core/presentation/components/datatable/datatable';
import { DeletionConfirmationModal } from '../../../../../core/presentation/components/deletion_confirmation_modal/deletion_confirmation_modal';
import {
    storeDeletionContext,
    storeFormContext,
    storesDataTableContext,
} from '../../../../../core/presentation/contexts/contexts';
import { Language } from '../../../../../core/presentation/strings/LanguageManager';
import getErrorString from '../../../../../core/presentation/utils/get_error_string';
import { StoresDataTable } from '../../components/stores_datatable/stores_datatable';
import { StoreForm } from '../../components/store_form/store_form';

export const StoresPage = () => {
    const storesDatatableBloc = di.storesDatatableBloc();
    const storeFormBloc = di.storeFormBloc();
    const storeDeletionBloc = di.storeDeletionBloc();
    const { enqueueSnackbar } = useSnackbar();

    const handleEdit = ({ index, dataIndex }: DataTableSelection) => {
        if (storesDatatableBloc.state._type === 'Loaded') {
            storeFormBloc.openFor('update', storesDatatableBloc.state.data.data[dataIndex]);
        }
    };
    const handleAskConfirmation = ({ index, dataIndex }: DataTableSelection) => {
        if (storesDatatableBloc.state._type === 'Loaded') {
            storeDeletionBloc.askConfirmation(storesDatatableBloc.state.data.data[dataIndex]);
        }
    };
    const handleConfirmDelete = () => {
        storeDeletionBloc.delete();
        storesDatatableBloc.changeSelection([]);
    };
    const handleConfirmationDenied = () => {
        storeDeletionBloc.denyConfirmation();
    };
    const handleRead = ({ index, dataIndex }: DataTableSelection) => {
        if (storesDatatableBloc.state._type === 'Loaded') {
            storeFormBloc.openFor('read', storesDatatableBloc.state.data.data[dataIndex]);
        }
    };
    const handleDeletionFailure = (failure: Failure) => {
        if (failure instanceof ValidationFailure) {
            if (failure.fails.deleteStore)
                enqueueSnackbar(getErrorString('deleteStore', { _type: 'Failure', failure }, 'store'), {
                    variant: 'error',
                });
            if (failure.fails.id)
                enqueueSnackbar(getErrorString('id', { _type: 'Failure', failure }, 'store'), { variant: 'error' });
            return;
        }
        if (failure instanceof AuthorizationFailure) {
            enqueueSnackbar(Language.strings.loggedOut, { variant: 'error' });
            return;
        }
        enqueueSnackbar(Language.strings.unknown_error, { variant: 'error' });
    };
    const handleDeletionSuccess = () => {
        enqueueSnackbar(Language.strings.deletionSuccess, { variant: 'success' });
    };

    const handleCreate = () => {
        storeFormBloc.openFor('create');
    };

    return (
        <>
            <storeFormContext.Provider value={storeFormBloc}>
                <storeDeletionContext.Provider value={storeDeletionBloc}>
                    <storesDataTableContext.Provider value={storesDatatableBloc}>
                        <StoresDataTable
                            onRead={handleRead}
                            onEdit={handleEdit}
                            onDelete={handleAskConfirmation}
                            onCreate={handleCreate}
                        />
                    </storesDataTableContext.Provider>
                    <DeletionConfirmationModal
                        bloc={storeDeletionBloc}
                        onClose={handleConfirmationDenied}
                        onConfirm={handleConfirmDelete}
                        onFailure={handleDeletionFailure}
                        onSuccess={handleDeletionSuccess}
                    />
                </storeDeletionContext.Provider>
                <StoreForm />
            </storeFormContext.Provider>
        </>
    );
};
