export interface EcoPointsStrings {
    ecopoint: string;
    ecopoints: string;
    ecopointName: string;
    ecopointAddress: string;
    ecopointSchedules: string;
    ecopointUpdate: string;
    ecopointCreate: string;
    ecopointDelete: string;
    ecopointRead: string;
    ecopointCreateSuccessful: string;
    ecopointEditSuccessful: string;
    ecopointNamePlaceholder: string;
    ecopointSchedulePlaceholder: string;
    ecopointAddressPlaceholder: string;
    ecopointLocationLabel: string;
    ecopointResidueType: string;
    //Errors
    ecopoint_id_exists_error: string;
    ecopoint_name_required_error: string;
    ecopoint_address_required_error: string;
    ecopoint_coordinates_required_error: string;
    ecopoint_schedules_required_error: string;
    ecopoint_residueTypes_required_error: string;
    ecopoint_createEcopoint_exist_validator_error: string;
    ecopoint_updateEcopoint_exist_validator_error: string;
    ecopoint_deleteEcopoint_exist_validator_error: string;
    ecopoint_getUserEcopoints_exist_validator_error: string;
    ecopoint_createEcopoint_user_active_error: string;
    ecopoint_updateEcopoint_user_active_error: string;
    ecopoint_deleteEcopoint_user_active_error: string;
    ecopoint_getUserEcopoints_user_active_error: string;
    ecopoint_updateEcopoint_ownership_ecopoint_error: string;
    ecopoint_deleteEcopoint_ownership_ecopoint_error: string;
}

export const ecopointsStrings: EcoPointsStrings = {
    ecopoint: 'EcoPunto',
    ecopoints: 'EcoPuntos',
    ecopointName: 'Nombre',
    ecopointAddress: 'Dirección',
    ecopointSchedules: 'Horarios',
    ecopointUpdate: 'Editar',
    ecopointDelete: 'Eliminar',
    ecopointRead: 'Ver',
    ecopointCreate: 'Crear',
    ecopointCreateSuccessful: 'El EcoPunto ha sido creado correctamente',
    ecopointEditSuccessful: 'El EcoPunto ha sido editado correctamente',
    ecopointNamePlaceholder: 'Ej: Kiosko Las Talitas',
    ecopointAddressPlaceholder: 'Ej: Av. Siempre Viva 153',
    ecopointSchedulePlaceholder:
        'Ej:\nLunes - 8:00hs a 13:00hs y 15:00hs a 19:00hs \nMartes - 8:00hs a 13:00hs y 15:00hs a 19:00hs',
    ecopointLocationLabel: 'Seleccione su ubicación (arrastre el indicador a la ubicación del EcoPunto):',
    ecopointResidueType: 'Tipos de Residuo admitidos (puede marcar varios)',
    //Errors
    ecopoint_id_exists_error: 'Error: El EcoPunto no existe.',
    ecopoint_name_required_error: 'El campo Nombre es requerido.',
    ecopoint_address_required_error: 'El campo Dirección es requerido.',
    ecopoint_coordinates_required_error: 'No se pudo obtener las coordenadas.',
    ecopoint_schedules_required_error: 'El campo Horarios es requerido.',
    ecopoint_residueTypes_required_error: 'El campo Tipos de Residuo admitidos es requerido.',
    ecopoint_createEcopoint_exist_validator_error: 'Error: La cuenta de validador no existe.',
    ecopoint_deleteEcopoint_exist_validator_error: 'Error: La cuenta de validador no existe.',
    ecopoint_updateEcopoint_exist_validator_error: 'Error: La cuenta de validador no existe.',
    ecopoint_getUserEcopoints_exist_validator_error: 'Error: La cuenta de validador no existe.',
    ecopoint_createEcopoint_user_active_error: 'Error: Su cuenta está inactiva',
    ecopoint_updateEcopoint_user_active_error: 'Error: Su cuenta está inactiva',
    ecopoint_deleteEcopoint_user_active_error: 'Error: Su cuenta está inactiva',
    ecopoint_getUserEcopoints_user_active_error: 'Error: Su cuenta está inactiva',
    ecopoint_updateEcopoint_ownership_ecopoint_error: 'Error: El EcoPunto seleccionado no le pertenece.',
    ecopoint_deleteEcopoint_ownership_ecopoint_error: 'Error: El EcoPunto seleccionado no le pertenece.',
};
