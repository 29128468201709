import { AxiosInstance } from 'axios';
import { ValidationError } from '../../../../core/data/errors/validation_error';
import { IDataTableValidations } from '../../../../core/data/interfaces/datatable_validation_interface';
import { httpToError } from '../../../../core/data/utils/http_to_error';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IAxiosDataSource } from '../../../authentication/data/datasources/interfaces/axios_datasource_interface';
import { PurchaseEntity } from '../entities/purchase_entity';
import { IGetStorePurchasesValidations } from '../validations/get_store_purchases_validations';
import { IRedeemPurchaseValidations } from '../validations/redeem_purchase_validations_Interface';
import {
    GetStorePurchasesParams,
    IPurchaseDataSource,
    RedeemPurchaseParams,
} from './interfaces/purchase_datasource_interface';

export class AxiosPurchaseDataSource implements IPurchaseDataSource, IAxiosDataSource {
    axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }
    async getStorePurchases({
        store,
        datatableParams,
    }: GetStorePurchasesParams): Promise<DataTableResponse<PurchaseEntity>> {
        try {
            const { size, page, order, filters } = datatableParams;
            const { data } = await this.axiosInstance.get(`stores/${store.id}/purchases`, {
                params: {
                    size,
                    page,
                    filters: JSON.stringify(filters),
                    order: JSON.stringify(order),
                },
            });
            return { data: data.data.map((item: any) => PurchaseEntity.fromObject(item)), total: data.total };
        } catch (error) {
            return Promise.reject(httpToError<IDataTableValidations | IGetStorePurchasesValidations>(error));
        }
    }

    async redeemPurchase({ purchaseCode, store }: RedeemPurchaseParams): Promise<PurchaseEntity> {
        try {
            const { data } = await this.axiosInstance.post(`purchases/${purchaseCode}`, {
                _method: 'PUT',
                store_id: store.id,
            });
            return Promise.resolve(PurchaseEntity.fromObject(data));
        } catch (error) {
            if (error.response && error.response.status === 400) {
                return Promise.reject(
                    new ValidationError<IRedeemPurchaseValidations>({
                        ...error.response.data,
                        purchaseCode: error.response.data.code,
                        store: error.response.data.store_id,
                    }),
                );
            }
            return Promise.reject(httpToError<IRedeemPurchaseValidations>(error));
        }
    }
}
