import { Coordinates } from '../../../../core/domain/interfaces/coordinates_interface';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { Failure } from '../../../../core/domain/failures/failure';
import { FormState } from '../../../../core/presentation/bloc/form_states/form_states';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { ModalBloc } from '../../../../core/presentation/bloc/modal_bloc/modal_bloc';
import { StoreModel } from '../../domain/models/store_model';
import { UserModel } from '../../../../core/domain/models/user_model';

type StoreFormInputs = { store: StoreModel; mainStores: StoreModel[]; providerStores: StoreModel[] };

export const emptyInputs: StoreFormInputs = {
    store: new StoreModel({
        id: -1,
        name: '',
        address: '',
        coordinates: { lat: 0, lng: 0 },
        phoneNumber: '',
        receiveCoupons: false,
        mainStores: [],
        providerStores: [],
        isProvider: false,
        seller: new UserModel({
            id: -1,
            username: '',
            name: '',
            email: '',
            surname: '',
            identificationNumber: 0,
            businessName: '',
        }),
    }),
    mainStores: [],
    providerStores: [],
};

export type StoreFormState = FormState<StoreFormInputs, StoreModel>;
export class StoreFormBloc extends ModalBloc<StoreFormState> {
    createStoreUseCase: IUseCase<StoreModel, StoreModel>;
    updateStoreUseCase: IUseCase<StoreModel, StoreModel>;
    getUserStoresUseCase: IUseCase<DataTableResponse<StoreModel>, DataTableParams>;
    getProvidersUseCase: IUseCase<StoreModel[], void>;

    constructor(
        createStoreUseCase: IUseCase<StoreModel, StoreModel>,
        updateStoreUseCase: IUseCase<StoreModel, StoreModel>,
        getUserStoresUseCase: IUseCase<DataTableResponse<StoreModel>, DataTableParams>,
        getProvidersUseCase: IUseCase<StoreModel[], void>,
    ) {
        super({
            open: false,
            block: false,
            _type: 'Idle',
            inputs: emptyInputs,
            action: 'read',
        });
        this.createStoreUseCase = createStoreUseCase;
        this.updateStoreUseCase = updateStoreUseCase;
        this.getUserStoresUseCase = getUserStoresUseCase;
        this.getProvidersUseCase = getProvidersUseCase;
    }

    private async getStoreUseCase(action: 'create' | 'update'): Promise<StoreModel | IFailure> {
        if (action === 'update') return await this.updateStoreUseCase.execute(this.state.inputs.store);
        return await this.createStoreUseCase.execute(this.state.inputs.store);
    }

    async submit(): Promise<void> {
        const { open, inputs, action } = this.state;
        if (action !== 'create' && action !== 'update') return;
        this.changeState({ action, inputs, open, _type: 'Submitting', block: true });
        const result = await this.getStoreUseCase(action);
        if (result instanceof Failure) {
            this.changeState({ action, inputs, open, block: false, _type: 'Failure', failure: result });
            return;
        }
        this.changeState({ action, inputs, open, block: false, _type: 'Success', data: result as StoreModel });
    }

    async open(): Promise<void> {
        this.openFor('create');
    }

    async openFor(action: 'read' | 'update' | 'create', data?: StoreModel) {
        if ((action === 'read' || action === 'update') && data !== undefined)
            this.changeState({
                _type: 'Loaded',
                open: true,
                block: false,
                inputs: { ...this.state.inputs, store: data },
                action,
            });
        if (action === 'create')
            this.changeState({
                _type: 'Loaded',
                open: true,
                block: false,
                inputs: emptyInputs,
                action,
            });
    }

    async changeInput(
        input:
            | 'name'
            | 'address'
            | 'phoneNumber'
            | 'receiveCoupons'
            | 'coordinates'
            | 'mainStores'
            | 'providerStores'
            | 'isProvider',
        value: string | boolean | Coordinates | number[],
    ) {
        this.changeState({
            ...this.state,
            _type: 'InputChanged',
            inputs: { ...this.state.inputs, store: { ...this.state.inputs.store, [input]: value } },
        });
    }

    async fillSelectors(): Promise<void> {
        const { store } = this.state.inputs;
        const providersResponse = await this.getProvidersUseCase.execute();
        const provider_stores: StoreModel[] =
            providersResponse instanceof Failure
                ? []
                : (providersResponse as StoreModel[]).filter((s) => s.id !== store.id);
        const storesResponse = await this.getUserStoresUseCase.execute({
            filters: [],
            order: undefined,
            page: 0,
            size: 0,
        });
        const stores: StoreModel[] =
            storesResponse instanceof Failure
                ? []
                : (storesResponse as DataTableResponse<StoreModel>).data.filter((s) => s.id !== store.id);

        this.changeState({
            ...this.state,
            inputs: { ...this.state.inputs, mainStores: stores, providerStores: provider_stores },
        });
    }
}
