import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { EcoPointModel } from '../models/ecopoint_model';
import { IEcoPointRepository } from '../repositories/ecopoint_repository_interface';

export class UpdateEcoPoint implements IUseCase<EcoPointModel, EcoPointModel> {
    ecopointRepository: IEcoPointRepository;

    constructor(ecopointRepository: IEcoPointRepository) {
        this.ecopointRepository = ecopointRepository;
    }

    execute(ecopoint: EcoPointModel): Promise<EcoPointModel | IFailure> {
        return this.ecopointRepository.updateEcoPoint(ecopoint);
    }
}
