import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { CouponModel } from '../models/coupon_model';
import { ICouponRepository } from '../repositories/coupon_repository_interface';

export class GetUserCoupons implements IUseCase<DataTableResponse<CouponModel>, DataTableParams> {
    couponRepository: ICouponRepository;

    constructor(couponRepository: ICouponRepository) {
        this.couponRepository = couponRepository;
    }

    async execute(datatableParams: DataTableParams): Promise<DataTableResponse<CouponModel> | IFailure> {
        const result = await this.couponRepository.getUserCoupons(datatableParams);
        return Promise.resolve(result as IFailure);
    }
}
