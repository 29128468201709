import { areAnyNil } from '../../../../core/app/utils/utils';
import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { IEntity } from '../../../../core/data/interfaces/entity_interface';
import { Coordinates } from '../../../../core/domain/interfaces/coordinates_interface';
import { StoreModel } from '../../domain/models/store_model';
import { UserEntity } from './../../../../core/data/entities/user_entity';

export type StoreEntityArgs = {
    id: number;
    name: string;
    address: string;
    coordinates: Coordinates;
    phoneNumber: string;
    receiveCoupons: boolean;
    mainStores: number[];
    providerStores: number[];
    isProvider: boolean;
    seller: UserEntity;
};

export class StoreEntity implements IEntity {
    _type = 'StoreEntity';

    id: number;
    name: string;
    address: string;
    coordinates: Coordinates;
    phoneNumber: string;
    receiveCoupons: boolean;
    mainStores: number[];
    providerStores: number[];
    isProvider: boolean;
    seller: UserEntity;

    constructor({
        id,
        name,
        address,
        coordinates,
        phoneNumber,
        receiveCoupons,
        mainStores,
        providerStores,
        isProvider,
        seller,
    }: StoreEntityArgs) {
        this.id = id;
        this.name = name;
        this.address = address;
        this.coordinates = coordinates;
        this.phoneNumber = phoneNumber;
        this.receiveCoupons = receiveCoupons;
        this.mainStores = mainStores;
        this.providerStores = providerStores;
        this.isProvider = isProvider;
        this.seller = seller;
    }

    toModel(): StoreModel {
        return new StoreModel({ ...this, seller: this.seller.toModel() });
    }
    static fromObject(obj: any) {
        const {
            id,
            name,
            address,
            coord_lat,
            coord_lng,
            phonenumber,
            receive_coupons,
            main_stores,
            provider_stores,
            is_provider,
            seller,
        } = obj;
        if (areAnyNil([id, name, address, coord_lat, coord_lng, phonenumber, receive_coupons, seller])) {
            throw new ConvertionError(JSON.stringify(obj), 'StoreEntity');
        }
        return new StoreEntity({
            id,
            name,
            address,
            coordinates: { lat: coord_lat, lng: coord_lng },
            phoneNumber: phonenumber,
            receiveCoupons: receive_coupons,
            mainStores: main_stores,
            providerStores: provider_stores,
            isProvider: is_provider || false,
            seller: UserEntity.fromObject(seller),
        });
    }
}
