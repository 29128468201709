import { ICacheDataSource } from './interfaces/cache_datasource_interface';

export class SessionStorageCacheDataSource implements ICacheDataSource {
    async clear(): Promise<void> {
        await sessionStorage.clear();
    }
    async get(key: string): Promise<any> {
        try {
            const result = await window.sessionStorage.getItem(key);
            if (result) return JSON.parse(result);
            return undefined;
        } catch (error) {
            return undefined;
        }
    }
    async set(key: string, value: any): Promise<boolean> {
        try {
            await window.sessionStorage.setItem(key, JSON.stringify(value));
            return true;
        } catch (error) {
            return false;
        }
    }
}
