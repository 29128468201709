import { CategoryEntity } from '../entities/category_entity';
import { CouponEntity } from '../entities/coupon_entity';
import { CouponModel } from '../../domain/models/coupon_model';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { ICouponDataSource } from '../datasources/interfaces/coupon_datasource_interface';
import { ICouponRepository } from '../../domain/repositories/coupon_repository_interface';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { StoreEntity } from '../../../stores/data/entities/store_entity';
import { UserEntity } from '../../../../core/data/entities/user_entity';
import { errorToFailure } from '../../../../core/data/utils/error_to_failure';

export class CouponRepository implements ICouponRepository {
    apiDataSource: ICouponDataSource;

    constructor(apiDataSource: ICouponDataSource) {
        this.apiDataSource = apiDataSource;
    }

    async getUserCoupons(datatableParams: DataTableParams): Promise<DataTableResponse<CouponModel> | IFailure> {
        try {
            const result: DataTableResponse<CouponEntity> = await this.apiDataSource.getUserCoupons(datatableParams);
            return { data: result.data.map((item) => item.toModel()), total: result.total };
        } catch (error) {
            return Promise.resolve(errorToFailure(error));
        }
    }
    async createCoupon(coupon: CouponModel): Promise<CouponModel | IFailure> {
        try {
            const entity: CouponEntity = await this.apiDataSource.createCoupon(
                new CouponEntity({
                    ...coupon,
                    store: new StoreEntity({ ...coupon.store, seller: new UserEntity({ ...coupon.store.seller }) }),
                    category: new CategoryEntity(coupon.category),
                }),
            );
            return entity.toModel();
        } catch (error) {
            return Promise.resolve(errorToFailure(error));
        }
    }
    async updateCoupon(coupon: CouponModel): Promise<CouponModel | IFailure> {
        try {
            const entity: CouponEntity = await this.apiDataSource.updateCoupon(
                new CouponEntity({
                    ...coupon,
                    store: new StoreEntity({ ...coupon.store, seller: new UserEntity({ ...coupon.store.seller }) }),
                    category: new CategoryEntity(coupon.category),
                }),
            );
            return entity.toModel();
        } catch (error) {
            return Promise.resolve(errorToFailure(error));
        }
    }
    async deleteCoupon(coupon: CouponModel): Promise<void | IFailure> {
        try {
            return await this.apiDataSource.deleteCoupon(
                new CouponEntity({
                    ...coupon,
                    store: new StoreEntity({ ...coupon.store, seller: new UserEntity({ ...coupon.store.seller }) }),
                    category: new CategoryEntity(coupon.category),
                }),
            );
        } catch (error) {
            return Promise.resolve(errorToFailure(error));
        }
    }
}
