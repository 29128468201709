import { Button, Checkbox, Grid, LinearProgress, Paper, TextField } from '@material-ui/core';
import _ from 'lodash';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions, MUIDataTableTextLabels } from 'mui-datatables';
import React from 'react';
import di from '../../../app/dependency_injection/di';
import {
    DataTableColumnType,
    DataTableFilter,
    DataTableOrder,
} from '../../../domain/interfaces/datatable_params_interface';
import { Language } from '../../strings/LanguageManager';
import { useStyles } from './styles';

export type DataTableColumn = {
    name: string;
    label: string;
    db?: string;
    options?: {
        filter: boolean;
        sort: boolean;
    };
    type?: DataTableColumnType;
};

export type DataTableProps = {
    data: any;
    columns: DataTableColumn[];
    total: number;
    page?: number;
    isLoading?: boolean;
    onRowSelectionChange?: (selected: any) => void;
    onColumnSortChange?: (order: DataTableOrder) => void;
    onChangePage?: (page: number) => void;
    onChangeRowsPerPage?: (size: number) => void;
    onFilterChange?: (filters: DataTableFilter[]) => void;
    selectionActions?: React.ReactElement;
    toolbarActions?: React.ReactElement;
    selection?: number[];
};

export type DataTableSelection = {
    index: number;
    dataIndex: number;
};

const drawBool = (value: boolean) => {
    return <Checkbox checked={value} />;
};

const drawDate = (value: string) => {
    return <span>{di.dateFormatter().format('DD/MM/YYYY', value)}</span>;
};

const drawDatePicker = (
    filterList: string[][],
    onChange: (val: string | string[], index: number, column: MUIDataTableColumn) => void,
    index: number,
    column: MUIDataTableColumn,
    filterData: string[][],
) => {
    return (
        <TextField
            fullWidth
            type="date"
            label={column.label}
            value={filterList[index] || undefined}
            onChange={(event) => {
                filterList[index] = [event.target.value];
                onChange(filterList[index], index, column);
            }}
            InputLabelProps={{ shrink: true }}
        />
    );
};
const drawNumericInput = (
    filterList: string[][],
    onChange: (val: string | string[], index: number, column: MUIDataTableColumn) => void,
    index: number,
    column: MUIDataTableColumn,
    filterData: string[][],
) => {
    return (
        <TextField
            fullWidth
            type="number"
            label={column.label}
            value={filterList[index] || undefined}
            onChange={(event) => {
                filterList[index] = [event.target.value];
                onChange(filterList[index], index, column);
            }}
            InputLabelProps={{ shrink: true }}
        />
    );
};

const localization: MUIDataTableTextLabels = {
    body: {
        noMatch: Language.strings.noMatch,
        toolTip: Language.strings.sortToolTip,
        columnHeaderTooltip: (column) => `${Language.strings.columnHeaderTooltip} ${column.label}`,
    },
    pagination: {
        next: Language.strings.nextPage,
        previous: Language.strings.previousPage,
        rowsPerPage: Language.strings.rowsPerPage,
        displayRows: Language.strings.displayRows,
    },
    toolbar: {
        search: Language.strings.search,
        downloadCsv: Language.strings.downloadCsv,
        print: Language.strings.print,
        viewColumns: Language.strings.viewColumns,
        filterTable: Language.strings.filterTable,
    },
    filter: {
        all: Language.strings.filterAll,
        title: Language.strings.filtersTitle,
        reset: Language.strings.filterReset,
    },
    viewColumns: {
        title: Language.strings.columnsTitle,
        titleAria: Language.strings.columnsTitleAria,
    },
    selectedRows: {
        text: Language.strings.rowsSelected,
        delete: Language.strings.delete,
        deleteAria: Language.strings.deleteAria,
    },
};

export const DataTable: React.FC<DataTableProps> = ({
    data,
    columns,
    total,
    page,
    isLoading = false,
    onRowSelectionChange,
    onColumnSortChange,
    onChangePage,
    onChangeRowsPerPage,
    onFilterChange,
    selectionActions,
    toolbarActions,
    selection = [],
}: DataTableProps) => {
    const classes = useStyles();
    const options: MUIDataTableOptions = {
        selectableRows: 'single',
        selectableRowsOnClick: true,
        rowsSelected: selection,
        serverSide: true,
        filterType: 'textField',
        confirmFilters: true,
        fixedHeader: true,
        count: total || 0,
        tableBodyHeight: `calc(100vh - ${selection.length === 0 ? '280px' : '227px'})`,
        elevation: 0,
        search: false,
        download: false,
        page: page || 0,
        pagination: selection.length === 0,
        rowsPerPageOptions: [10, 15, 20],
        draggableColumns: { enabled: true },
        enableNestedDataAccess: '.',
        customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
            const footer = () => {
                return (
                    <div style={{ marginTop: '40px' }}>
                        <Button variant="contained" onClick={() => (applyNewFilters ? applyNewFilters() : undefined)}>
                            {Language.strings.applyFilters}
                        </Button>
                    </div>
                );
            };
            return footer();
        },
        onRowSelectionChange: (current, all, selected) => {
            if (onRowSelectionChange) onRowSelectionChange(all.map((item) => item.dataIndex));
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (onColumnSortChange)
                onColumnSortChange({
                    column: columns.find((item) => item.name === changedColumn)?.db || changedColumn,
                    direction,
                });
        },
        onChangePage: (currentPage: number) => {
            if (onChangePage) onChangePage(currentPage);
        },
        onChangeRowsPerPage: (numberOfRows: number) => {
            if (onChangeRowsPerPage) onChangeRowsPerPage(numberOfRows);
        },
        onDownload: () => {
            return false;
        },
        onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
            const filters = filterList
                .map((item, index) => {
                    let value: string | number | boolean = item[0];
                    if (item[0] !== undefined) {
                        if (columns[index].type === 'bool') value = item[0] === Language.strings.booleanTrue;
                        if (columns[index].type === 'number') value = parseInt(item[0]);
                    }
                    return {
                        column: columns[index].db || columns[index].name,
                        value,
                        type: columns[index].type || 'text',
                    };
                })
                .filter((filter) => {
                    if (filter.type === 'text') return !_.isEmpty(filter.value) && !_.isNil(filter.value);
                    return !_.isNil(filter.value);
                });
            if (onFilterChange) onFilterChange(filters);
        },
        textLabels: localization,
    };

    const SelectionToolbar = (props: any) => (
        <Grid container className={classes.selectionToolbar} alignItems="center" justify="space-between">
            <Grid item>{`${props.selectedRows.data.length} ${Language.strings.rowsSelected}`}</Grid>
            <Grid container item style={{ width: 'unset' }} justify="flex-end" alignItems="center">
                {React.cloneElement(selectionActions as React.ReactElement<any>, props.selectedRows.data[0])}
            </Grid>
        </Grid>
    );

    const _columns: MUIDataTableColumn[] = [
        ...columns.map((c) => {
            const newColumn: MUIDataTableColumn = c;
            if (c.type === 'bool') {
                newColumn.options = {
                    ...newColumn.options,
                    customBodyRender: drawBool,
                    customFilterListOptions: {
                        render: (value) => {
                            return `${c.label} - ${value}`;
                        },
                    },
                    filterType: 'dropdown',
                    filterOptions: {
                        names: [Language.strings.booleanTrue, Language.strings.booleanFalse],
                    },
                };
            }
            drawNumericInput;
            if (c.type === 'date' || c.type === 'minDate' || c.type === 'maxDate')
                newColumn.options = {
                    ...newColumn.options,
                    customBodyRender: drawDate,
                    customFilterListOptions: {
                        render: drawDate,
                    },
                    filterType: 'custom',
                    filterOptions: {
                        display: drawDatePicker,
                    },
                };
            if (c.type === 'number')
                newColumn.options = {
                    ...newColumn.options,
                    filterType: 'custom',
                    filterOptions: {
                        display: drawNumericInput,
                    },
                };
            return newColumn;
        }),
    ];
    return (
        <Paper className={`${classes.paper} ${data.length === 0 || total === 0 ? classes.empty : ''}`}>
            {isLoading ? <LinearProgress /> : null}
            <MUIDataTable
                title={toolbarActions}
                data={data}
                columns={_columns}
                options={options}
                components={{
                    TableToolbarSelect: SelectionToolbar,
                }}
            />
        </Paper>
    );
};
