import { Coordinates } from '../../../../core/domain/interfaces/coordinates_interface';
import { IModel } from '../../../../core/domain/interfaces/model_interface';

export type EcoPointModelArgs = {
    id: number;
    name: string;
    address: string;
    coordinates: Coordinates;
    schedules: string;
    residueTypes: number[];
};

export class EcoPointModel implements IModel {
    _type = 'EcoPointModel';

    id: number;
    name: string;
    address: string;
    coordinates: Coordinates;
    schedules: string;
    residueTypes: number[];

    constructor({ id, name, address, coordinates, schedules, residueTypes }: EcoPointModelArgs) {
        this.id = id;
        this.name = name;
        this.address = address;
        this.coordinates = coordinates;
        this.schedules = schedules;
        this.residueTypes = residueTypes;
    }
}
