import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import React from 'react';
import di from '../../../../../core/app/dependency_injection/di';
import { passwordRecoveryContext } from '../../../../../core/presentation/contexts/contexts';
import { Language } from '../../../../../core/presentation/strings/LanguageManager';
import { PasswordRecoveryRequestForm } from '../../components/password_recovery_request_form/password_recovery_request_form';
import { useStyles } from './styles';

export const RequestPasswordRecoveryPage = () => {
    const classes = useStyles();
    return (
        <Grid container className={classes.container} justify="center" alignItems="center">
            <Card className={classes.paper} elevation={5}>
                <CardHeader title={Language.strings.recover_password} />
                <CardContent>
                    <passwordRecoveryContext.Provider value={di.passwordRecoveryBloc()}>
                        <PasswordRecoveryRequestForm />
                    </passwordRecoveryContext.Provider>
                </CardContent>
            </Card>
        </Grid>
    );
};
