import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { authContext, useAuthBloc } from '../contexts/contexts';

import { AuthenticationState } from '../../../features/authentication/presentation/blocs/authentication_bloc/authentication_bloc_states';
import { BlocBuilder } from '../bloc';
import { Content } from '../components/content/content';
import { Dashboard } from '../../../features/dashboard/presentation/components/dashboard/dashboard';
import { NotFound } from '../pages/not_found/not_found';
import di from '../../app/dependency_injection/di';
import { routes } from './auth_routes';

const byPassAuth = process.env.REACT_APP_BYPASS_AUTH;

export const AuthorizerContainer: React.FC = () => {
    return (
        <authContext.Provider value={di.authBloc()}>
            <Authorizer />
        </authContext.Provider>
    );
};
export const Authorizer: React.FC = () => {
    const authenticationBloc = useAuthBloc();
    useEffect(() => {
        authenticationBloc.getAuthenticatedUser();
    }, []);
    if (byPassAuth === 'true') return <Routes />;
    return (
        <BlocBuilder
            bloc={authenticationBloc}
            builder={(state: AuthenticationState) => {
                if (state._type === 'LoggedOut') return <Redirect to="/" />;
                if (state._type === 'Loaded') return <Routes />;
                return <></>;
            }}
        />
    );
};

const Routes = () => {
    return (
        <Dashboard>
            <Switch>
                {routes
                    .filter((item) => item.type === 'url')
                    .map((item) => (
                        <Route key={item.url} exact path={item.url}>
                            <Content title={item.contentTitle}>{item.component}</Content>
                        </Route>
                    ))}
                <Route component={NotFound} />
            </Switch>
        </Dashboard>
    );
};
