import React from 'react';
import di from '../../../../core/app/dependency_injection/di';
import { collectionsValidationFormBlocContext } from '../../../../core/presentation/contexts/contexts';
import { CollectionsValidationForm } from '../components/collections_validation_form/collections_validation_form';

export const CollectionsValidationPage = () => {
    const collectionsValidationForm = di.collectionValidationFormBloc();

    return (
        <>
            <collectionsValidationFormBlocContext.Provider value={collectionsValidationForm}>
                <CollectionsValidationForm />
            </collectionsValidationFormBlocContext.Provider>
        </>
    );
};
