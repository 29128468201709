import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            textAlign: 'right',
        },
        checkbox: {
            ['& .MuiSvgIcon-root']: {
                color: theme.palette.text.primary,
            },
        },
    }),
);
