import { Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';

type ContentProps = {
    children?: React.ReactElement;
    title?: string;
};

export const Content: React.FC<ContentProps> = ({ children, title }: ContentProps) => {
    return (
        <>
            <Grid>
                <Typography variant="h1">{title}</Typography>
            </Grid>
            <Divider />
            <div style={{ marginTop: '1rem' }}>{children}</div>
        </>
    );
};
