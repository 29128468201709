import { IModel } from '../../../../core/domain/interfaces/model_interface';
import { StoreModel } from '../../../stores/domain/models/store_model';
import { CategoryModel } from './category_model';

type CouponModelArgs = {
    id: number;
    title: string;
    description: string;
    price: number;
    image: File | string;
    terms: string;
    tags: string;
    startDate: string;
    finishDate: string;
    stock: number;
    store: StoreModel;
    category: CategoryModel;
};
export class CouponModel implements IModel {
    _type = 'CouponModel';
    id: number;
    title: string;
    description: string;
    tags: string;
    price: number;
    image: File | string;
    terms: string;
    startDate: string;
    finishDate: string;
    stock: number;
    store: StoreModel;
    category: CategoryModel;

    constructor({
        id,
        title,
        description,
        price,
        image,
        terms,
        tags,
        finishDate,
        startDate,
        stock,
        category,
        store,
    }: CouponModelArgs) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.price = price;
        this.image = image;
        this.terms = terms;
        this.tags = tags;
        this.startDate = startDate;
        this.finishDate = finishDate;
        this.stock = stock;
        this.store = store;
        this.category = category;
    }
}
