import { Failure } from '../../../../../core/domain/failures/failure';
import { UserModel } from '../../../../../core/domain/models/user_model';
import { Bloc } from '../../../../../core/presentation/bloc';
import { Login } from '../../../domain/usecases/login/login';
import { initialState, LoginPageState } from './login_page_states';

export class LoginPageBloc extends Bloc<LoginPageState> {
    loginUseCase: Login;

    constructor(loginUseCase: Login) {
        super(initialState);
        this.loginUseCase = loginUseCase;
    }

    async login(): Promise<void> {
        const { username, password } = this.state;
        this.changeState({ username, password, _type: 'Submitting' });
        const result = await this.loginUseCase.execute({ username, password });
        if (result instanceof Failure) {
            this.changeState({ username, password, _type: 'Failure', failure: result });
            return;
        }
        this.changeState({ username, password, _type: 'Success', user: result as UserModel });
    }
    changeInput(input: 'username' | 'password', value: string): void {
        this.changeState({ ...this.state, _type: 'InputChanged', [input]: value });
    }
}
