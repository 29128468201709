import { Grid, Paper } from '@material-ui/core';
import React from 'react';
import { Language } from '../../strings/LanguageManager';
import { useStyles } from './styles';

export const NotFound = () => {
    const classes = useStyles();
    return (
        <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.container}
            style={{ height: 'calc( 100vh - 10rem)' }}
        >
            <Grid item>
                <Paper style={{ padding: '2rem' }} className={classes.paper}>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={12} md={5} className={classes.code}>
                            404
                        </Grid>
                        <Grid item md={1} className={classes.separator}></Grid>
                        <Grid item xs={12} md={5} className={classes.message}>
                            {Language.strings.pageNotFound}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};
