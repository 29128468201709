import { IModel } from '../../../../core/domain/interfaces/model_interface';
import { CouponModel } from '../../../coupons/domain/models/coupon_model';
import { StoreModel } from '../../../stores/domain/models/store_model';

export type PurchaseArgs = {
    coupon: CouponModel;
    price: number;
    purchaseDate: string;
    exchangeDate?: string;
    exchangeStore?: StoreModel;
    code: string;
};
export class PurchaseModel implements IModel {
    _type = 'PurchaseModel';

    coupon: CouponModel;
    price: number;
    purchaseDate: string;
    exchangeDate?: string;
    exchangeStore?: StoreModel;
    code: string;

    constructor({ coupon, price, purchaseDate, exchangeDate, exchangeStore, code }: PurchaseArgs) {
        this.coupon = coupon;
        this.price = price;
        this.purchaseDate = purchaseDate;
        this.exchangeDate = exchangeDate;
        this.exchangeStore = exchangeStore;
        this.code = code;
    }
}
