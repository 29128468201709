import React from 'react';
import logo_large from '../../assets/logos/logo_large.svg';
import logo_small from '../../assets/logos/logo_small.svg';

type LogoProps = {
    size: number;
    variant: 'small' | 'large';
    className?: string;
};

const Logo: React.FC<LogoProps> = ({ size, variant, className }: LogoProps) => {
    const logos = { small: logo_small, large: logo_large };
    return <img src={logos[variant]} style={{ width: `${size}rem`, height: 'auto' }} className={className} />;
};

export default React.memo(Logo);
