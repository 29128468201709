import { AxiosInstance } from 'axios';
import { ValidationError } from '../../../../core/data/errors/validation_error';
import { httpToError } from '../../../../core/data/utils/http_to_error';
import { IAxiosDataSource } from '../../../authentication/data/datasources/interfaces/axios_datasource_interface';
import { CollectionEntity } from '../entities/collection_entity';
import { IValidateCollectionsValidations } from '../repositories/validations/validate_collections_validations';
import { ICollectionDataSource, ValidateCollectionsParams } from './interfaces/collection_datasource_interface';

export class AxiosCollectionDataSource implements IAxiosDataSource, ICollectionDataSource {
    axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    async validateCollections({ userCode, ecopoint }: ValidateCollectionsParams): Promise<CollectionEntity[]> {
        try {
            const { data } = await this.axiosInstance.post(`collections/${userCode}`, {
                _method: 'PUT',
                ecopoint_id: ecopoint.id,
            });
            return Promise.resolve((data as any[]).map((item) => CollectionEntity.fromObject(item)));
        } catch (error) {
            if (error.response && error.response.status === 400) {
                return Promise.reject(
                    new ValidationError<IValidateCollectionsValidations>({
                        ...error.response.data,
                        userCode: error.response.data.code,
                        ecopoint: error.response.data.ecopoint_id,
                    }),
                );
            }
            return Promise.reject(httpToError<IValidateCollectionsValidations>(error));
        }
    }
}
