import { useSnackbar } from 'notistack';
import React from 'react';
import di from '../../../../../core/app/dependency_injection/di';
import { AuthorizationFailure } from '../../../../../core/domain/failures/authorization_failure';
import { Failure } from '../../../../../core/domain/failures/failure';
import { ValidationFailure } from '../../../../../core/domain/failures/validation_failure';
import { DataTableSelection } from '../../../../../core/presentation/components/datatable/datatable';
import { DeletionConfirmationModal } from '../../../../../core/presentation/components/deletion_confirmation_modal/deletion_confirmation_modal';
import {
    ecopointDeletionContext,
    ecopointFormContext,
    ecopointsDataTableContext,
} from '../../../../../core/presentation/contexts/contexts';
import { Language } from '../../../../../core/presentation/strings/LanguageManager';
import getErrorString from '../../../../../core/presentation/utils/get_error_string';
import { EcoPointModel } from '../../../domain/models/ecopoint_model';
import { EcoPointsDataTable } from '../../components/ecopoints_datatable/ecopoints_datatable';
import { EcoPointForm } from '../../components/ecopoint_form/ecopoint_form';

export const testData = [
    new EcoPointModel({
        id: 1,
        name: 'Tienda 1',
        address: 'lasdasd 123',
        coordinates: { lat: -31.416638415050677, lng: -64.18355818593822 },
        schedules: 'schedules',
        residueTypes: [],
    }),
];

export const EcoPointsPage = () => {
    const ecopointsDatatableBloc = di.ecopointsDatatableBloc();
    const ecopointFormBloc = di.ecopointFormBloc();
    const ecopointDeletionBloc = di.ecopointDeletionBloc();
    const { enqueueSnackbar } = useSnackbar();

    const handleEdit = ({ index, dataIndex }: DataTableSelection) => {
        if (ecopointsDatatableBloc.state._type === 'Loaded') {
            ecopointFormBloc.openFor('update', ecopointsDatatableBloc.state.data.data[dataIndex]);
        }
    };
    const handleAskConfirmation = ({ index, dataIndex }: DataTableSelection) => {
        if (ecopointsDatatableBloc.state._type === 'Loaded') {
            ecopointDeletionBloc.askConfirmation(ecopointsDatatableBloc.state.data.data[dataIndex]);
        }
    };
    const handleConfirmDelete = () => {
        ecopointDeletionBloc.delete();
        ecopointsDatatableBloc.changeSelection([]);
    };
    const handleConfirmationDenied = () => {
        ecopointDeletionBloc.denyConfirmation();
    };
    const handleRead = ({ index, dataIndex }: DataTableSelection) => {
        if (ecopointsDatatableBloc.state._type === 'Loaded') {
            ecopointFormBloc.openFor('read', ecopointsDatatableBloc.state.data.data[dataIndex]);
        }
    };
    const handleDeletionFailure = (failure: Failure) => {
        if (failure instanceof ValidationFailure) {
            if (failure.fails.deleteEcopoint)
                enqueueSnackbar(getErrorString('deleteEcopoint', { _type: 'Failure', failure }, 'ecopoint'), {
                    variant: 'error',
                });
            if (failure.fails.id)
                enqueueSnackbar(getErrorString('id', { _type: 'Failure', failure }, 'ecopoint'), { variant: 'error' });
            return;
        }
        if (failure instanceof AuthorizationFailure) {
            enqueueSnackbar(Language.strings.loggedOut, { variant: 'error' });
            return;
        }
        enqueueSnackbar(Language.strings.unknown_error, { variant: 'error' });
    };
    const handleDeletionSuccess = () => {
        enqueueSnackbar(Language.strings.deletionSuccess, { variant: 'success' });
    };

    const handleCreate = () => {
        ecopointFormBloc.openFor('create');
    };

    return (
        <>
            <ecopointFormContext.Provider value={ecopointFormBloc}>
                <ecopointDeletionContext.Provider value={ecopointDeletionBloc}>
                    <ecopointsDataTableContext.Provider value={ecopointsDatatableBloc}>
                        <EcoPointsDataTable
                            onRead={handleRead}
                            onEdit={handleEdit}
                            onDelete={handleAskConfirmation}
                            onCreate={handleCreate}
                        />
                    </ecopointsDataTableContext.Provider>
                    <DeletionConfirmationModal
                        bloc={ecopointDeletionBloc}
                        onClose={handleConfirmationDenied}
                        onConfirm={handleConfirmDelete}
                        onFailure={handleDeletionFailure}
                        onSuccess={handleDeletionSuccess}
                    />
                </ecopointDeletionContext.Provider>
                <EcoPointForm />
            </ecopointFormContext.Provider>
        </>
    );
};
