import { useSnackbar } from 'notistack';
import React from 'react';
import di from '../../../../../core/app/dependency_injection/di';
import { Failure } from '../../../../../core/domain/failures/failure';
import { ValidationFailure } from '../../../../../core/domain/failures/validation_failure';
import { DataTableSelection } from '../../../../../core/presentation/components/datatable/datatable';
import { DeletionConfirmationModal } from '../../../../../core/presentation/components/deletion_confirmation_modal/deletion_confirmation_modal';
import {
    couponDeletionContext,
    couponFormContext,
    couponsDataTableContext,
} from '../../../../../core/presentation/contexts/contexts';
import { Language } from '../../../../../core/presentation/strings/LanguageManager';
import getErrorString from '../../../../../core/presentation/utils/get_error_string';
import { CouponsDataTable } from '../../components/coupons_datatable/coupons_datatable';
import { CouponForm } from '../../components/coupon_form/coupon_form';
import { AuthorizationFailure } from './../../../../../core/domain/failures/authorization_failure';

export const CouponsPage = () => {
    const couponsDatatableBloc = di.couponsDatatableBloc();
    const couponFormBloc = di.couponFormBloc();
    const couponDeletionBloc = di.couponDeletionBloc();
    const { enqueueSnackbar } = useSnackbar();

    const handleEdit = ({ index, dataIndex }: DataTableSelection) => {
        if (couponsDatatableBloc.state._type === 'Loaded') {
            couponFormBloc.openFor('update', couponsDatatableBloc.state.data.data[dataIndex]);
        }
    };
    const handleAskConfirmation = ({ index, dataIndex }: DataTableSelection) => {
        if (couponsDatatableBloc.state._type === 'Loaded') {
            couponDeletionBloc.askConfirmation(couponsDatatableBloc.state.data.data[dataIndex]);
        }
    };
    const handleConfirmDelete = () => {
        couponDeletionBloc.delete();
        couponsDatatableBloc.changeSelection([]);
    };
    const handleConfirmationDenied = () => {
        couponDeletionBloc.denyConfirmation();
    };
    const handleRead = ({ index, dataIndex }: DataTableSelection) => {
        if (couponsDatatableBloc.state._type === 'Loaded') {
            couponFormBloc.openFor('read', couponsDatatableBloc.state.data.data[dataIndex]);
        }
    };
    const handleDeletionFailure = (failure: Failure) => {
        if (failure instanceof ValidationFailure) {
            if (failure.fails.deleteCoupon)
                enqueueSnackbar(getErrorString('deleteCoupon', { _type: 'Failure', failure }, 'coupon'), {
                    variant: 'error',
                });
            if (failure.fails.id)
                enqueueSnackbar(getErrorString('id', { _type: 'Failure', failure }, 'coupon'), { variant: 'error' });
            return;
        }
        if (!(failure instanceof AuthorizationFailure)) {
            enqueueSnackbar(Language.strings.unknown_error, { variant: 'error' });
        }
    };
    const handleDeletionSuccess = () => {
        enqueueSnackbar(Language.strings.deletionSuccess, { variant: 'success' });
    };

    const handleCreate = () => {
        couponFormBloc.openFor('create');
    };

    return (
        <>
            <couponFormContext.Provider value={couponFormBloc}>
                <couponDeletionContext.Provider value={couponDeletionBloc}>
                    <couponsDataTableContext.Provider value={couponsDatatableBloc}>
                        <CouponsDataTable
                            onRead={handleRead}
                            onEdit={handleEdit}
                            onDelete={handleAskConfirmation}
                            onCreate={handleCreate}
                        />
                    </couponsDataTableContext.Provider>
                    <DeletionConfirmationModal
                        bloc={couponDeletionBloc}
                        onClose={handleConfirmationDenied}
                        onConfirm={handleConfirmDelete}
                        onFailure={handleDeletionFailure}
                        onSuccess={handleDeletionSuccess}
                    />
                </couponDeletionContext.Provider>
                <CouponForm />
            </couponFormContext.Provider>
        </>
    );
};
