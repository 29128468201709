import { AxiosInstance } from 'axios';
import { ValidationError } from '../../../../core/data/errors/validation_error';
import { IDataTableValidations } from '../../../../core/data/interfaces/datatable_validation_interface';
import { httpToError } from '../../../../core/data/utils/http_to_error';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IAxiosDataSource } from '../../../authentication/data/datasources/interfaces/axios_datasource_interface';
import { EcoPointEntity } from '../entities/ecopoint_entity';
import { IEcoPointValidations } from '../validations/ecopoint_validations_interface';
import { IEcoPointDatasource } from './interfaces/ecopoint_datasource_interface';

export class AxiosEcoPointDataSource implements IEcoPointDatasource, IAxiosDataSource {
    axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }
    async deleteEcoPoint(ecopoint: EcoPointEntity): Promise<void> {
        const { id } = ecopoint;
        try {
            return await this.axiosInstance.delete(`ecopoints/${id}`);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                return Promise.reject(
                    new ValidationError<IEcoPointValidations>({
                        ...error.response.data,
                        coordinates: error.response.data.coord_lat || error.response.data.coord_lng,
                        residueTypes: error.response.data.residue_types,
                    }),
                );
            }
            return Promise.reject(httpToError<IEcoPointValidations>(error));
        }
    }
    async updateEcoPoint(ecopoint: EcoPointEntity): Promise<EcoPointEntity> {
        try {
            const { id, name, address, coordinates, schedules, residueTypes } = ecopoint;
            const { data } = await this.axiosInstance.post(`ecopoints/${id}`, {
                name: name,
                address: address,
                coord_lat: coordinates.lat,
                coord_lng: coordinates.lng,
                schedules,
                residue_types: residueTypes,
                _method: 'PUT',
            });
            return EcoPointEntity.fromObject(data);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                return Promise.reject(
                    new ValidationError<IEcoPointValidations>({
                        ...error.response.data,
                        coordinates: error.response.data.coord_lat || error.response.data.coord_lng,
                        residueTypes: error.response.data.residue_types,
                    }),
                );
            }
            return Promise.reject(httpToError<IEcoPointValidations>(error));
        }
    }
    async createEcoPoint(ecopoint: EcoPointEntity): Promise<EcoPointEntity> {
        const { name, address, coordinates, schedules, residueTypes } = ecopoint;
        try {
            const { data } = await this.axiosInstance.post('ecopoints', {
                name,
                address,
                coord_lat: coordinates.lat,
                coord_lng: coordinates.lng,
                schedules,
                residue_types: residueTypes,
            });
            return EcoPointEntity.fromObject(data);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                return Promise.reject(
                    new ValidationError<IEcoPointValidations>({
                        ...error.response.data,
                        coordinates: error.response.data.coord_lat || error.response.data.coord_lng,
                        residueTypes: error.response.data.residue_types,
                    }),
                );
            }
            return Promise.reject(httpToError<IEcoPointValidations>(error));
        }
    }

    async getUserEcoPoints(datatableParams: DataTableParams): Promise<DataTableResponse<EcoPointEntity>> {
        try {
            const { size, page, order, filters } = datatableParams;
            const { data } = await this.axiosInstance.get(`ecopoints`, {
                params: {
                    size,
                    page,
                    filters: JSON.stringify(filters),
                    order: JSON.stringify(order),
                },
            });
            return { data: data.data.map((item: any) => EcoPointEntity.fromObject(item)), total: data.total };
        } catch (error) {
            if (error.response && error.response.status === 400) {
                return Promise.reject(
                    new ValidationError<IEcoPointValidations>({
                        ...error.response.data,
                        getUserEcopoints: error.response.data.getEcopointsByUserId, // TODO : fix test
                    }),
                );
            }
            return Promise.reject(httpToError<IDataTableValidations>(error));
        }
    }
}
