import { errorToFailure } from '../../../../core/data/utils/error_to_failure';
import { CacheFailure } from '../../../../core/domain/failures/cache_failure';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { ICacheDataSource } from '../../../authentication/data/datasources/interfaces/cache_datasource_interface';
import { EcoPointEntity } from '../../../ecopoints/data/entities/ecopoint_entity';
import { EcoPointModel } from '../../../ecopoints/domain/models/ecopoint_model';
import { CollectionModel } from '../../domain/models/collection_model';
import { ICollectionRepository } from '../../domain/repositories/collection_repository_interface';
import { ValidateCollectionsParams } from '../../domain/usecases/validate_collections';
import { ICollectionDataSource } from '../datasources/interfaces/collection_datasource_interface';
import { CollectionEntity } from '../entities/collection_entity';

export class CollectionRepository implements ICollectionRepository {
    cacheDataSource: ICacheDataSource;
    collectionDataSource: ICollectionDataSource;

    constructor(collectionDataSource: ICollectionDataSource, cacheDataSource: ICacheDataSource) {
        this.collectionDataSource = collectionDataSource;
        this.cacheDataSource = cacheDataSource;
    }
    async validateCollections({
        userCode,
        ecopoint,
    }: ValidateCollectionsParams): Promise<CollectionModel[] | IFailure> {
        try {
            const result: CollectionEntity[] = await this.collectionDataSource.validateCollections({
                userCode,
                ecopoint: new EcoPointEntity(ecopoint),
            });
            return result.map((item) => item.toModel());
        } catch (error) {
            return Promise.resolve(errorToFailure(error));
        }
    }
    async getCachedEcoPoint(): Promise<IFailure | EcoPointModel> {
        try {
            const result = await this.cacheDataSource.get('ecopoint');
            const entity = new EcoPointEntity(result);
            return entity.toModel();
        } catch (error) {
            return Promise.resolve(new CacheFailure(error.message));
        }
    }
    async cacheEcoPoint(ecopoint: EcoPointModel): Promise<boolean | IFailure> {
        try {
            const result = await this.cacheDataSource.set('ecopoint', new EcoPointEntity(ecopoint));
            if (!result) return Promise.resolve(new CacheFailure(`Cannot save ${ecopoint} in cache`));
            return Promise.resolve(result);
        } catch (error) {
            return Promise.resolve(new CacheFailure(error.message));
        }
    }
}
