import { Button, Checkbox, FormHelperText, Grid, TextField, Typography } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Marker } from 'react-leaflet';
import { AuthorizationFailure } from '../../../../../core/domain/failures/authorization_failure';
import { ValidationFailure } from '../../../../../core/domain/failures/validation_failure';
import { Coordinates } from '../../../../../core/domain/interfaces/coordinates_interface';
import { BlocBuilder } from '../../../../../core/presentation/bloc';
import { ModalState } from '../../../../../core/presentation/bloc/modal_bloc/modal_states';
import { DraggableMarker } from '../../../../../core/presentation/components/draggable_marker/draggable_marker';
import FullScreenDialog from '../../../../../core/presentation/components/fullscreen_modal/fullscreen_modal';
import { Map } from '../../../../../core/presentation/components/map/map';
import { useEcoPointFormBloc } from '../../../../../core/presentation/contexts/contexts';
import useGeoLocation from '../../../../../core/presentation/hooks/useLocation';
import { Language } from '../../../../../core/presentation/strings/LanguageManager';
import getErrorString from '../../../../../core/presentation/utils/get_error_string';
import { Logout } from '../../../../authentication/presentation/components/logout/logout';
import { ResidueTypeModel } from '../../../../residues/domain/models/residue_type_model';
import { EcoPointFormBloc, EcopointFormState } from '../../blocs/ecopoint_form_bloc';
import { useStyles } from './styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const EcoPointForm: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const bloc: EcoPointFormBloc = useEcoPointFormBloc();
    const classes = useStyles();
    const location = useGeoLocation();

    useEffect(() => {
        const fillSelectors = (state: ModalState & EcopointFormState, prevState: ModalState & EcopointFormState) => {
            if (!prevState.open && state.open) {
                bloc.fillSelectors();
            }
        };

        const showSnackBar = (state: ModalState & EcopointFormState) => {
            if (state.open) {
                if (state._type === 'Failure' && !(state.failure instanceof AuthorizationFailure)) {
                    if (state.failure instanceof ValidationFailure) {
                        if (state.failure.fails.id)
                            enqueueSnackbar(getErrorString('id', state, 'ecopoint'), {
                                variant: 'error',
                            });
                        if (state.failure.fails.createEcopoint)
                            enqueueSnackbar(getErrorString('createEcopoint', state, 'ecopoint'), {
                                variant: 'error',
                            });
                        if (state.failure.fails.updateEcopoint)
                            enqueueSnackbar(getErrorString('updateEcopoint', state, 'ecopoint'), {
                                variant: 'error',
                            });
                        return;
                    }
                    enqueueSnackbar(Language.strings.unknown_error, { variant: 'error' });
                }
                if (state._type === 'Success' && state.action === 'create')
                    enqueueSnackbar(Language.strings.ecopointCreateSuccessful, { variant: 'success' });
                if (state._type === 'Success' && state.action === 'update')
                    enqueueSnackbar(Language.strings.ecopointEditSuccessful, { variant: 'success' });
            }
        };
        bloc.subscribe(showSnackBar);
        bloc.subscribe(fillSelectors);
        return () => {
            bloc.unsubscribe(showSnackBar);
            bloc.unsubscribe(fillSelectors);
        };
    });

    const handleInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        bloc.changeInput(event.target.name as 'name' | 'address' | 'schedules', event.target.value);
    };
    const handleCoordinatesChanged = (value: Coordinates) => {
        bloc.changeInput('coordinates', value);
    };
    const handleResidueTypesChanged = (event: React.ChangeEvent<any>, value: (ResidueTypeModel | undefined)[]) => {
        bloc.changeInput(
            'residueTypes',
            (value as ResidueTypeModel[]).map((s) => s.id),
        );
    };

    const handleSubmit = () => {
        bloc.submit();
    };
    return (
        <BlocBuilder
            bloc={bloc}
            builder={(state: ModalState & EcopointFormState) => {
                const {
                    action,
                    inputs: { ecopoint, residueTypes },
                } = state;
                const title =
                    (action && action === 'read' && Language.strings.ecopointRead) ||
                    (action === 'create' && Language.strings.ecopointCreate) ||
                    (action === 'update' && Language.strings.ecopointUpdate) ||
                    '';
                if (state._type === 'Failure' && state.failure instanceof AuthorizationFailure)
                    return <Logout error={true} errorType="authorization" />;

                return (
                    <FullScreenDialog
                        title={`${title} ${Language.strings.ecopoint}`}
                        open={state.open}
                        disableClose={state.block}
                        actions={
                            state.action !== 'read' ? (
                                <Button
                                    color="inherit"
                                    onClick={handleSubmit}
                                    disabled={state._type === 'Submitting'}
                                    startIcon={<SaveIcon />}
                                >
                                    {state._type === 'Submitting' ? Language.strings.saving : Language.strings.save}
                                </Button>
                            ) : undefined
                        }
                        content={
                            <Grid container item xs={12} direction="column" className={`${classes.container}`}>
                                <Grid item className={classes.pb}>
                                    <TextField
                                        fullWidth
                                        label={Language.strings.ecopointName}
                                        placeholder={Language.strings.ecopointNamePlaceholder}
                                        name="name"
                                        value={ecopoint.name}
                                        onChange={state.action === 'read' ? undefined : handleInputChanged}
                                        error={
                                            state._type === 'Failure' &&
                                            state.failure instanceof ValidationFailure &&
                                            state.failure.fails.name
                                        }
                                        helperText={getErrorString('name', state, 'ecopoint')}
                                        autoFocus
                                    />
                                </Grid>

                                <Grid item className={classes.pb}>
                                    <TextField
                                        fullWidth
                                        name="schedules"
                                        multiline
                                        rowsMax={6}
                                        rows={3}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder={Language.strings.ecopointSchedulePlaceholder}
                                        label={Language.strings.ecopointSchedules}
                                        value={ecopoint.schedules}
                                        onChange={state.action === 'read' ? undefined : handleInputChanged}
                                        error={
                                            state._type === 'Failure' &&
                                            state.failure instanceof ValidationFailure &&
                                            state.failure.fails.schedules
                                        }
                                        helperText={getErrorString('schedules', state, 'ecopoint')}
                                    />
                                </Grid>
                                <Grid item className={classes.pb}>
                                    <Autocomplete
                                        style={{ flexGrow: 1 }}
                                        multiple
                                        disableCloseOnSelect
                                        options={residueTypes}
                                        getOptionLabel={(option) => (option ? option.name : 'no name')}
                                        value={
                                            ecopoint.residueTypes.map((s) => residueTypes.find((_s) => _s.id === s)) ||
                                            []
                                        }
                                        renderInput={(params) => (
                                            <TextField {...params} label={Language.strings.ecopointResidueType} />
                                        )}
                                        limitTags={0}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option?.name || 'no name'}
                                            </React.Fragment>
                                        )}
                                        onChange={handleResidueTypesChanged}
                                    />
                                    {state._type === 'Failure' &&
                                    state.failure instanceof ValidationFailure &&
                                    state.failure.fails.residueTypes ? (
                                        <Typography color="error" variant="caption">
                                            {getErrorString('residueTypes', state, 'ecopoint')}
                                        </Typography>
                                    ) : null}
                                </Grid>
                                <Grid item className={classes.pb}>
                                    <TextField
                                        fullWidth
                                        name="address"
                                        placeholder={Language.strings.ecopointAddressPlaceholder}
                                        label={Language.strings.ecopointAddress}
                                        value={ecopoint.address}
                                        onChange={state.action === 'read' ? undefined : handleInputChanged}
                                        error={
                                            state._type === 'Failure' &&
                                            state.failure instanceof ValidationFailure &&
                                            state.failure.fails.address
                                        }
                                        helperText={getErrorString('address', state, 'ecopoint')}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" color="secondary" style={{ marginBottom: '1rem' }}>
                                        {Language.strings.ecopointLocationLabel}
                                    </Typography>
                                    {state._type === 'Failure' &&
                                    state.failure instanceof ValidationFailure &&
                                    state.failure.fails.coordinates ? (
                                        <FormHelperText error>
                                            {getErrorString('coordinates', state, 'ecopoint')}
                                        </FormHelperText>
                                    ) : null}
                                    <Map
                                        points={[]}
                                        className={classes.map}
                                        center={
                                            ecopoint.coordinates.lat !== 0 && ecopoint.coordinates.lng !== 0
                                                ? ecopoint.coordinates
                                                : (location &&
                                                      location.loaded &&
                                                      !location.error &&
                                                      location.coordinates && {
                                                          lat: parseFloat(location.coordinates.lat),
                                                          lng: parseFloat(location.coordinates.lng),
                                                      }) ||
                                                  undefined
                                        }
                                        mapObjects={
                                            <>
                                                {state.action === 'read' ? (
                                                    ecopoint.coordinates.lat !== 0 && ecopoint.coordinates.lng !== 0 ? (
                                                        <Marker position={ecopoint.coordinates} />
                                                    ) : null
                                                ) : (
                                                    <DraggableMarker
                                                        initialPosition={
                                                            ecopoint.coordinates.lat !== 0 &&
                                                            ecopoint.coordinates.lng !== 0
                                                                ? ecopoint.coordinates
                                                                : (location &&
                                                                      location.loaded &&
                                                                      !location.error &&
                                                                      location.coordinates && {
                                                                          lat: parseFloat(location.coordinates.lat),
                                                                          lng: parseFloat(location.coordinates.lng),
                                                                      }) ||
                                                                  undefined
                                                        }
                                                        onChange={handleCoordinatesChanged}
                                                    />
                                                )}
                                            </>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        }
                        onClose={() => bloc.close()}
                    />
                );
            }}
        />
    );
};
