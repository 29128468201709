import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { ResidueTypeModel } from '../models/residue_type_model';
import { IResidueRepository } from '../repositories/residue_repository';

export class GetResidueTypes implements IUseCase<ResidueTypeModel[], void> {
    residueRepository: IResidueRepository;

    constructor(residueRepository: IResidueRepository) {
        this.residueRepository = residueRepository;
    }

    async execute(): Promise<ResidueTypeModel[] | IFailure> {
        return this.residueRepository.getResidueTypes();
    }
}
