import { Button, Grid, TextField, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { AuthorizationFailure } from '../../../../../core/domain/failures/authorization_failure';
import { ValidationFailure } from '../../../../../core/domain/failures/validation_failure';
import { BlocBuilder } from '../../../../../core/presentation/bloc';
import { ModalState } from '../../../../../core/presentation/bloc/modal_bloc/modal_states';
import FullScreenDialog from '../../../../../core/presentation/components/fullscreen_modal/fullscreen_modal';
import { UploadButton } from '../../../../../core/presentation/components/upload_button/upload_button';
import { useCouponFormBloc } from '../../../../../core/presentation/contexts/contexts';
import { Language } from '../../../../../core/presentation/strings/LanguageManager';
import getErrorString from '../../../../../core/presentation/utils/get_error_string';
import { StoreModel } from '../../../../stores/domain/models/store_model';
import { CategoryModel } from '../../../domain/models/category_model';
import { CouponFormBloc, CouponFormState } from '../../blocs/coupon_form_bloc';
import { CouponDetails } from '../coupon_details/coupon_details';
import { Logout } from './../../../../authentication/presentation/components/logout/logout';
import { useStyles } from './styles';

export const CouponForm: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const bloc: CouponFormBloc = useCouponFormBloc();
    const classes = useStyles();

    useEffect(() => {
        const showSnackBar = (state: ModalState & CouponFormState) => {
            if (state.open) {
                if (state._type === 'Failure' && !(state.failure instanceof AuthorizationFailure)) {
                    if (state.failure instanceof ValidationFailure) {
                        if (state.failure.fails.createCoupon)
                            enqueueSnackbar(getErrorString('createCoupon', state, 'coupon'), { variant: 'error' });
                        if (state.failure.fails.updateCoupon)
                            enqueueSnackbar(getErrorString('updateCoupon', state, 'coupon'), { variant: 'error' });
                        if (state.failure.fails.id)
                            enqueueSnackbar(getErrorString('id', state, 'coupon'), { variant: 'error' });
                        if (state.failure.fails.image)
                            enqueueSnackbar(getErrorString('image', state, 'coupon'), { variant: 'error' });
                        return;
                    }
                    enqueueSnackbar(Language.strings.unknown_error, { variant: 'error' });
                }
                if (state._type === 'Success' && state.action === 'create')
                    enqueueSnackbar(Language.strings.couponCreateSuccessful, { variant: 'success' });
                if (state._type === 'Success' && state.action === 'update')
                    enqueueSnackbar(Language.strings.couponEditSuccessful, { variant: 'success' });
            }
        };
        bloc.subscribe(showSnackBar);
        return () => {
            bloc.unsubscribe(showSnackBar);
        };
    });

    const handleInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.name;
        let value: string | File | undefined | number = event.target.value;
        if (input === 'image' && event.target.files !== null && event.target.files?.length > 0) {
            value = event.target.files[0];
        }
        bloc.changeInput(
            input as
                | 'title'
                | 'description'
                | 'tags'
                | 'price'
                | 'terms'
                | 'startDate'
                | 'finishDate'
                | 'stock'
                | 'image',
            value,
        );
    };
    const handleCategoryChanged = (event: React.ChangeEvent<any>, value: CategoryModel | null) => {
        bloc.changeInput('category', value || undefined);
    };
    const handleStoreChanged = (event: React.ChangeEvent<any>, value: StoreModel | null) => {
        bloc.changeInput('store', value || undefined);
    };

    const handleSubmit = () => {
        bloc.submit();
    };
    return (
        <>
            <BlocBuilder
                bloc={bloc}
                builder={(state: ModalState & CouponFormState) => {
                    const { action } = state;
                    const { stores, categories } = state.inputs;
                    const title =
                        (action && action === 'read' && Language.strings.couponRead) ||
                        (action === 'create' && Language.strings.couponCreate) ||
                        (action === 'update' && Language.strings.couponUpdate) ||
                        '';
                    if (state._type === 'Failure' && state.failure instanceof AuthorizationFailure)
                        return <Logout error={true} errorType="authorization" />;

                    return (
                        <FullScreenDialog
                            title={`${title} ${Language.strings.coupon}`}
                            open={state.open}
                            disableClose={state.block}
                            actions={
                                state.action !== 'read' ? (
                                    <Button
                                        color="inherit"
                                        onClick={handleSubmit}
                                        disabled={state._type === 'Submitting'}
                                        startIcon={<SaveIcon />}
                                    >
                                        {state._type === 'Submitting' ? Language.strings.saving : Language.strings.save}
                                    </Button>
                                ) : undefined
                            }
                            content={
                                <Grid container spacing={2}>
                                    <Grid container item sm={5} direction="column" className={`${classes.container}`}>
                                        <Grid container spacing={4}>
                                            <Grid item className={classes.pb} style={{ flexGrow: 1 }}>
                                                <TextField
                                                    fullWidth
                                                    label={Language.strings.couponTitle}
                                                    name="title"
                                                    value={state.inputs.title}
                                                    onChange={state.action === 'read' ? undefined : handleInputChanged}
                                                    error={
                                                        state._type === 'Failure' &&
                                                        state.failure instanceof ValidationFailure &&
                                                        state.failure.fails.title
                                                    }
                                                    helperText={getErrorString('title', state, 'coupon')}
                                                    autoFocus
                                                />
                                            </Grid>
                                            {action !== 'read' && (
                                                <>
                                                    <Grid
                                                        item
                                                        className={classes.pb}
                                                        style={{ display: 'flex', alignItems: 'center' }}
                                                    >
                                                        <UploadButton
                                                            name="image"
                                                            color="secondary"
                                                            variant="outlined"
                                                            onChange={handleInputChanged}
                                                            label={Language.strings.couponImage}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                        <Grid item className={classes.pb}>
                                            <TextField
                                                fullWidth
                                                label={Language.strings.couponDescription}
                                                name="description"
                                                multiline
                                                rows={4}
                                                rowsMax={4}
                                                value={state.inputs.description}
                                                onChange={state.action === 'read' ? undefined : handleInputChanged}
                                                error={
                                                    state._type === 'Failure' &&
                                                    state.failure instanceof ValidationFailure &&
                                                    state.failure.fails.description
                                                }
                                                helperText={getErrorString('description', state, 'coupon')}
                                            />
                                        </Grid>
                                        <Grid container spacing={4}>
                                            <Grid item className={classes.pb} xs={6}>
                                                <Autocomplete
                                                    options={categories}
                                                    getOptionLabel={(option) => option.name}
                                                    value={state.inputs.category || null}
                                                    fullWidth
                                                    onChange={
                                                        state.action === 'read' ? undefined : handleCategoryChanged
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={Language.strings.couponCategory}
                                                        />
                                                    )}
                                                />
                                                {state._type === 'Failure' &&
                                                state.failure instanceof ValidationFailure &&
                                                state.failure.fails.category ? (
                                                    <Typography color="error" variant="caption">
                                                        {getErrorString('category', state, 'coupon')}
                                                    </Typography>
                                                ) : null}
                                            </Grid>
                                            <Grid item className={classes.pb} xs={6}>
                                                <Autocomplete
                                                    options={stores}
                                                    getOptionLabel={(option) => option.name}
                                                    value={state.inputs.store || null}
                                                    fullWidth
                                                    renderInput={(params) => (
                                                        <TextField {...params} label={Language.strings.couponStore} />
                                                    )}
                                                    onChange={state.action === 'read' ? undefined : handleStoreChanged}
                                                />
                                                {state._type === 'Failure' &&
                                                state.failure instanceof ValidationFailure &&
                                                state.failure.fails.store ? (
                                                    <Typography color="error" variant="caption">
                                                        {getErrorString('store', state, 'coupon')}
                                                    </Typography>
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                        <Grid item className={classes.pb}>
                                            <TextField
                                                fullWidth
                                                name="tags"
                                                label={Language.strings.couponTags}
                                                value={state.inputs.tags}
                                                onChange={state.action === 'read' ? undefined : handleInputChanged}
                                                error={
                                                    state._type === 'Failure' &&
                                                    state.failure instanceof ValidationFailure &&
                                                    state.failure.fails.tags
                                                }
                                                helperText={getErrorString('tags', state, 'coupon')}
                                            />
                                        </Grid>
                                        <Grid item className={classes.pb}>
                                            <TextField
                                                fullWidth
                                                name="terms"
                                                label={Language.strings.couponTerms}
                                                value={state.inputs.terms}
                                                onChange={state.action === 'read' ? undefined : handleInputChanged}
                                                multiline
                                                rows={4}
                                                error={
                                                    state._type === 'Failure' &&
                                                    state.failure instanceof ValidationFailure &&
                                                    state.failure.fails.terms
                                                }
                                                helperText={getErrorString('terms', state, 'coupon')}
                                            />
                                        </Grid>
                                        <Grid container spacing={4}>
                                            <Grid item className={classes.pb} xs={6}>
                                                <TextField
                                                    fullWidth
                                                    name="price"
                                                    type="number"
                                                    label={Language.strings.couponPrice}
                                                    value={state.inputs.price}
                                                    onChange={state.action === 'read' ? undefined : handleInputChanged}
                                                    error={
                                                        state._type === 'Failure' &&
                                                        state.failure instanceof ValidationFailure &&
                                                        state.failure.fails.price
                                                    }
                                                    helperText={getErrorString('price', state, 'coupon')}
                                                />
                                            </Grid>
                                            <Grid item className={classes.pb} xs={6}>
                                                <TextField
                                                    fullWidth
                                                    name="stock"
                                                    type="number"
                                                    label={Language.strings.couponStock}
                                                    value={state.inputs.stock}
                                                    onChange={state.action === 'read' ? undefined : handleInputChanged}
                                                    error={
                                                        state._type === 'Failure' &&
                                                        state.failure instanceof ValidationFailure &&
                                                        state.failure.fails.stock
                                                    }
                                                    helperText={getErrorString('stock', state, 'coupon')}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={4}>
                                            <Grid item className={classes.pb} xs={6}>
                                                <TextField
                                                    fullWidth
                                                    name="startDate"
                                                    type="date"
                                                    label={Language.strings.couponStartDate}
                                                    value={state.inputs.startDate}
                                                    onChange={state.action === 'read' ? undefined : handleInputChanged}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={
                                                        state._type === 'Failure' &&
                                                        state.failure instanceof ValidationFailure &&
                                                        state.failure.fails.startDate
                                                    }
                                                    helperText={getErrorString('startDate', state, 'coupon')}
                                                />
                                            </Grid>
                                            <Grid item className={classes.pb} xs={6}>
                                                <TextField
                                                    fullWidth
                                                    name="finishDate"
                                                    type="date"
                                                    label={Language.strings.couponFinishDate}
                                                    value={state.inputs.finishDate}
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={state.action === 'read' ? undefined : handleInputChanged}
                                                    error={
                                                        state._type === 'Failure' &&
                                                        state.failure instanceof ValidationFailure &&
                                                        state.failure.fails.finishDate
                                                    }
                                                    helperText={getErrorString('finishDate', state, 'coupon')}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container item sm={6} alignItems="center">
                                        <CouponDetails couponDetails={state.inputs} />
                                    </Grid>
                                </Grid>
                            }
                            onClose={() => bloc.close()}
                        />
                    );
                }}
            />
        </>
    );
};
