import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { StoreModel } from '../../../stores/domain/models/store_model';
import { IPurchaseRepository } from '../repositories/purchase_repository_interface';

export class CacheStore implements IUseCase<boolean, StoreModel> {
    purchaseRepository: IPurchaseRepository;

    constructor(purchaseRepository: IPurchaseRepository) {
        this.purchaseRepository = purchaseRepository;
    }
    execute(store: StoreModel): Promise<boolean | IFailure> {
        return this.purchaseRepository.cacheStore(store);
    }
}
