export interface PurchasesStrings {
    purchase: string;
    purchases: string;
    purchaseCode: string;
    purchaseStore: string;
    purchaseRedeem: string;
    purchaseCouponTitle: string;
    purchaseExchangeDate: string;
    purchaseSuccess: string;

    //Errors
    purchase_store_required_error: string;
    purchase_store_exists_error: string;
    purchase_purchaseCode_required_error: string;
    purchase_purchaseCode_exists_error: string;
    purchase_updatePurchase_redeemed_purchase_error: string;
    purchase_updatePurchase_expired_coupon_error: string;
    purchase_updatePurchase_not_redeemable_error: string;
    purchase_getPurchasesByStoreId_ownership_store_error: string;
}

export const purchaseStrings: PurchasesStrings = {
    purchase: 'Canje',
    purchases: 'Canjes',
    purchaseCode: 'Código de Cupón',
    purchaseStore: 'Tienda',
    purchaseRedeem: 'Canjear Cupón',
    purchaseCouponTitle: 'Título de Cupón',
    purchaseExchangeDate: 'Fecha de Canje',
    purchaseSuccess: '¡El cupón ha sido canjeado correctamente!',
    purchase_store_required_error: 'El campo Tienda es requerido',
    purchase_store_exists_error: 'La Tienda seleccionada no existe',
    purchase_purchaseCode_required_error: 'El campo Código es requerido',
    purchase_purchaseCode_exists_error: 'El código es inválido',
    purchase_updatePurchase_redeemed_purchase_error: 'Error: El código ya ha sido utilizado anteriormente',
    purchase_updatePurchase_expired_coupon_error: 'Error: El cupón ha expirado',
    purchase_updatePurchase_not_redeemable_error: 'Error: El cupón no es cajeable en esta tienda.',
    purchase_getPurchasesByStoreId_ownership_store_error: 'Error: La tienda no le pertenece al usuario.',
};
