import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { LoginPage } from '../../../features/authentication/presentation/pages/login_page/login_page';
import { PasswordRecoveryConfirmationPage } from '../../../features/authentication/presentation/pages/password_recovery_confirmation_page/password_recovery_confirmation_page';
import { RequestPasswordRecoveryPage } from '../../../features/authentication/presentation/pages/request_password_recovery_page/request_password_recovery_page';
import { AuthorizerContainer } from './authorizer';

export const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <LoginPage />
                </Route>
                <Route exact path="/password/request">
                    <RequestPasswordRecoveryPage />
                </Route>
                <Route exact path="/password/confirmation">
                    <PasswordRecoveryConfirmationPage />
                </Route>
                <Route component={AuthorizerContainer} />
            </Switch>
        </Router>
    );
};
