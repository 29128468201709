import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef } from 'react';
import { AuthorizationFailure } from '../../../../../core/domain/failures/authorization_failure';
import { ValidationFailure } from '../../../../../core/domain/failures/validation_failure';
import { BlocBuilder } from '../../../../../core/presentation/bloc';
import { FormState } from '../../../../../core/presentation/bloc/form_states/form_states';
import { usePurchaseFormBloc } from '../../../../../core/presentation/contexts/contexts';
import { Language } from '../../../../../core/presentation/strings/LanguageManager';
import getErrorString from '../../../../../core/presentation/utils/get_error_string';
import { Logout } from '../../../../authentication/presentation/components/logout/logout';
import { CouponDetails } from '../../../../coupons/presentation/components/coupon_details/coupon_details';
import { StoreModel } from '../../../../stores/domain/models/store_model';
import { PurchaseModel } from '../../../domain/models/purchase_model';
import { PurchaseFormBlocInputs } from '../../blocs/purchase_form_bloc';
import { useStyles } from './styles';
const emptyDetails = {
    title: '',
    image: '',
    price: 0,
    terms: '',
    finishDate: '',
    stock: 0,
    description: '',
};
export const PurchaseForm = () => {
    const purchaseBloc = usePurchaseFormBloc();
    const { enqueueSnackbar } = useSnackbar();
    const codeRef = useRef<HTMLInputElement | null>();

    const handlePurchaseCodeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        purchaseBloc.changeCode(event.target.value.replace(/[^a-zA-ZnÑ0-9]/gi, '').toUpperCase());
    };
    const handleStoreChanged = (event: React.ChangeEvent<any>, value: StoreModel | null) => {
        purchaseBloc.changeStore(value || undefined);
    };
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        purchaseBloc.submit();
    };
    const handleClear = () => {
        purchaseBloc.clear();
        if (codeRef.current) codeRef.current.focus();
    };

    useEffect(() => {
        purchaseBloc.fillSelectors();
        purchaseBloc.getCachedStore();
        const handleSnackbars = (state: FormState<PurchaseFormBlocInputs, PurchaseModel>) => {
            if (state._type === 'Failure' && !(state.failure instanceof AuthorizationFailure)) {
                if (state.failure instanceof ValidationFailure && state.failure.fails.updatePurchase) {
                    enqueueSnackbar(getErrorString('updatePurchase', state, 'purchase'), { variant: 'error' });
                    return;
                }
                enqueueSnackbar(Language.strings.unknown_error, { variant: 'error' });
            }
            if (state._type === 'Success') enqueueSnackbar(Language.strings.purchaseSuccess, { variant: 'success' });
        };
        purchaseBloc.subscribe(handleSnackbars);
        return () => {
            purchaseBloc.unsubscribe(handleSnackbars);
        };
    }, []);

    const classes = useStyles();

    return (
        <BlocBuilder
            bloc={purchaseBloc}
            builder={(state: FormState<PurchaseFormBlocInputs, PurchaseModel>) => {
                const { purchaseCode, store, stores } = state.inputs;
                if (state._type === 'Failure' && state.failure instanceof AuthorizationFailure)
                    return <Logout error={true} errorType="authorization" />;
                return (
                    <Grid container spacing={4} className={classes.pb}>
                        <Grid container item xs={12} md={6}>
                            <Paper className={classes.paper}>
                                <form onSubmit={handleSubmit}>
                                    <Grid item xs={12} className={classes.pb}>
                                        <Autocomplete
                                            options={stores}
                                            getOptionLabel={(option) => option.name}
                                            value={store || null}
                                            fullWidth
                                            renderInput={(params) => (
                                                <TextField {...params} label={Language.strings.purchaseStore} />
                                            )}
                                            onChange={handleStoreChanged}
                                        />
                                        {state._type === 'Failure' &&
                                        state.failure instanceof ValidationFailure &&
                                        state.failure.fails.store ? (
                                            <Typography color="error" variant="caption">
                                                {getErrorString('store', state, 'purchase')}
                                            </Typography>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} className={classes.pb}>
                                        <TextField
                                            name="purchaseCode"
                                            label={Language.strings.purchaseCode}
                                            value={purchaseCode}
                                            onChange={handlePurchaseCodeChanged}
                                            fullWidth
                                            autoFocus
                                            inputRef={codeRef}
                                            className={classes.code}
                                            error={
                                                state._type === 'Failure' &&
                                                state.failure instanceof ValidationFailure &&
                                                state.failure.fails.purchaseCode
                                            }
                                            helperText={getErrorString('purchaseCode', state, 'purchase')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={classes.pb}>
                                        <Button variant="contained" color="primary" type="submit" fullWidth>
                                            {Language.strings.purchaseRedeem}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} className={classes.pb}>
                                        <Button variant="outlined" color="secondary" onClick={handleClear} fullWidth>
                                            {Language.strings.clear}
                                        </Button>
                                    </Grid>
                                </form>
                            </Paper>
                        </Grid>
                        <Grid container item xs={12} md={6}>
                            <CouponDetails
                                couponDetails={state._type === 'Success' ? state.data.coupon : emptyDetails}
                                hide={['stock', 'price']}
                                className={classes.details}
                            />
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
};
