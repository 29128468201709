import { errorToFailure } from '../../../../core/data/utils/error_to_failure';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { StoreModel } from '../../domain/models/store_model';
import { IStoreRepository } from '../../domain/repositories/store_repository_interface';
import { IStoreDatasource } from '../datasources/interfaces/store_datasource_interface';
import { StoreEntity } from '../entities/store_entity';

export class StoreRepository implements IStoreRepository {
    datasource: IStoreDatasource;

    constructor(datasource: IStoreDatasource) {
        this.datasource = datasource;
    }
    async getProviders(): Promise<IFailure | StoreModel[]> {
        try {
            const result: StoreEntity[] = await this.datasource.getProviders();
            return result.map((item) => item.toModel());
        } catch (error) {
            return Promise.resolve(errorToFailure(error));
        }
    }
    async deleteStore(store: StoreModel): Promise<void | IFailure> {
        try {
            return await this.datasource.deleteStore(store);
        } catch (error) {
            return Promise.resolve(errorToFailure(error));
        }
    }
    async updateStore(store: StoreModel): Promise<StoreModel | IFailure> {
        try {
            const entity: StoreEntity = await this.datasource.updateStore(store);
            return entity.toModel();
        } catch (error) {
            return Promise.resolve(errorToFailure(error));
        }
    }
    async createStore(store: StoreModel): Promise<StoreModel | IFailure> {
        try {
            const entity: StoreEntity = await this.datasource.createStore(store);
            return entity.toModel();
        } catch (error) {
            return Promise.resolve(errorToFailure(error));
        }
    }

    async getUserStores(datatableParams: DataTableParams): Promise<DataTableResponse<StoreModel> | IFailure> {
        try {
            const result: DataTableResponse<StoreEntity> = await this.datasource.getUserStores(datatableParams);
            return { data: result.data.map((item) => item.toModel()), total: result.total };
        } catch (error) {
            return Promise.resolve(errorToFailure(error));
        }
    }
}
