import { Failure } from '../../../../core/domain/failures/failure';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { DataTableBloc } from '../../../../core/presentation/bloc/datatable_bloc/datatable_bloc';
import { StoreModel } from '../../../stores/domain/models/store_model';
import { PurchaseModel } from '../../domain/models/purchase_model';
import { GetStorePurchasesParams } from '../../domain/usecases/get_store_purchases';

export class PurchasesDataTableBloc extends DataTableBloc<PurchaseModel> {
    getStorePurchasesUseCase: IUseCase<DataTableResponse<PurchaseModel>, GetStorePurchasesParams>;

    constructor(getStorePurchasesUseCase: IUseCase<DataTableResponse<PurchaseModel>, GetStorePurchasesParams>) {
        super({ _type: 'Idle', page: 0, size: 10, filters: [], order: undefined, selection: [] });
        Object.setPrototypeOf(this, PurchasesDataTableBloc.prototype);
        this.getStorePurchasesUseCase = getStorePurchasesUseCase;
    }

    getStorePurchases = async (store: StoreModel): Promise<void> => {
        const { page, size, filters, order, selection } = this.state;
        this.changeState({ _type: 'Loading', page, size, filters, order, selection });
        const result = await this.getStorePurchasesUseCase.execute({
            store,
            datatableParams: { page, size, filters, order },
        });
        if (result instanceof Failure)
            return this.changeState({ _type: 'Failure', page, size, filters, order, selection, failure: result });
        this.changeState({
            _type: 'Loaded',
            page,
            size,
            filters,
            order,
            selection,
            data: result as DataTableResponse<PurchaseModel>,
        });
    };
}
