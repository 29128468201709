import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            width: '100vw',
            minHeight: '100vh',
            padding: '1rem',
            backgroundColor: theme.palette.secondary.main,
            flexWrap: 'nowrap',
            justifyContent: 'center',
            alignItems: 'center ',
            flexDirection: 'column',
        },
        paper: {
            width: '100%',
            maxWidth: '400px',
            ['& .MuiCardHeader-title']: {
                textAlign: 'center',
            },
            ['& .MuiCardHeader-root']: {
                borderBottom: `1px solid ${theme.colors.greyTransparent}`,
            },
            ['& .MuiOutlinedInput-root fieldset']: {
                border: `2px solid ${theme.input.borderColor.main}`,
            },
        },
        logo: {
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '25rem',
            },
            marginBottom: '2rem',
        },
    }),
);
