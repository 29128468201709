import { Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { AuthorizationFailure } from '../../../../../core/domain/failures/authorization_failure';
import { ValidationFailure } from '../../../../../core/domain/failures/validation_failure';
import { BlocBuilder } from '../../../../../core/presentation/bloc';
import { DataTableStates } from '../../../../../core/presentation/bloc/datatable_bloc/datatable_bloc_states';
import { FormState } from '../../../../../core/presentation/bloc/form_states/form_states';
import { DataTable, DataTableColumn } from '../../../../../core/presentation/components/datatable/datatable';
import { usePurchaseDataTableBloc, usePurchaseFormBloc } from '../../../../../core/presentation/contexts/contexts';
import { Language } from '../../../../../core/presentation/strings/LanguageManager';
import getErrorString from '../../../../../core/presentation/utils/get_error_string';
import { Logout } from '../../../../authentication/presentation/components/logout/logout';
import { StoreModel } from '../../../../stores/domain/models/store_model';
import { PurchaseModel } from '../../../domain/models/purchase_model';
import { PurchaseFormBlocInputs } from '../../blocs/purchase_form_bloc';

export const columns: DataTableColumn[] = [
    { name: 'code', label: Language.strings.purchaseCode, type: 'text' },
    {
        name: 'coupon.title',
        label: Language.strings.purchaseCouponTitle,
        options: { filter: false, sort: false },
        type: 'text',
    },
    { name: 'exchangeDate', label: Language.strings.purchaseExchangeDate, db: 'exchange_date', type: 'date' },
];
export const PurchasesDataTable: React.FC = () => {
    const datatableBloc = usePurchaseDataTableBloc();
    const purchaseFormBloc = usePurchaseFormBloc();
    let prevStore: StoreModel | undefined = undefined;
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (prevStore) datatableBloc.getStorePurchases(prevStore);

        const showSnackBar = (state: DataTableStates<PurchaseModel>) => {
            if (
                state._type === 'Failure' &&
                state.failure instanceof ValidationFailure &&
                state.failure.fails.getPurchasesByStoreId
            ) {
                enqueueSnackbar(getErrorString('getPurchasesByStoreId', state, 'purchase'), { variant: 'error' });
            }
        };

        const datatableChanged = (state: DataTableStates<PurchaseModel>) => {
            if (['FiltersChanged', 'OrderChanged', 'PageChanged', 'SizeChanged'].includes(state._type) && prevStore)
                datatableBloc.getStorePurchases(prevStore);
        };
        const storeChanged = (state: FormState<PurchaseFormBlocInputs, PurchaseModel>) => {
            if (state.inputs.store && (state.inputs.store !== prevStore || state._type === 'Success')) {
                prevStore = state.inputs.store;
                datatableBloc.getStorePurchases(state.inputs.store);
            }
        };

        datatableBloc.subscribe(showSnackBar);
        datatableBloc.subscribe(datatableChanged);
        purchaseFormBloc.subscribe(storeChanged);
        return () => {
            datatableBloc.unsubscribe(datatableChanged);
            datatableBloc.unsubscribe(showSnackBar);
            purchaseFormBloc.unsubscribe(storeChanged);
        };
    }, []);

    return (
        <BlocBuilder
            bloc={datatableBloc}
            builder={(state: DataTableStates<PurchaseModel>) => {
                if (state._type === 'Failure' && state.failure instanceof AuthorizationFailure)
                    return <Logout error={true} errorType="authorization" />;
                return (
                    <DataTable
                        columns={columns}
                        selection={state.selection}
                        isLoading={state._type === 'Loading'}
                        data={state._type === 'Loaded' ? state.data.data : []}
                        total={state._type === 'Loaded' ? state.data.total : 0}
                        page={state.page}
                        onRowSelectionChange={datatableBloc.changeSelection}
                        onColumnSortChange={datatableBloc.changeOrder}
                        onChangePage={datatableBloc.changePage}
                        onChangeRowsPerPage={datatableBloc.changeSize}
                        onFilterChange={datatableBloc.changeFilters}
                        selectionActions={<></>}
                        toolbarActions={<Typography variant="h2">{Language.strings.purchases}</Typography>}
                    />
                );
            }}
        />
    );
};
