import { AxiosInstance } from 'axios';
import { ValidationError } from '../../../../core/data/errors/validation_error';
import { IDataTableValidations } from '../../../../core/data/interfaces/datatable_validation_interface';
import { httpToError } from '../../../../core/data/utils/http_to_error';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IAxiosDataSource } from '../../../authentication/data/datasources/interfaces/axios_datasource_interface';
import { StoreModel } from '../../domain/models/store_model';
import { StoreEntity } from '../entities/store_entity';
import { IStoreValidations } from '../validations/store_validations_interface';
import { IStoreDatasource } from './interfaces/store_datasource_interface';

export class AxiosStoreDataSource implements IStoreDatasource, IAxiosDataSource {
    axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }
    async getProviders(): Promise<StoreEntity[]> {
        try {
            const { data } = await this.axiosInstance.get(`providers`);
            return data.map((item: any) => StoreEntity.fromObject(item));
        } catch (error) {
            return Promise.reject(httpToError<void>(error));
        }
    }
    async deleteStore(store: StoreModel): Promise<void> {
        const { id } = store;
        try {
            return await this.axiosInstance.delete(`stores/${id}`);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                return Promise.reject(
                    new ValidationError<IStoreValidations>({
                        ...error.response.data,
                        coordinates: error.response.data.coord_lat || error.response.data.coord_lng,
                        phoneNumber: error.response.data.phonenumber,
                        receiveCoupons: error.response.data.receive_coupons,
                    }),
                );
            }
            return Promise.reject(httpToError<IStoreValidations>(error));
        }
    }
    async updateStore(store: StoreModel): Promise<StoreEntity> {
        try {
            const {
                id,
                name,
                address,
                coordinates,
                phoneNumber,
                receiveCoupons,
                providerStores,
                mainStores,
                isProvider,
            } = store;
            const { data } = await this.axiosInstance.post(`stores/${id}`, {
                name: name,
                address: address,
                coord_lat: coordinates.lat,
                coord_lng: coordinates.lng,
                phonenumber: phoneNumber,
                receive_coupons: receiveCoupons,
                provider_stores: providerStores,
                main_stores: mainStores,
                is_provider: isProvider,
                _method: 'PUT',
            });
            return StoreEntity.fromObject(data);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                return Promise.reject(
                    new ValidationError<IStoreValidations>({
                        ...error.response.data,
                        coordinates: error.response.data.coord_lat || error.response.data.coord_lng,
                        phoneNumber: error.response.data.phonenumber,
                        receiveCoupons: error.response.data.receive_coupons,
                        isProvider: error.response.data.is_provider,
                    }),
                );
            }
            return Promise.reject(httpToError<IStoreValidations>(error));
        }
    }
    async createStore(store: StoreModel): Promise<StoreEntity> {
        const { name, address, coordinates, phoneNumber, receiveCoupons, providerStores, mainStores, isProvider } =
            store;
        try {
            const { data } = await this.axiosInstance.post('stores', {
                name: name,
                address: address,
                coord_lat: coordinates.lat,
                coord_lng: coordinates.lng,
                phonenumber: phoneNumber,
                receive_coupons: receiveCoupons,
                provider_stores: providerStores,
                main_stores: mainStores,
                is_provider: isProvider,
            });
            return StoreEntity.fromObject(data);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                return Promise.reject(
                    new ValidationError<IStoreValidations>({
                        ...error.response.data,
                        coordinates: error.response.data.coord_lat || error.response.data.coord_lng,
                        phoneNumber: error.response.data.phonenumber,
                        receiveCoupons: error.response.data.receive_coupons,
                        isProvider: error.response.data.is_provider,
                    }),
                );
            }
            return Promise.reject(httpToError<IStoreValidations>(error));
        }
    }

    async getUserStores(datatableParams: DataTableParams): Promise<DataTableResponse<StoreEntity>> {
        try {
            const { size, page, order, filters } = datatableParams;
            const { data } = await this.axiosInstance.get(`stores`, {
                params: {
                    size,
                    page,
                    filters: JSON.stringify(filters),
                    order: JSON.stringify(order),
                },
            });
            return { data: data.data.map((item: any) => StoreEntity.fromObject(item)), total: data.total };
        } catch (error) {
            return Promise.reject(httpToError<IDataTableValidations>(error));
        }
    }
}
