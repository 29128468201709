import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { CouponModel } from '../models/coupon_model';
import { ICouponRepository } from '../repositories/coupon_repository_interface';

export class DeleteCoupon implements IUseCase<void, CouponModel> {
    couponRepository: ICouponRepository;

    constructor(couponRepository: ICouponRepository) {
        this.couponRepository = couponRepository;
    }

    async execute(coupon: CouponModel): Promise<void | IFailure> {
        return this.couponRepository.deleteCoupon(coupon);
    }
}
