import { errorToFailure } from '../../../../core/data/utils/error_to_failure';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { ResidueTypeModel } from '../../domain/models/residue_type_model';
import { IResidueRepository } from '../../domain/repositories/residue_repository';
import { IResidueDataSource } from '../datasources/interfaces/residue_datasource_interface';

export class ResidueRepository implements IResidueRepository {
    residueDataSource: IResidueDataSource;

    constructor(residueDataSource: IResidueDataSource) {
        this.residueDataSource = residueDataSource;
    }

    async getResidueTypes(): Promise<ResidueTypeModel[] | IFailure> {
        try {
            const result = await this.residueDataSource.getResidueTypes();
            return Promise.resolve(result.map((item) => item.toModel()));
        } catch (error) {
            return Promise.resolve(errorToFailure(error));
        }
    }
}
