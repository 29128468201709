import { areAnyNil } from '../../../../core/app/utils/utils';
import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { IEntity } from '../../../../core/data/interfaces/entity_interface';
import { ResidueTypeModel, ResidueTypeModelArgs } from '../../domain/models/residue_type_model';

export class ResidueTypeEntity extends ResidueTypeModel implements IEntity {
    _type = 'ResidueTypeEntity';

    constructor({ id, name }: ResidueTypeModelArgs) {
        super({ id, name });
    }

    toModel(): ResidueTypeModel {
        return new ResidueTypeModel(this);
    }

    static fromObject(obj: any): ResidueTypeEntity {
        try {
            const { id, name } = obj;
            if (areAnyNil([id, name])) {
                throw new ConvertionError(JSON.stringify(obj), 'ResidueTypeEntity');
            }
            return new ResidueTypeEntity({
                id,
                name,
            });
        } catch (error) {
            throw new ConvertionError(JSON.stringify(obj), 'ResidueTypeEntity');
        }
    }
}
