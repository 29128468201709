import { areAnyNil } from '../../../../core/app/utils/utils';
import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { IEntity } from '../../../../core/data/interfaces/entity_interface';
import { CategoryModel } from '../../domain/models/category_model';

export class CategoryEntity extends CategoryModel implements IEntity {
    _type = 'CategoryEntity';

    toModel(): CategoryModel {
        return new CategoryModel(this);
    }

    static fromObject(obj: any): CategoryEntity {
        const { id, name } = obj;
        if (areAnyNil([id, name])) {
            throw new ConvertionError(JSON.stringify(obj), 'CategoryEntity');
        }
        return new CategoryEntity({
            id,
            name,
        });
    }
}
