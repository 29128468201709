import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { CollectionModel } from '../../../domain/models/collection_model';
import { Language } from '../../../../../core/presentation/strings/LanguageManager';
import React from 'react';
import { useStyles } from './styles';

type CollectionsDetailsProps = {
    collections: CollectionModel[];
};
export const CollectionsDetails = ({ collections = [] }: CollectionsDetailsProps) => {
    const classes = useStyles();
    return (
        <TableContainer className={classes.table} component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={5} align="left">
                            {Language.strings.collectionDetails}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center">{Language.strings.collectionResidue}</TableCell>
                        <TableCell align="center">{Language.strings.collectionCount}</TableCell>
                        <TableCell align="center">{Language.strings.collectionAmount}</TableCell>
                        <TableCell align="center">{Language.strings.collectionSubtotal}</TableCell>
                        <TableCell align="center">{Language.strings.collectionState}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {collections.map((collection, index) => {
                        const { residue, amount, count, validationDate } = collection;
                        const isValidated = validationDate ? true : false;
                        return (
                            <TableRow key={index}>
                                <TableCell align="center">{residue.name}</TableCell>
                                <TableCell align="center">{count}</TableCell>
                                <TableCell align="center">{amount} pts.</TableCell>
                                <TableCell align="center">{count * amount} pts.</TableCell>
                                <TableCell align="center">
                                    {isValidated ? <CheckCircleIcon /> : <CancelIcon />}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                    <TableRow>
                        <TableCell colSpan={3} />
                        <TableCell colSpan={1}>{Language.strings.collectionTotal}</TableCell>
                        <TableCell align="center">
                            $
                            {collections
                                .map((collection) => collection.amount * collection.count)
                                .reduce((acc, current) => {
                                    return acc + current;
                                }, 0)}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};
