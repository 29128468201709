import { Failure } from '../../../../core/domain/failures/failure';
import { ValidationFailure } from '../../../../core/domain/failures/validation_failure';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { FormState } from '../../../../core/presentation/bloc/form_states/form_states';
import { ModalBloc } from '../../../../core/presentation/bloc/modal_bloc/modal_bloc';
import { Language } from '../../../../core/presentation/strings/LanguageManager';
import { StoreModel } from '../../../stores/domain/models/store_model';
import { ICouponValidations } from '../../data/validations/coupon_validations_interface';
import { CategoryModel } from '../../domain/models/category_model';
import { CouponModel } from '../../domain/models/coupon_model';

export type CouponFormInputs = {
    id: number;
    title: string;
    description: string;
    tags: string;
    price: number;
    image: File | string;
    terms: string;
    startDate: string;
    finishDate: string;
    stock: number;
    store: StoreModel | undefined;
    category: CategoryModel | undefined;
    stores: StoreModel[];
    categories: CategoryModel[];
};

export const emptyInputs: CouponFormInputs = {
    id: 0,
    title: '',
    description: '',
    tags: '',
    price: 0,
    image: '',
    terms: '',
    startDate: '',
    finishDate: '',
    stock: 0,
    store: undefined,
    category: undefined,
    stores: [],
    categories: [],
};
export type CouponFormState = FormState<CouponFormInputs, CouponModel>;
export class CouponFormBloc extends ModalBloc<CouponFormState> {
    createCouponUseCase: IUseCase<CouponModel, CouponModel>;
    updateCouponUseCase: IUseCase<CouponModel, CouponModel>;
    getUserStoresUseCase: IUseCase<DataTableResponse<StoreModel>, DataTableParams>;
    getCategoriesUseCase: IUseCase<CategoryModel[], void>;

    constructor(
        createCouponUseCase: IUseCase<CouponModel, CouponModel>,
        updateCouponUseCase: IUseCase<CouponModel, CouponModel>,
        getUserStoresUseCase: IUseCase<DataTableResponse<StoreModel>, DataTableParams>,
        getCategoriesUseCase: IUseCase<CategoryModel[], void>,
    ) {
        super({
            open: false,
            block: false,
            _type: 'Idle',
            inputs: emptyInputs,
            action: 'read',
        });
        this.createCouponUseCase = createCouponUseCase;
        this.updateCouponUseCase = updateCouponUseCase;
        this.getUserStoresUseCase = getUserStoresUseCase;
        this.getCategoriesUseCase = getCategoriesUseCase;
    }

    private async getUseCase(action: string) {
        const { inputs } = this.state;
        if (inputs.store !== undefined && inputs.category !== undefined) {
            const couponModel = new CouponModel({
                ...inputs,
                store: inputs.store as StoreModel,
                category: inputs.category as CategoryModel,
            });
            if (action === 'create') return await this.createCouponUseCase.execute(couponModel);
            if (action === 'update') return await this.updateCouponUseCase.execute(couponModel);
        } else {
            if (!inputs.store)
                return new ValidationFailure<ICouponValidations>({
                    store: { code: 'required_error', message: Language.strings.coupon_store_required_error },
                });
            if (!inputs.category)
                return new ValidationFailure<ICouponValidations>({
                    category: { code: 'required_error', message: Language.strings.coupon_category_required_error },
                });
        }
    }

    async submit(): Promise<void> {
        const { open, inputs, action } = this.state;
        if (action === 'read') return;
        this.changeState({ action, inputs, open, _type: 'Submitting', block: true });
        const result = await this.getUseCase(action);
        if (result instanceof Failure) {
            this.changeState({ action, inputs, open, block: false, _type: 'Failure', failure: result });
            return;
        }
        if (result instanceof CouponModel)
            this.changeState({ action, inputs, open, block: false, _type: 'Success', data: result as CouponModel });
    }

    async open(): Promise<void> {
        this.openFor('create');
    }
    async fillSelectors() {
        const storesResponse = await this.getUserStoresUseCase.execute({
            filters: [],
            order: undefined,
            page: 0,
            size: 0,
        });
        const categoriesResponse = await this.getCategoriesUseCase.execute();
        const stores: StoreModel[] =
            storesResponse instanceof Failure ? [] : (storesResponse as DataTableResponse<StoreModel>).data;
        const categories: CategoryModel[] =
            categoriesResponse instanceof Failure ? [] : (categoriesResponse as CategoryModel[]);
        this.changeState({ ...this.state, inputs: { ...this.state.inputs, categories, stores } });
    }

    async openFor(action: 'read' | 'update' | 'create', data?: CouponModel) {
        const { inputs } = this.state;
        if ((action === 'read' || action === 'update') && data === undefined) return;

        if (action === 'read' || action === 'update')
            this.changeState({
                _type: 'Loaded',
                open: true,
                block: false,
                inputs: {
                    ...inputs,
                    ...data,
                },
                action,
            });
        if (action === 'create')
            this.changeState({
                _type: 'Loaded',
                open: true,
                block: false,
                inputs: emptyInputs,
                action,
            });
        if (action !== 'read') {
            this.fillSelectors();
        }
    }
    async changeInput(
        input:
            | 'title'
            | 'description'
            | 'tags'
            | 'price'
            | 'terms'
            | 'startDate'
            | 'finishDate'
            | 'stock'
            | 'image'
            | 'store'
            | 'category',
        value: string | number | File | CategoryModel | StoreModel | undefined,
    ) {
        this.changeState({
            ...this.state,
            _type: 'InputChanged',
            inputs: { ...this.state.inputs, [input]: value },
        });
    }
}
