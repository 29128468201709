import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { EcoPointModel } from '../models/ecopoint_model';
import { IEcoPointRepository } from '../repositories/ecopoint_repository_interface';

export class GetUserEcoPoints implements IUseCase<DataTableResponse<EcoPointModel>, DataTableParams> {
    ecopointRepository: IEcoPointRepository;

    constructor(ecopointRepository: IEcoPointRepository) {
        this.ecopointRepository = ecopointRepository;
    }

    async execute(datatableParams: DataTableParams): Promise<DataTableResponse<EcoPointModel> | IFailure> {
        const result = await this.ecopointRepository.getUserEcoPoints(datatableParams);
        return Promise.resolve(result as IFailure);
    }
}
