import { IFailure } from '../interfaces/failure_interface';
export abstract class Failure implements IFailure {
    message?: string;
    _type: string;

    constructor(_type: string, message?: string) {
        this.message = message || 'no message';
        this._type = _type;
        this.log();
    }
    log(): void {
        console.log(`${this._type} due to ${this.message}`);
    }
}
