import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { BlocBuilder } from '../../../../../core/presentation/bloc';
import { InfoModal } from '../../../../../core/presentation/components/modal/modal';
import { Language } from '../../../../../core/presentation/strings/LanguageManager';
import { PasswordRecoveryStates } from '../../blocs/password_recovery_bloc/password_recovery_states';
import { ValidationFailure } from '../../../../../core/domain/failures/validation_failure';
import { useHistory } from 'react-router-dom';
import { usePasswordRecoveryBloc } from '../../../../../core/presentation/contexts/contexts';

export const PasswordRecoveryRequestForm = () => {
    const passwordRecoveryBloc = usePasswordRecoveryBloc();
    const history = useHistory();
    const [open, setOpen] = useState(false);

    const handleInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        passwordRecoveryBloc.changeInput(event.target.name as 'email', event.target.value);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        passwordRecoveryBloc.requestPasswordRecovery();
    };

    const handleBack = () => {
        history.push('/');
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const onChanged = (state: PasswordRecoveryStates) => {
            if (state._type === 'RequestSuccessful') setOpen(true);
        };
        passwordRecoveryBloc.subscribe(onChanged);
        return () => passwordRecoveryBloc.unsubscribe(onChanged);
    }, []);

    return (
        <BlocBuilder
            bloc={passwordRecoveryBloc}
            builder={(state: PasswordRecoveryStates) => {
                const getErrorString = (input: string) => {
                    if (state._type === 'RequestFailed' && state.failure instanceof ValidationFailure) {
                        return Language.strings.getString(
                            `password_recovery_${input}_${state.failure.fails[input]?.code}`,
                        );
                    }
                    return '';
                };
                return (
                    <>
                        <form onSubmit={handleSubmit}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Typography>{Language.strings.password_recovery_help}</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        name="email"
                                        label={Language.strings.email}
                                        type="email"
                                        value={state.email}
                                        error={state._type === 'RequestFailed'}
                                        helperText={getErrorString('email')}
                                        onChange={handleInputChanged}
                                        autoFocus
                                    />
                                </Grid>
                                {state._type === 'RequestFailed' && !(state.failure instanceof ValidationFailure) ? (
                                    <Grid item>
                                        <Typography variant="caption" color="error">
                                            {Language.strings.unknown_error}
                                        </Typography>
                                    </Grid>
                                ) : null}

                                <Grid container item justify="space-between" spacing={3}>
                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            color="secondary"
                                            size="large"
                                            disabled={state._type === 'Requesting'}
                                            onClick={handleBack}
                                        >
                                            {Language.strings.back}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            disabled={state._type === 'Requesting'}
                                            type="submit"
                                        >
                                            {state._type === 'Requesting'
                                                ? Language.strings.submitting
                                                : Language.strings.submit}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                        <InfoModal
                            title={Language.strings.password_recovery_request_sent}
                            content={Language.strings.password_recovery_request_sent_detail}
                            onClose={handleClose}
                            open={open}
                            actions={
                                <>
                                    <Button onClick={handleClose} color="primary">
                                        {Language.strings.close}
                                    </Button>
                                </>
                            }
                        />
                    </>
                );
            }}
        />
    );
};
