import { Failure } from '../../../../core/domain/failures/failure';
import { ValidationFailure } from '../../../../core/domain/failures/validation_failure';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { Bloc } from '../../../../core/presentation/bloc';
import { FormState } from '../../../../core/presentation/bloc/form_states/form_states';
import { Language } from '../../../../core/presentation/strings/LanguageManager';
import { StoreModel } from '../../../stores/domain/models/store_model';
import { IRedeemPurchaseValidations } from '../../data/validations/redeem_purchase_validations_Interface';
import { PurchaseModel } from '../../domain/models/purchase_model';
import { RedeemPurchaseParams } from '../../domain/usecases/redeem_purchase';

export type PurchaseFormBlocInputs = {
    store: StoreModel | undefined;
    purchaseCode: string;
    stores: StoreModel[];
};

export const emptyInputs: FormState<PurchaseFormBlocInputs, PurchaseModel> = {
    _type: 'Idle',
    inputs: { store: undefined, purchaseCode: '', stores: [] },
    action: 'update',
};

type PurchaseFormBlocArgs = {
    redeemPurchaseUseCase: IUseCase<PurchaseModel, RedeemPurchaseParams>;
    getUserStoresUseCase: IUseCase<DataTableResponse<StoreModel>, DataTableParams>;
    getCachedStoreUseCase: IUseCase<StoreModel, void>;
    cacheStoreUseCase: IUseCase<boolean, StoreModel>;
};
export class PurchaseFormBloc extends Bloc<FormState<PurchaseFormBlocInputs, PurchaseModel>> {
    redeemPurchaseUseCase: IUseCase<PurchaseModel, RedeemPurchaseParams>;
    getUserStoresUseCase: IUseCase<DataTableResponse<StoreModel>, DataTableParams>;
    getCachedStoreUseCase: IUseCase<StoreModel, void>;
    cacheStoreUseCase: IUseCase<boolean, StoreModel>;

    constructor({
        redeemPurchaseUseCase,
        getUserStoresUseCase,
        getCachedStoreUseCase,
        cacheStoreUseCase,
    }: PurchaseFormBlocArgs) {
        super(emptyInputs);
        this.redeemPurchaseUseCase = redeemPurchaseUseCase;
        this.getUserStoresUseCase = getUserStoresUseCase;
        this.getCachedStoreUseCase = getCachedStoreUseCase;
        this.cacheStoreUseCase = cacheStoreUseCase;
    }

    async fillSelectors(): Promise<void> {
        const filters: DataTableParams = {
            filters: [{ column: 'receive_coupons', value: true, type: 'bool' }],
            order: undefined,
            page: 0,
            size: 0,
        };
        const storesResponse = await this.getUserStoresUseCase.execute(filters);
        const stores: StoreModel[] =
            storesResponse instanceof Failure ? [] : (storesResponse as DataTableResponse<StoreModel>).data;
        this.changeState({ ...this.state, inputs: { ...this.state.inputs, stores } });
    }

    async getCachedStore(): Promise<void> {
        const result = await this.getCachedStoreUseCase.execute();
        if (result instanceof StoreModel)
            return this.changeState({
                _type: 'InputChanged',
                action: 'update',
                inputs: { ...this.state.inputs, store: result },
            });
    }

    async changeStore(store: StoreModel | undefined): Promise<void> {
        if (store) this.cacheStoreUseCase.execute(store);
        const { inputs, action } = this.state;
        this.changeState({ _type: 'InputChanged', inputs: { ...inputs, store }, action });
    }

    async changeCode(purchaseCode: string): Promise<void> {
        const { inputs, action } = this.state;
        this.changeState({ _type: 'InputChanged', inputs: { ...inputs, purchaseCode }, action });
    }

    async submit(): Promise<void> {
        const { action, inputs } = this.state;
        if (action !== 'update') return;
        if (!inputs.store)
            return this.changeState({
                _type: 'Failure',
                failure: new ValidationFailure<IRedeemPurchaseValidations>({
                    store: { code: 'required_error', message: Language.strings.purchase_store_required_error },
                }),
                inputs,
                action,
            });

        this.changeState({ action, inputs, _type: 'Submitting' });
        const result = await this.redeemPurchaseUseCase.execute({
            purchaseCode: inputs.purchaseCode,
            store: inputs.store,
        });
        if (result instanceof Failure) {
            this.changeState({ action, inputs, _type: 'Failure', failure: result });
            return;
        }
        this.changeState({ action, inputs, _type: 'Success', data: result as PurchaseModel });
    }

    async clear(): Promise<void> {
        this.changeState({ ...this.state, _type: 'Cleared', inputs: { ...this.state.inputs, purchaseCode: '' } });
    }
}
