import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { EcoPointModel } from '../../../ecopoints/domain/models/ecopoint_model';
import { ICollectionRepository } from '../repositories/collection_repository_interface';

export class CacheEcoPoint implements IUseCase<boolean, EcoPointModel> {
    collectionRepository: ICollectionRepository;

    constructor(collectionRepository: ICollectionRepository) {
        this.collectionRepository = collectionRepository;
    }
    execute(ecopoint: EcoPointModel): Promise<boolean | IFailure> {
        return this.collectionRepository.cacheEcoPoint(ecopoint);
    }
}
