import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { StoreModel } from '../../../stores/domain/models/store_model';
import { IPurchaseRepository } from '../repositories/purchase_repository_interface';

export class GetCachedStore implements IUseCase<StoreModel, void> {
    purchaseRepository: IPurchaseRepository;

    constructor(purchaseRepository: IPurchaseRepository) {
        this.purchaseRepository = purchaseRepository;
    }

    async execute(): Promise<StoreModel | IFailure> {
        return this.purchaseRepository.getCachedStore();
    }
}
