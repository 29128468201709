import _ from 'lodash';
import { areAnyNil } from '../../../../core/app/utils/utils';
import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { IEntity } from '../../../../core/data/interfaces/entity_interface';
import { CouponEntity } from '../../../coupons/data/entities/coupon_entity';
import { StoreEntity } from '../../../stores/data/entities/store_entity';
import { PurchaseModel } from '../../domain/models/purchase_model';

type PurchaseEntityArgs = {
    id: number;
    coupon: CouponEntity;
    price: number;
    purchaseDate: string;
    exchangeDate?: string;
    exchangeStore?: StoreEntity;
    code: string;
};

export class PurchaseEntity implements IEntity {
    _type = 'PurchaseEntity';
    id: number;
    coupon: CouponEntity;
    price: number;
    purchaseDate: string;
    exchangeDate?: string;
    exchangeStore?: StoreEntity;
    code: string;

    constructor({ id, coupon, price, purchaseDate, exchangeDate, exchangeStore, code }: PurchaseEntityArgs) {
        this.id = id;
        this.coupon = coupon;
        this.price = price;
        this.purchaseDate = purchaseDate;
        this.exchangeDate = exchangeDate;
        this.exchangeStore = exchangeStore;
        this.code = code;
    }

    toModel(): PurchaseModel {
        const purchase = new PurchaseModel({
            price: this.price,
            purchaseDate: this.purchaseDate,
            exchangeDate: this.exchangeDate,
            coupon: this.coupon.toModel(),
            exchangeStore: this.exchangeStore?.toModel(),
            code: this.code,
        });
        return purchase;
    }

    static fromObject(obj: any): PurchaseEntity {
        try {
            const { id, coupon, price, purchase_date, code } = obj;
            if (areAnyNil([id, coupon, price, purchase_date, code])) {
                throw new ConvertionError(JSON.stringify(obj), 'PurchaseEntity');
            }
            return new PurchaseEntity({
                id: obj.id,
                price: obj.price,
                purchaseDate: obj.purchase_date.date,
                exchangeDate: obj.exchange_date?.date,
                coupon: CouponEntity.fromObject(obj.coupon),
                exchangeStore: _.isNil(obj.exchange_store) ? undefined : StoreEntity.fromObject(obj.exchange_store),
                code: obj.code,
            });
        } catch (error) {
            throw new ConvertionError(JSON.stringify(obj), 'PurchaseEntity');
        }
    }
}
