export interface StoreStrings {
    store: string;
    stores: string;
    storeName: string;
    storeAddress: string;
    storePhoneNumber: string;
    storeReceiveCoupons: string;
    storeIsProvider: string;
    storeUpdate: string;
    storeCreate: string;
    storeDelete: string;
    storeRead: string;
    storeCreateSuccessful: string;
    storeEditSuccessful: string;
    storeNamePlaceholder: string;
    storeAddressPlaceholder: string;
    storeLocationLabel: string;
    storeMainStores: string;
    storeProviderStores: string;
    storeMainStoresHelp: string;
    storeProviderStoresHelp: string;
    storeReceiveCouponsHelp: string;
    storeisProviderHelp: string;
    //Errors
    store_name_required_error: string;
    store_address_required_error: string;
    store_coordinates_required_error: string;
    store_coordinates_numeric_error: string;
    store_phoneNumber_required_error: string;
    store_receiveCoupons_required_error: string;
    store_isProvider_required_error: string;
    store_id_exists_error: string;
    store_createStore_exist_seller_error: string;
    store_updateStore_exist_seller_error: string;
    store_deleteStore_exist_seller_error: string;
    store_createStore_user_active_error: string;
    store_updateStore_user_active_error: string;
    store_deleteStore_user_active_error: string;
    store_updateStore_ownership_store_error: string;
    store_deleteStore_ownership_store_error: string;
}

export const storeStrings: StoreStrings = {
    store: 'Tienda',
    stores: 'Tiendas',
    storeName: 'Nombre',
    storeAddress: 'Dirección',
    storePhoneNumber: 'Teléfono',
    storeReceiveCoupons: 'Recibe Cupones',
    storeIsProvider: 'Es Proveedor',
    storeUpdate: 'Editar',
    storeDelete: 'Eliminar',
    storeRead: 'Ver',
    storeCreate: 'Crear',
    storeCreateSuccessful: 'La tienda ha sido creada correctamente',
    storeEditSuccessful: 'La tienda ha sido editada correctamente',
    storeNamePlaceholder: 'Ej: Kiosko Las Talitas',
    storeAddressPlaceholder: 'Ej: Av. Siempre Viva 153',
    storeLocationLabel: 'Seleccione su ubicación (arrastre el indicador a la ubicación de la Tienda):',
    storeMainStores: 'Sucursal de...',
    storeProviderStores: 'Convenio con Proveedores',
    storeMainStoresHelp:
        'Si tiene sucursales, seleccione la/s casa/s central/es.\n\nLos cupones creados desde la casa central podrán ser canjeados en esta misma tienda. Además tu tienda aparecerá en busquedas de cupones dirigidas a tus otras tiendas.',
    storeProviderStoresHelp:
        'Indica con que marcas o comercios tiene convenio para recibir sus cupones.\n\nLos cupones emitidos por las marcas o comercios seleccionados podrán ser canjeados en esta misma tienda.\n\nAdemás tu tienda aparecerá en busquedas de cupones emitidos desde la marca o comercio en convenio',
    storeReceiveCouponsHelp: 'Indica si tus clientes podrán canjear cupones en esta tienda.',
    storeisProviderHelp: 'Indica si deseas que otros usuarios puedan seleccionar a tu tienda como su proveedor.',
    //Errors
    store_name_required_error: 'El campo Nombre es requerido.',
    store_address_required_error: 'El campo Dirección es requerido.',
    store_coordinates_required_error: 'Error: No se pudo obtener coordenadas.',
    store_coordinates_numeric_error: 'Error: No se pudo obtener coordenadas.',
    store_phoneNumber_required_error: 'El campo Teléfono es requerido.',
    store_receiveCoupons_required_error: 'El campo Recibe Cupones es requerido.',
    store_isProvider_required_error: 'El campo Es Proveedor es requerido.',

    store_id_exists_error: 'Error: La tienda no existe.',
    store_createStore_exist_seller_error: 'Error: Su cuenta de comerciante no existe.',
    store_updateStore_exist_seller_error: 'Error: Su cuenta de comerciante no existe.',
    store_deleteStore_exist_seller_error: 'Error: Su cuenta de comerciante no existe.',
    store_createStore_user_active_error: 'Error: Su cuenta se encuentra inactiva.',
    store_updateStore_user_active_error: 'Error: Su cuenta se encuentra inactiva.',
    store_deleteStore_user_active_error: 'Error: Su cuenta se encuentra inactiva.',
    store_updateStore_ownership_store_error: 'Error: La tienda no le pertenece al usuario.',
    store_deleteStore_ownership_store_error: 'Error: La tienda no le pertenece al usuario.',
};
