import { Failure } from '../../../../../core/domain/failures/failure';
import { UserModel } from '../../../../../core/domain/models/user_model';

export interface ICommonState {
    username: string;
    password: string;
}

export interface IIdle {
    _type: 'Idle';
}

export interface ISubmitting {
    _type: 'Submitting';
}
export interface ISuccess {
    _type: 'Success';
    user: UserModel;
}
export interface IFailure {
    _type: 'Failure';
    failure: Failure;
}
export interface IInputChanged {
    _type: 'InputChanged';
}

export type LoginPageState = (IIdle | ISubmitting | ISuccess | IFailure | IInputChanged) & ICommonState;

export const initialState: LoginPageState = { _type: 'Idle', username: '', password: '' };
