import { useEffect, useState } from 'react';

function getStoredValue(init: any, key: string) {
    const item = localStorage.getItem(key);
    if (item) return JSON.parse(item);

    if (init instanceof Function) return init();
    return init;
}

export const useLocalStorage = (initialValue: any, key: string) => {
    const [value, setValue] = useState(() => {
        return getStoredValue(initialValue, key);
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [value, key]);

    return [
        value,
        setValue,
        () => {
            setValue(initialValue instanceof Function ? initialValue() : initialValue);
        },
    ];
};
