import { CollectionsValidationPage } from '../../../features/collections/presentation/pages/collections_validation_page';
import { CouponsPage } from '../../../features/coupons/presentation/pages/coupons_page/coupons_page';
import EcoIcon from '@material-ui/icons/Eco';
import { EcoPointsPage } from '../../../features/ecopoints/presentation/pages/ecopoints_page/ecopoints_page';
import { Language } from '../strings/LanguageManager';
import { PurchasesPage } from '../../../features/purchases/presentation/pages/purchases_page';
import React from 'react';
import Recycle from 'mdi-material-ui/Recycle';
import RedeemIcon from '@material-ui/icons/Redeem';
import StoreIcon from '@material-ui/icons/Store';
import { StoresPage } from '../../../features/stores/presentation/pages/stores_page/stores_page';
import TicketPercent from 'mdi-material-ui/TicketPercent';

export const routes = [
    {
        type: 'title',
        title: 'EMPRESAS / MUNICIPIOS',
    },
    {
        type: 'url',
        url: '/stores',
        title: 'COMPLETAR REGISTRO',
        contentTitle: 'Altas',
        icon: <StoreIcon />,
        component: <StoresPage />,
        id: 1,
    },
    {
        type: 'separator',
    },
    {
        type: 'url',
        url: '/collections-validation',
        title: 'VALIDAR RESIDUOS',
        icon: <Recycle />,
        contentTitle: 'Validar Residuos',
        component: <CollectionsValidationPage />,
        id: 2,
    },
    {
        type: 'url',
        url: '/purchases',
        title: 'Recibir Cupón',
        icon: <RedeemIcon />,
        component: <PurchasesPage />,
        contentTitle: 'Canjear Cupones',
        id: 3,
    },
    {
        type: 'separator',
    },
    {
        type: 'url',
        url: '/stores',
        title: 'Alta Sucursales',
        icon: <StoreIcon />,
        component: <StoresPage />,
        contentTitle: 'Altas',
        id: 4,
    },
    {
        type: 'url',
        url: '/ecopoints',
        title: 'Alta Ecopuntos',
        icon: <EcoIcon />,
        component: <EcoPointsPage />,
        contentTitle: Language.strings.ecopoints,
        id: 5,
    },
    {
        type: 'separator',
    },
    {
        type: 'url',
        url: '/coupons',
        title: 'Crear Cupones',
        icon: <TicketPercent />,
        component: <CouponsPage />,
        contentTitle: Language.strings.coupons,
        id: 6,
    },
    {
        type: 'separator',
    },
    {
        type: 'title',
        title: 'COOPERATIVAS',
    },
    {
        type: 'url',
        url: '/collections-validation',
        title: 'VALIDAR RESIDUOS',
        icon: <Recycle />,
        component: <CollectionsValidationPage />,
        contentTitle: 'Validar Residuos',
        id: 7,
    },
    {
        type: 'url',
        url: '/ecopoints',
        title: 'Alta Ecopuntos',
        icon: <EcoIcon />,
        component: <EcoPointsPage />,
        contentTitle: Language.strings.ecopoints,
        id: 8,
    },
];
