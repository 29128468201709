import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { CouponModel } from '../models/coupon_model';
import { ICouponRepository } from '../repositories/coupon_repository_interface';

export class CreateCoupon implements IUseCase<CouponModel, CouponModel> {
    couponRepository: ICouponRepository;

    constructor(couponRepository: ICouponRepository) {
        this.couponRepository = couponRepository;
    }

    execute(coupon: CouponModel): Promise<CouponModel | IFailure> {
        return this.couponRepository.createCoupon(coupon);
    }
}
