import { errorToFailure } from '../../../../core/data/utils/error_to_failure';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { EcoPointModel } from '../../domain/models/ecopoint_model';
import { IEcoPointRepository } from '../../domain/repositories/ecopoint_repository_interface';
import { IEcoPointDatasource } from '../datasources/interfaces/ecopoint_datasource_interface';
import { EcoPointEntity } from '../entities/ecopoint_entity';

export class EcoPointRepository implements IEcoPointRepository {
    datasource: IEcoPointDatasource;

    constructor(datasource: IEcoPointDatasource) {
        this.datasource = datasource;
    }
    async deleteEcoPoint(ecopoint: EcoPointModel): Promise<void | IFailure> {
        try {
            return await this.datasource.deleteEcoPoint(new EcoPointEntity(ecopoint));
        } catch (error) {
            return Promise.resolve(errorToFailure(error));
        }
    }
    async updateEcoPoint(ecopoint: EcoPointModel): Promise<EcoPointModel | IFailure> {
        try {
            const entity: EcoPointEntity = await this.datasource.updateEcoPoint(new EcoPointEntity(ecopoint));
            return entity.toModel();
        } catch (error) {
            return Promise.resolve(errorToFailure(error));
        }
    }
    async createEcoPoint(ecopoint: EcoPointModel): Promise<EcoPointModel | IFailure> {
        try {
            const entity: EcoPointEntity = await this.datasource.createEcoPoint(new EcoPointEntity(ecopoint));
            return entity.toModel();
        } catch (error) {
            return Promise.resolve(errorToFailure(error));
        }
    }

    async getUserEcoPoints(datatableParams: DataTableParams): Promise<DataTableResponse<EcoPointModel> | IFailure> {
        try {
            const result: DataTableResponse<EcoPointEntity> = await this.datasource.getUserEcoPoints(datatableParams);
            return { data: result.data.map((item) => item.toModel()), total: result.total };
        } catch (error) {
            return Promise.resolve(errorToFailure(error));
        }
    }
}
