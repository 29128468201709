import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { StoreModel } from '../models/store_model';
import { IStoreRepository } from '../repositories/store_repository_interface';

export class GetUserStores implements IUseCase<DataTableResponse<StoreModel>, DataTableParams> {
    storeRepository: IStoreRepository;

    constructor(storeRepository: IStoreRepository) {
        this.storeRepository = storeRepository;
    }

    async execute(datatableParams: DataTableParams): Promise<DataTableResponse<StoreModel> | IFailure> {
        const result = await this.storeRepository.getUserStores(datatableParams);
        return Promise.resolve(result as IFailure);
    }
}
