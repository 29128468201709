import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            overflow: 'hidden',
            position: 'relative',
            ['& .MuiLinearProgress-root']: {
                position: 'absolute',
                top: 0,
                width: '100%',
            },
        },
        empty: {
            ['& .MuiTable-root .MuiTableBody-root .MuiTableCell-body > div']: {
                width: '100%',
            },
        },
        selectionToolbar: {
            padding: '0 1.5rem',
            minHeight: '64px',
            ['& > div:first-child']: {
                padding: '21px 0',
            },
            ['& > div:not(:first-child)']: {
                ['& > div']: { padding: '12px' },
                ['& > div:first-child']: { paddingLeft: '0' },
            },
        },
    }),
);
