import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef } from 'react';
import { AuthorizationFailure } from '../../../../../core/domain/failures/authorization_failure';
import { ValidationFailure } from '../../../../../core/domain/failures/validation_failure';
import { BlocBuilder } from '../../../../../core/presentation/bloc';
import { FormState } from '../../../../../core/presentation/bloc/form_states/form_states';
import { useCollectionsValidationFormBloc } from '../../../../../core/presentation/contexts/contexts';
import { Language } from '../../../../../core/presentation/strings/LanguageManager';
import getErrorString from '../../../../../core/presentation/utils/get_error_string';
import { Logout } from '../../../../authentication/presentation/components/logout/logout';
import { EcoPointModel } from '../../../../ecopoints/domain/models/ecopoint_model';
import { CollectionModel } from '../../../domain/models/collection_model';
import { CollectionValidationFormBlocInputs } from '../../blocs/collections_validation_form_bloc';
import { CollectionsDetails } from '../collections_details/collections_details';
import { useStyles } from './styles';

export const CollectionsValidationForm = () => {
    const collectionValidationBloc = useCollectionsValidationFormBloc();
    const { enqueueSnackbar } = useSnackbar();
    const codeRef = useRef<HTMLInputElement | null>();

    const handleUserCodeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        collectionValidationBloc.changeCode(event.target.value.replace(/[^a-zA-ZnÑ0-9]/gi, '').toUpperCase());
    };
    const handleEcoPointChanged = (event: React.ChangeEvent<any>, value: EcoPointModel | null) => {
        collectionValidationBloc.changeEcoPoint(value || undefined);
    };
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        collectionValidationBloc.submit();
    };
    const handleClear = () => {
        collectionValidationBloc.clear();
        if (codeRef.current) codeRef.current.focus();
    };

    useEffect(() => {
        collectionValidationBloc.fillSelectors();
        collectionValidationBloc.getCachedEcoPoint();
        const handleSnackbars = (state: FormState<CollectionValidationFormBlocInputs, CollectionModel[]>) => {
            if (state._type === 'Failure' && !(state.failure instanceof AuthorizationFailure)) {
                if (state.failure instanceof ValidationFailure && state.failure.fails.updateCollection) {
                    enqueueSnackbar(getErrorString('updateCollection', state, 'collection'), { variant: 'error' });
                    return;
                }
                enqueueSnackbar(Language.strings.unknown_error, { variant: 'error' });
            }
            if (state._type === 'Success')
                enqueueSnackbar(Language.strings.collectionValidationSuccess, { variant: 'success' });
        };
        collectionValidationBloc.subscribe(handleSnackbars);
        return () => {
            collectionValidationBloc.unsubscribe(handleSnackbars);
        };
    }, []);

    const classes = useStyles();

    return (
        <BlocBuilder
            bloc={collectionValidationBloc}
            builder={(state: FormState<CollectionValidationFormBlocInputs, CollectionModel[]>) => {
                const { userCode, ecopoint, ecopoints } = state.inputs;
                if (state._type === 'Failure' && state.failure instanceof AuthorizationFailure)
                    return <Logout error={true} errorType="authorization" />;
                return (
                    <Grid container spacing={4} className={classes.pb}>
                        <Grid container item xs={12} md={6}>
                            <Paper className={classes.paper}>
                                <form onSubmit={handleSubmit}>
                                    <Grid item xs={12} className={classes.pb}>
                                        <Autocomplete
                                            options={ecopoints}
                                            getOptionLabel={(option) => option.name}
                                            value={ecopoint || null}
                                            fullWidth
                                            renderInput={(params) => (
                                                <TextField {...params} label={Language.strings.ecopoint} />
                                            )}
                                            onChange={handleEcoPointChanged}
                                        />
                                        {state._type === 'Failure' &&
                                        state.failure instanceof ValidationFailure &&
                                        state.failure.fails.ecopoint ? (
                                            <Typography color="error" variant="caption">
                                                {getErrorString('ecopoint', state, 'collection')}
                                            </Typography>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} className={classes.pb}>
                                        <TextField
                                            name="userCode"
                                            label={Language.strings.userCode}
                                            value={userCode}
                                            onChange={handleUserCodeChanged}
                                            fullWidth
                                            autoFocus
                                            inputRef={codeRef}
                                            className={classes.code}
                                            error={
                                                state._type === 'Failure' &&
                                                state.failure instanceof ValidationFailure &&
                                                state.failure.fails.userCode
                                            }
                                            helperText={getErrorString('userCode', state, 'collection')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={classes.pb}>
                                        <Button variant="contained" color="primary" type="submit" fullWidth>
                                            {Language.strings.collectionValidate}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} className={classes.pb}>
                                        <Button variant="outlined" color="secondary" onClick={handleClear} fullWidth>
                                            {Language.strings.clear}
                                        </Button>
                                    </Grid>
                                </form>
                            </Paper>
                        </Grid>
                        <Grid container item xs={12} md={6}>
                            <CollectionsDetails collections={state._type === 'Success' ? state.data : []} />
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
};
