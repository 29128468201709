import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            height: 'calc( 100vh - 10rem)',
        },
        paper: { padding: '2rem', background: theme.palette.secondary.main },
        code: {
            textAlign: 'center',
            fontSize: '10rem',
            fontWeight: 'bold',
            color: theme.palette.primary.main,
        },
        message: {
            fontSize: '2rem',
            margin: 'auto 0',
            color: theme.palette.primary.main,
            paddingLeft: '0',
            textAlign: 'center',
            [theme.breakpoints.up('md')]: {
                paddingLeft: '3rem',
                textAlign: 'left',
            },
        },
        separator: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'block',
                borderRight: `.5rem solid ${theme.palette.primary.main}`,
                height: '200px',
            },
        },
    }),
);
