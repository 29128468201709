import { IFailure } from '../../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../../core/domain/interfaces/usecase_interface';
import { IAuthenticationRepository } from '../../repositories/interfaces/authentication_repository_interface';

export type ChangePasswordParams = {
    password: string;
    token: string;
};

export class ChangePassword implements IUseCase<void, ChangePasswordParams> {
    repository: IAuthenticationRepository;

    constructor(repository: IAuthenticationRepository) {
        this.repository = repository;
    }

    async execute({ password, token }: ChangePasswordParams): Promise<void | IFailure> {
        return this.repository.changePassword({ password, token });
    }
}
