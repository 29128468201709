import { AuthenticationBloc } from '../../../features/authentication/presentation/blocs/authentication_bloc/authentication_bloc';
import { LoginPageBloc } from '../../../features/authentication/presentation/blocs/login_page_bloc/login_page_bloc';
import { PasswordRecoveryBloc } from '../../../features/authentication/presentation/blocs/password_recovery_bloc/password_recovery_bloc';
import { CollectionValidationFormBloc } from '../../../features/collections/presentation/blocs/collections_validation_form_bloc';
import { CouponModel } from '../../../features/coupons/domain/models/coupon_model';
import { CouponsDataTableBloc } from '../../../features/coupons/presentation/blocs/coupons_datatable_bloc';
import { CouponFormBloc } from '../../../features/coupons/presentation/blocs/coupon_form_bloc';
import { EcoPointModel } from '../../../features/ecopoints/domain/models/ecopoint_model';
import { EcoPointsDataTableBloc } from '../../../features/ecopoints/presentation/blocs/ecopoint_datatable_bloc';
import { EcoPointFormBloc } from '../../../features/ecopoints/presentation/blocs/ecopoint_form_bloc';
import { PurchasesDataTableBloc } from '../../../features/purchases/presentation/blocs/purchase_datatable_bloc';
import { PurchaseFormBloc } from '../../../features/purchases/presentation/blocs/purchase_form_bloc';
import { StoreModel } from '../../../features/stores/domain/models/store_model';
import { StoresDataTableBloc } from '../../../features/stores/presentation/blocs/stores_datatable_bloc';
import { StoreFormBloc } from '../../../features/stores/presentation/blocs/store_form_bloc';
import { createContext } from '../bloc/Context';
import { DeletionBloc } from '../bloc/deletion_bloc/deletion_bloc';

export const [loginPageContext, useLoginPageBloc] = createContext<LoginPageBloc>();

export const [authContext, useAuthBloc] = createContext<AuthenticationBloc>();

export const [passwordRecoveryContext, usePasswordRecoveryBloc] = createContext<PasswordRecoveryBloc>();

export const [storesDataTableContext, useStoresDataTableBloc] = createContext<StoresDataTableBloc>();

export const [storeFormContext, useStoreFormBloc] = createContext<StoreFormBloc>();

export const [storeDeletionContext, useStoreDeletionBloc] = createContext<DeletionBloc<StoreModel>>();

export const [ecopointsDataTableContext, useEcoPointsDataTableBloc] = createContext<EcoPointsDataTableBloc>();

export const [ecopointFormContext, useEcoPointFormBloc] = createContext<EcoPointFormBloc>();

export const [ecopointDeletionContext, useEcoPointDeletionBloc] = createContext<DeletionBloc<EcoPointModel>>();

export const [couponsDataTableContext, useCouponsDataTableBloc] = createContext<CouponsDataTableBloc>();

export const [couponFormContext, useCouponFormBloc] = createContext<CouponFormBloc>();

export const [couponDeletionContext, useCouponDeletionBloc] = createContext<DeletionBloc<CouponModel>>();

export const [purchaseFormContext, usePurchaseFormBloc] = createContext<PurchaseFormBloc>();

export const [purchaseDataTableBlocContext, usePurchaseDataTableBloc] = createContext<PurchasesDataTableBloc>();

export const [collectionsValidationFormBlocContext, useCollectionsValidationFormBloc] =
    createContext<CollectionValidationFormBloc>();
