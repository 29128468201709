import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { StoreModel } from '../../../stores/domain/models/store_model';
import { PurchaseModel } from '../models/purchase_model';
import { IPurchaseRepository } from '../repositories/purchase_repository_interface';

export type GetStorePurchasesParams = {
    store: StoreModel;
    datatableParams: DataTableParams;
};

export class GetStorePurchases implements IUseCase<DataTableResponse<PurchaseModel>, GetStorePurchasesParams> {
    purchaseRepository: IPurchaseRepository;

    constructor(purchaseRepository: IPurchaseRepository) {
        this.purchaseRepository = purchaseRepository;
    }
    async execute({
        store,
        datatableParams,
    }: GetStorePurchasesParams): Promise<DataTableResponse<PurchaseModel> | IFailure> {
        const result = await this.purchaseRepository.getStorePurchases({ store, datatableParams });
        return Promise.resolve(result as IFailure);
    }
}
