import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { StoreModel } from '../models/store_model';
import { IStoreRepository } from '../repositories/store_repository_interface';

export class CreateStore implements IUseCase<StoreModel, StoreModel> {
    storeRepository: IStoreRepository;

    constructor(storeRepository: IStoreRepository) {
        this.storeRepository = storeRepository;
    }

    execute(store: StoreModel): Promise<StoreModel | IFailure> {
        return this.storeRepository.createStore(store);
    }
}
