export interface GlobalStrings {
    username: string;
    password: string;
    email: string;
    submitting: string;
    submit: string;
    saving: string;
    save: string;
    welcome: string;
    unknown_error: string;
    back: string;
    close: string;
    clear: string;
    filters: string;
    applyFilters: string;
    confirmationTitle: string;
    confirmationContent: string;
    confirm: string;
    deny: string;
    deletionSuccess: string;
    help: string;
    pageNotFound: string;
    loggedOut: string;
}

export const globalStrings: GlobalStrings = {
    username: 'Usuario',
    password: 'Contraseña',
    email: 'Email',
    submit: 'Enviar',
    submitting: 'Enviando...',
    welcome: '¡Bienvenido!',
    unknown_error: '¡Ha ocurrido un error! Por favor inténtelo de nuevo más tarde.',
    back: 'Volver',
    close: 'Cerrar',
    clear: 'Limpiar',
    filters: 'Filtros',
    applyFilters: 'Aplicar Filtros',
    save: 'GUARDAR',
    saving: 'GUARDANDO...',
    confirmationTitle: 'Confirmación',
    confirmationContent: '¿Está seguro de que desea realizar ésta operación?',
    confirm: 'CONFIRMAR',
    deny: 'CANCELAR',
    deletionSuccess: 'La eliminación ha sido exitosa',
    help: 'Ayuda',
    pageNotFound: 'La página que está buscando no pudo ser encontrada',
    loggedOut: 'Su sesión ha expirado o no posee permisos suficientes.',
};
