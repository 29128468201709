import { Failure } from '../../../../../core/domain/failures/failure';
import { IUseCase } from '../../../../../core/domain/interfaces/usecase_interface';
import { UserModel } from '../../../../../core/domain/models/user_model';
import { Bloc } from '../../../../../core/presentation/bloc';
import { AuthenticationState, initialState } from './authentication_bloc_states';

export class AuthenticationBloc extends Bloc<AuthenticationState> {
    getAuthenticatedUserUseCase: IUseCase<UserModel, void>;
    logoutUseCase: IUseCase<void, void>;

    constructor(getAuthenticatedUserUseCase: IUseCase<UserModel, void>, logoutUseCase: IUseCase<void, void>) {
        super(initialState);
        this.getAuthenticatedUserUseCase = getAuthenticatedUserUseCase;
        this.logoutUseCase = logoutUseCase;
    }

    async getAuthenticatedUser(): Promise<void> {
        this.changeState({ _type: 'Loading' });
        const result = await this.getAuthenticatedUserUseCase.execute();
        if (result instanceof Failure) return await this.logout();
        this.changeState({ _type: 'Loaded', user: result as UserModel });
    }
    async logout(): Promise<void> {
        await this.logoutUseCase.execute();
        this.changeState({ _type: 'LoggedOut' });
    }
}
