import { IFailure } from '../../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../../core/domain/interfaces/usecase_interface';
import { UserModel } from '../../../../../core/domain/models/user_model';
import { IAuthenticationRepository } from '../../repositories/interfaces/authentication_repository_interface';

export class GetAuthenticatedUser implements IUseCase<UserModel, void> {
    repository: IAuthenticationRepository;

    constructor(repository: IAuthenticationRepository) {
        this.repository = repository;
    }

    async execute(): Promise<UserModel | IFailure> {
        return this.repository.getAuthenticatedUser();
    }
}
