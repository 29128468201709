import { Button, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useEffect } from 'react';
import { AuthorizationFailure } from '../../../../../core/domain/failures/authorization_failure';
import { BlocBuilder } from '../../../../../core/presentation/bloc';
import { DataTableStates } from '../../../../../core/presentation/bloc/datatable_bloc/datatable_bloc_states';
import { DeletionState } from '../../../../../core/presentation/bloc/deletion_bloc/deletion_state';
import { FormState } from '../../../../../core/presentation/bloc/form_states/form_states';
import { ModalState } from '../../../../../core/presentation/bloc/modal_bloc/modal_states';
import {
    DataTable,
    DataTableColumn,
    DataTableSelection,
} from '../../../../../core/presentation/components/datatable/datatable';
import {
    useEcoPointDeletionBloc,
    useEcoPointFormBloc,
    useEcoPointsDataTableBloc,
} from '../../../../../core/presentation/contexts/contexts';
import { Language } from '../../../../../core/presentation/strings/LanguageManager';
import { Logout } from '../../../../authentication/presentation/components/logout/logout';
import { EcoPointModel } from '../../../domain/models/ecopoint_model';
import { EcopointFormInputs } from '../../blocs/ecopoint_form_bloc';
import { useStyles } from './styles';

export const columns: DataTableColumn[] = [
    { name: 'name', label: Language.strings.ecopointName, type: 'text' },
    { name: 'address', label: Language.strings.ecopointAddress, type: 'text' },
];

export type EcoPointsDataTableProps = {
    onRead?: (selection: DataTableSelection) => void;
    onEdit?: (selection: DataTableSelection) => void;
    onDelete?: (selection: DataTableSelection) => void;
    onCreate?: () => void;
};

export const EcoPointsDataTable: React.FC<EcoPointsDataTableProps> = ({
    onRead,
    onEdit,
    onDelete,
    onCreate,
}: EcoPointsDataTableProps) => {
    const datatableBloc = useEcoPointsDataTableBloc();
    const ecopointFormBloc = useEcoPointFormBloc();
    const ecopointDeletionBloc = useEcoPointDeletionBloc();

    useEffect(() => {
        datatableBloc.getUserEcoPoints();

        const refresh = (
            state: DataTableStates<EcoPointModel> | (ModalState & FormState<EcopointFormInputs, EcoPointModel>),
        ) => {
            if (['Success', 'FiltersChanged', 'OrderChanged', 'PageChanged', 'SizeChanged'].includes(state._type))
                datatableBloc.getUserEcoPoints();
        };
        const reset = (state: DeletionState<EcoPointModel>) => {
            if (['Success'].includes(state._type)) {
                datatableBloc.changePage(0);
                datatableBloc.getUserEcoPoints();
            }
        };

        datatableBloc.subscribe(refresh);
        ecopointFormBloc.subscribe(refresh);
        ecopointDeletionBloc.subscribe(reset);
        return () => {
            datatableBloc.unsubscribe(refresh);
            ecopointFormBloc.unsubscribe(refresh);
            ecopointDeletionBloc.unsubscribe(reset);
        };
    }, []);

    const SelectionActions = (props: any) => {
        const classes = useStyles();
        return (
            <>
                <Tooltip title={Language.strings.ecopointRead} aria-label={Language.strings.ecopointRead}>
                    <IconButton color="secondary" onClick={onRead ? () => onRead(props) : undefined}>
                        <VisibilityIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={Language.strings.ecopointUpdate} aria-label={Language.strings.ecopointUpdate}>
                    <IconButton color="secondary" onClick={onEdit ? () => onEdit(props) : undefined}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={Language.strings.ecopointDelete} aria-label={Language.strings.ecopointDelete}>
                    <IconButton className={classes.delete} onClick={onDelete ? () => onDelete(props) : undefined}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </>
        );
    };
    const ToolbarActions = (props: any) => {
        const classes = useStyles();
        return (
            <>
                <Button
                    variant="contained"
                    color="primary"
                    disableElevation={true}
                    startIcon={<AddIcon />}
                    onClick={onCreate ? () => onCreate() : undefined}
                >
                    {`${Language.strings.ecopointCreate} ${Language.strings.ecopoint}`}
                </Button>
            </>
        );
    };

    return (
        <BlocBuilder
            bloc={datatableBloc}
            builder={(state: DataTableStates<EcoPointModel>) => {
                if (state._type === 'Failure' && state.failure instanceof AuthorizationFailure)
                    return <Logout error={true} errorType="authorization" />;
                return (
                    <DataTable
                        columns={columns}
                        isLoading={state._type === 'Loading'}
                        data={state._type === 'Loaded' ? state.data.data : []}
                        total={state._type === 'Loaded' ? state.data.total : 0}
                        page={state.page}
                        selection={state.selection}
                        onRowSelectionChange={datatableBloc.changeSelection}
                        onColumnSortChange={datatableBloc.changeOrder}
                        onChangePage={datatableBloc.changePage}
                        onChangeRowsPerPage={datatableBloc.changeSize}
                        onFilterChange={datatableBloc.changeFilters}
                        selectionActions={<SelectionActions />}
                        toolbarActions={<ToolbarActions />}
                    />
                );
            }}
        />
    );
};
