import React from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Coordinates } from '../../../domain/interfaces/coordinates_interface';
import { useStyles } from './styles';

export type MapPoint = {
    coordinates: Coordinates;
    popup?: React.ReactElement;
};

type MapProps = {
    points: MapPoint[];
    className?: string;
    mapObjects?: React.ReactElement;
    center?: Coordinates;
};

export const Map = ({
    points,
    className,
    mapObjects,
    center = { lat: -31.416638415050677, lng: -64.18355818593822 },
}: MapProps) => {
    const classes = useStyles();
    const convertedPoints = points.map((point) => ({
        ...point,
        coordinates: { lat: point.coordinates.lat, lng: point.coordinates.lng },
    }));

    return (
        <MapContainer
            className={`${classes.map} ${className}`}
            bounds={
                convertedPoints.length > 0
                    ? convertedPoints.map((point) => [point.coordinates.lat, point.coordinates.lng])
                    : undefined
            }
            center={convertedPoints.length > 0 ? undefined : center}
            zoom={convertedPoints.length > 0 ? undefined : 15}
            scrollWheelZoom={true}
        >
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {convertedPoints.map((point, index) => (
                <Marker position={[point.coordinates.lat, point.coordinates.lng]} key={index}>
                    {point.popup ? <Popup>{point.popup}</Popup> : null}
                </Marker>
            ))}
            {mapObjects}
        </MapContainer>
    );
};
