import { Button, Checkbox, FormControlLabel, FormHelperText, Grid, TextField, Typography } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Marker } from 'react-leaflet';
import { AuthorizationFailure } from '../../../../../core/domain/failures/authorization_failure';
import { ValidationFailure } from '../../../../../core/domain/failures/validation_failure';
import { Coordinates } from '../../../../../core/domain/interfaces/coordinates_interface';
import { BlocBuilder } from '../../../../../core/presentation/bloc';
import { ModalState } from '../../../../../core/presentation/bloc/modal_bloc/modal_states';
import { DraggableMarker } from '../../../../../core/presentation/components/draggable_marker/draggable_marker';
import FullScreenDialog from '../../../../../core/presentation/components/fullscreen_modal/fullscreen_modal';
import { InputHelper } from '../../../../../core/presentation/components/input_helper/input_helper';
import { Map } from '../../../../../core/presentation/components/map/map';
import { useStoreFormBloc } from '../../../../../core/presentation/contexts/contexts';
import useGeoLocation from '../../../../../core/presentation/hooks/useLocation';
import { Language } from '../../../../../core/presentation/strings/LanguageManager';
import getErrorString from '../../../../../core/presentation/utils/get_error_string';
import { Logout } from '../../../../authentication/presentation/components/logout/logout';
import { StoreModel } from '../../../domain/models/store_model';
import { StoreFormBloc, StoreFormState } from '../../blocs/store_form_bloc';
import { useStyles } from './styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const StoreForm: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const bloc: StoreFormBloc = useStoreFormBloc();
    const location = useGeoLocation();

    const classes = useStyles();

    useEffect(() => {
        const fillSelectors = (state: ModalState & StoreFormState, prevState: ModalState & StoreFormState) => {
            if (!prevState.open && state.open) bloc.fillSelectors();
        };
        const showSnackBar = (state: ModalState & StoreFormState) => {
            if (state.open) {
                if (state._type === 'Failure' && !(state.failure instanceof AuthorizationFailure)) {
                    if (state.failure instanceof ValidationFailure) {
                        if (state.failure.fails.id)
                            enqueueSnackbar(getErrorString('id', state, 'store'), { variant: 'error' });
                        if (state.failure.fails.coordinates)
                            enqueueSnackbar(getErrorString('coordinates', state, 'store'), { variant: 'error' });
                        if (state.failure.fails.updateStore)
                            enqueueSnackbar(getErrorString('updateStore', state, 'store'), { variant: 'error' });
                        if (state.failure.fails.createStore)
                            enqueueSnackbar(getErrorString('createStore', state, 'store'), { variant: 'error' });
                        return;
                    }
                    enqueueSnackbar(Language.strings.unknown_error, { variant: 'error' });
                }
                if (state._type === 'Success' && state.action === 'create')
                    enqueueSnackbar(Language.strings.storeCreateSuccessful, { variant: 'success' });
                if (state._type === 'Success' && state.action === 'update')
                    enqueueSnackbar(Language.strings.storeEditSuccessful, { variant: 'success' });
            }
        };

        bloc.subscribe(fillSelectors);
        bloc.subscribe(showSnackBar);
        return () => {
            bloc.unsubscribe(fillSelectors);
            bloc.unsubscribe(showSnackBar);
        };
    });

    const handleInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value: string | boolean = event.target.value;
        if (event.target.name === 'receiveCoupons' || event.target.name === 'isProvider') value = event.target.checked;
        bloc.changeInput(event.target.name as 'name' | 'address' | 'receiveCoupons' | 'isProvider', value);
    };
    const handlePhoneChanged = (value: string) => {
        const newValue = '+' + value.replace(/[^0-9]/gi, '');
        bloc.changeInput('phoneNumber', newValue);
    };
    const handleCoordinatesChanged = (value: Coordinates) => {
        bloc.changeInput('coordinates', value);
    };
    const handleMainStoresChanged = (event: React.ChangeEvent<any>, value: (StoreModel | undefined)[]) => {
        bloc.changeInput(
            'mainStores',
            (value as StoreModel[]).map((s) => s.id),
        );
    };
    const handleProviderStoresChanged = (event: React.ChangeEvent<any>, value: (StoreModel | undefined)[]) => {
        bloc.changeInput(
            'providerStores',
            (value as StoreModel[]).map((s) => s.id),
        );
    };

    const handleSubmit = () => {
        bloc.submit();
    };
    return (
        <>
            <BlocBuilder
                bloc={bloc}
                builder={(state: ModalState & StoreFormState) => {
                    const {
                        action,
                        inputs: { store, mainStores, providerStores },
                    } = state;
                    const title =
                        (action && action === 'read' && Language.strings.storeRead) ||
                        (action === 'create' && Language.strings.storeCreate) ||
                        (action === 'update' && Language.strings.storeUpdate) ||
                        '';
                    if (state._type === 'Failure' && state.failure instanceof AuthorizationFailure)
                        return <Logout error={true} errorType="authorization" />;
                    return (
                        <FullScreenDialog
                            title={`${title} ${Language.strings.store}`}
                            open={state.open}
                            disableClose={state.block}
                            actions={
                                state.action !== 'read' ? (
                                    <Button
                                        color="inherit"
                                        onClick={handleSubmit}
                                        disabled={state._type === 'Submitting'}
                                        startIcon={<SaveIcon />}
                                    >
                                        {state._type === 'Submitting' ? Language.strings.saving : Language.strings.save}
                                    </Button>
                                ) : undefined
                            }
                            content={
                                <Grid container item xs={12} direction="column" className={`${classes.container}`}>
                                    <Grid item className={classes.pb}>
                                        <TextField
                                            fullWidth
                                            label={Language.strings.storeName}
                                            name="name"
                                            placeholder={Language.strings.storeNamePlaceholder}
                                            value={store.name}
                                            onChange={state.action === 'read' ? undefined : handleInputChanged}
                                            error={
                                                state._type === 'Failure' &&
                                                state.failure instanceof ValidationFailure &&
                                                state.failure.fails.name
                                            }
                                            helperText={getErrorString('name', state, 'store')}
                                            autoFocus
                                        />
                                    </Grid>
                                    <Grid item className={classes.pb}>
                                        <TextField
                                            fullWidth
                                            name="address"
                                            label={Language.strings.storeAddress}
                                            placeholder={Language.strings.storeAddressPlaceholder}
                                            value={store.address}
                                            onChange={state.action === 'read' ? undefined : handleInputChanged}
                                            error={
                                                state._type === 'Failure' &&
                                                state.failure instanceof ValidationFailure &&
                                                state.failure.fails.address
                                            }
                                            helperText={getErrorString('address', state, 'store')}
                                        />
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item className={classes.pb} xs={12} sm={6}>
                                            <InputHelper message={Language.strings.storeMainStoresHelp}>
                                                <Autocomplete
                                                    style={{ flexGrow: 1 }}
                                                    multiple
                                                    disableCloseOnSelect
                                                    options={mainStores}
                                                    getOptionLabel={(option) => (option ? option.name : 'no name')}
                                                    value={
                                                        store.mainStores.map((s) =>
                                                            mainStores.find((_s) => _s.id === s),
                                                        ) || []
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={Language.strings.storeMainStores}
                                                        />
                                                    )}
                                                    limitTags={0}
                                                    renderOption={(option, { selected }) => (
                                                        <React.Fragment>
                                                            <Checkbox
                                                                icon={icon}
                                                                checkedIcon={checkedIcon}
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                            />
                                                            {option?.name || 'no name'}
                                                        </React.Fragment>
                                                    )}
                                                    onChange={handleMainStoresChanged}
                                                />
                                            </InputHelper>
                                        </Grid>
                                        <Grid item className={classes.pb} xs={12} sm={6}>
                                            <InputHelper message={Language.strings.storeProviderStoresHelp}>
                                                <Autocomplete
                                                    multiple
                                                    disableCloseOnSelect
                                                    options={providerStores}
                                                    getOptionLabel={(option) => (option ? option.name : 'no name')}
                                                    value={
                                                        store.providerStores.map((s) =>
                                                            providerStores.find((_s) => _s.id === s),
                                                        ) || null
                                                    }
                                                    style={{ flexGrow: 1 }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={Language.strings.storeProviderStores}
                                                        />
                                                    )}
                                                    limitTags={0}
                                                    renderOption={(option, { selected }) => (
                                                        <React.Fragment>
                                                            <Checkbox
                                                                icon={icon}
                                                                checkedIcon={checkedIcon}
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                            />
                                                            {`${option?.seller?.businessName} - ${option?.name}` ||
                                                                'no name'}
                                                        </React.Fragment>
                                                    )}
                                                    onChange={handleProviderStoresChanged}
                                                />
                                            </InputHelper>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item className={classes.pb} style={{ paddingRight: '1rem' }}>
                                            <MuiPhoneNumber
                                                label={Language.strings.storePhoneNumber}
                                                defaultCountry={'ar'}
                                                countryCodeEditable={false}
                                                enableLongNumbers={true}
                                                value={store.phoneNumber}
                                                name="phoneNumber"
                                                disabled={state.action === 'read'}
                                                onChange={state.action === 'read' ? undefined : handlePhoneChanged}
                                                error={
                                                    state._type === 'Failure' &&
                                                    state.failure instanceof ValidationFailure &&
                                                    state.failure.fails.phoneNumber
                                                }
                                            />
                                            {state._type === 'Failure' &&
                                            state.failure instanceof ValidationFailure &&
                                            state.failure.fails.phoneNumber ? (
                                                <FormHelperText error>
                                                    {getErrorString('phoneNumber', state, 'store')}
                                                </FormHelperText>
                                            ) : null}
                                        </Grid>
                                        <Grid item className={classes.pb}>
                                            <InputHelper message={Language.strings.storeReceiveCouponsHelp}>
                                                <FormControlLabel
                                                    label={Language.strings.storeReceiveCoupons}
                                                    control={
                                                        <Checkbox
                                                            checked={store.receiveCoupons}
                                                            onChange={
                                                                state.action === 'read' ? undefined : handleInputChanged
                                                            }
                                                            name="receiveCoupons"
                                                        />
                                                    }
                                                />
                                            </InputHelper>
                                            {state._type === 'Failure' &&
                                            state.failure instanceof ValidationFailure &&
                                            state.failure.fails.receiveCoupons ? (
                                                <FormHelperText error>
                                                    {getErrorString('receiveCoupons', state, 'store')}
                                                </FormHelperText>
                                            ) : null}
                                        </Grid>
                                        <Grid item className={classes.pb}>
                                            <InputHelper message={Language.strings.storeisProviderHelp}>
                                                <FormControlLabel
                                                    label={Language.strings.storeIsProvider}
                                                    control={
                                                        <Checkbox
                                                            checked={store.isProvider}
                                                            onChange={
                                                                state.action === 'read' ? undefined : handleInputChanged
                                                            }
                                                            name="isProvider"
                                                        />
                                                    }
                                                />
                                            </InputHelper>
                                            {state._type === 'Failure' &&
                                            state.failure instanceof ValidationFailure &&
                                            state.failure.fails.isProvider ? (
                                                <FormHelperText error>
                                                    {getErrorString('isProvider', state, 'store')}
                                                </FormHelperText>
                                            ) : null}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="subtitle2"
                                            color="secondary"
                                            style={{ marginBottom: '1rem' }}
                                        >
                                            {Language.strings.storeLocationLabel}
                                        </Typography>
                                        <Map
                                            points={[]}
                                            className={classes.map}
                                            center={
                                                store.coordinates.lat !== 0 && store.coordinates.lng !== 0
                                                    ? store.coordinates
                                                    : (location &&
                                                          location.loaded &&
                                                          !location.error &&
                                                          location.coordinates && {
                                                              lat: parseFloat(location.coordinates.lat),
                                                              lng: parseFloat(location.coordinates.lng),
                                                          }) ||
                                                      undefined
                                            }
                                            mapObjects={
                                                <>
                                                    {state.action === 'read' ? (
                                                        store.coordinates.lat !== 0 && store.coordinates.lng !== 0 ? (
                                                            <Marker position={store.coordinates} />
                                                        ) : null
                                                    ) : (
                                                        <DraggableMarker
                                                            initialPosition={
                                                                store.coordinates.lat !== 0 &&
                                                                store.coordinates.lng !== 0
                                                                    ? store.coordinates
                                                                    : (location &&
                                                                          location.loaded &&
                                                                          !location.error &&
                                                                          location.coordinates && {
                                                                              lat: parseFloat(location.coordinates.lat),
                                                                              lng: parseFloat(location.coordinates.lng),
                                                                          }) ||
                                                                      undefined
                                                            }
                                                            onChange={handleCoordinatesChanged}
                                                        />
                                                    )}
                                                </>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            }
                            onClose={() => bloc.close()}
                        />
                    );
                }}
            />
        </>
    );
};
