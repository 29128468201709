import React from 'react';
import ResponsiveDrawer from '../../../../../core/presentation/components/drawer/drawer';

type DashboardProps = {
    children?: React.ReactElement;
};

export const Dashboard = ({ children }: DashboardProps) => {
    return <ResponsiveDrawer>{children}</ResponsiveDrawer>;
};
