import { Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Failure } from '../../../domain/failures/failure';
import { BlocBuilder } from '../../bloc';
import { DeletionBloc } from '../../bloc/deletion_bloc/deletion_bloc';
import { DeletionState } from '../../bloc/deletion_bloc/deletion_state';
import { Language } from '../../strings/LanguageManager';
import { InfoModal } from '../modal/modal';

type DeletionConfirmationModalProps<T> = {
    bloc: DeletionBloc<T>;
    message?: string;
    onClose: () => void;
    onConfirm: () => void;
    onFailure?: (failure: Failure) => void;
    onSuccess?: () => void;
};

export function DeletionConfirmationModal<T>({
    bloc,
    message,
    onClose,
    onConfirm,
    onFailure,
    onSuccess,
}: DeletionConfirmationModalProps<T>) {
    useEffect(() => {
        const handleDeletionState = (state: DeletionState<T>) => {
            if (state._type === 'Failure' && onFailure) onFailure(state.failure);
            if (state._type === 'Success' && onSuccess) onSuccess();
        };
        bloc.subscribe(handleDeletionState);
        return () => {
            bloc.unsubscribe(handleDeletionState);
        };
    });

    return (
        <BlocBuilder
            bloc={bloc}
            builder={(state: DeletionState<T>) => {
                return (
                    <InfoModal
                        open={state._type === 'WaitingConfirmation'}
                        title={Language.strings.confirmationTitle}
                        content={message || Language.strings.confirmationContent}
                        actions={
                            <>
                                <Button onClick={onClose}>{Language.strings.deny}</Button>
                                <Button onClick={onConfirm} variant="outlined">
                                    {Language.strings.confirm}
                                </Button>
                            </>
                        }
                    />
                );
            }}
        />
    );
}
