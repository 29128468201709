import { Grid, IconButton, Typography } from '@material-ui/core';
import HelpOutline from '@material-ui/icons/HelpOutline';
import React, { useState } from 'react';
import { Language } from '../../strings/LanguageManager';
import { InfoModal } from '../modal/modal';
type InputHelperProps = {
    message: string;
    icon?: React.ReactElement;
    children: React.ReactElement;
};
export const InputHelper = ({ message, icon, children }: InputHelperProps) => {
    const [open, setOpen] = useState(false);
    return (
        <Grid container>
            {React.cloneElement(children as React.ReactElement<any>, {
                style: { flexGrow: 1 },
            })}
            <IconButton
                aria-label="help"
                onClick={() => {
                    setOpen(true);
                }}
            >
                {icon || <HelpOutline />}
            </IconButton>
            <InfoModal
                title={Language.strings.help}
                open={open}
                content={<Typography style={{ whiteSpace: 'pre-wrap' }}>{message}</Typography>}
                actions={<></>}
                onClose={() => setOpen(false)}
            />
        </Grid>
    );
};
