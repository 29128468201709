import { AxiosInstance } from 'axios';
import { httpToError } from '../../../../core/data/utils/http_to_error';
import { IAxiosDataSource } from '../../../authentication/data/datasources/interfaces/axios_datasource_interface';
import { ResidueTypeEntity } from '../entities/residue_type_entity';
import { IResidueDataSource } from './interfaces/residue_datasource_interface';

export class AxiosResidueDataSource implements IAxiosDataSource, IResidueDataSource {
    axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    async getResidueTypes(): Promise<ResidueTypeEntity[]> {
        try {
            const { data } = await this.axiosInstance.get(`residuetypes`);
            return Promise.resolve(data.map((item: any) => ResidueTypeEntity.fromObject(item)));
        } catch (error) {
            return Promise.reject(httpToError<void>(error));
        }
    }
}
