import { IUseCase } from '../../../../../core/domain/interfaces/usecase_interface';
import { IAuthenticationRepository } from '../../repositories/interfaces/authentication_repository_interface';

export class Logout implements IUseCase<void, void> {
    repository: IAuthenticationRepository;

    constructor(repository: IAuthenticationRepository) {
        this.repository = repository;
    }

    async execute(): Promise<void> {
        return this.repository.logout();
    }
}
