import { AxiosInstance } from 'axios';
import { UserEntity } from '../../../../core/data/entities/user_entity';
import { AuthorizationError } from '../../../../core/data/errors/authorization_error';
import { httpToError } from '../../../../core/data/utils/http_to_error';
import { ChangePasswordParams } from '../../domain/usecases/change_password/change_password';
import { LoginParams } from '../../domain/usecases/login/login';
import { RegisterUserParams } from '../../domain/usecases/register_user/register_user';
import { LoginValidations } from '../validation_interfaces/login_validations';
import { PasswordRecoveryValidations } from '../validation_interfaces/password_recovery_validations';
import { RegistrationValidations } from '../validation_interfaces/registration_validations';
import { AuthenticationResponse, IAuthenticationDataSource } from './interfaces/authentication_datasource_interface';
import { IAxiosDataSource } from './interfaces/axios_datasource_interface';

export class AxiosAuthenticationDataSource implements IAxiosDataSource, IAuthenticationDataSource {
    axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }
    async changePassword({ password, token }: ChangePasswordParams): Promise<void> {
        try {
            await this.axiosInstance.post('authentications/password-reset-confirm', { password, token });
        } catch (error) {
            if (error.response && error.response.status === 400 && error.response.data['token'])
                return Promise.reject(new AuthorizationError(error.response.data['token'].message));

            return Promise.reject(httpToError<PasswordRecoveryValidations>(error));
        }
    }
    async requestPasswordRecovery(email: string): Promise<void> {
        try {
            await this.axiosInstance.post('authentications/password-reset-request', {
                email,
            });
        } catch (error) {
            return Promise.reject(httpToError<PasswordRecoveryValidations>(error));
        }
    }

    async login({ username, password }: LoginParams): Promise<AuthenticationResponse> {
        try {
            const { data } = await this.axiosInstance.post('authentications/login', { username, password });
            this.axiosInstance.defaults.headers.Authorization = `Bearer ${data.token}`;
            return Promise.resolve({ token: data.token, user: UserEntity.fromObject(data.user) });
        } catch (error) {
            return Promise.reject(httpToError<LoginValidations>(error));
        }
    }

    async registerUser({
        username,
        email,
        name,
        surname,
        identificationNumber,
        businessName,
        password,
    }: RegisterUserParams): Promise<UserEntity> {
        try {
            const { data } = await this.axiosInstance.post('users', {
                username,
                email,
                password,
                name,
                surname,
                identification_number: identificationNumber,
                business_name: businessName,
            });

            return UserEntity.fromObject(data);
        } catch (error) {
            return Promise.reject(httpToError<RegistrationValidations>(error));
        }
    }
}
