import { Grid, Paper, Typography } from '@material-ui/core';

import { Language } from '../../../../../core/presentation/strings/LanguageManager';
import React from 'react';
import _ from 'lodash';
import couponImg from '../../../../../assets/images/coupon.png';
import di from '../../../../../core/app/dependency_injection/di';
import { useStyles } from './styles';

const server_url = process.env.REACT_APP_SERVER_URL;

type CouponDetailsProps = {
    couponDetails: {
        title: string;
        image: string | File;
        price: number;
        terms: string;
        finishDate: string;
        stock: number;
        description: string;
    };
    hide?: ('title' | 'image' | 'price' | 'terms' | 'finishDate' | 'stock' | 'description')[];
    className?: string;
};
export function CouponDetails({ couponDetails, hide = [], className }: CouponDetailsProps) {
    const { title, image, price, terms, description, finishDate, stock } = couponDetails;
    const classes = useStyles();
    const dateFormatter = di.dateFormatter();
    let imageUrl = couponImg;
    if (image) {
        if (image instanceof File) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(image);
            imageUrl = URL.createObjectURL(image);
        } else imageUrl = `${server_url}${image}`;
    }
    return (
        <>
            <Paper className={`${classes.card} ${className}`}>
                {!hide.includes('image') && (
                    <div className={classes.image_viewport}>
                        <img className={classes.image} src={imageUrl} />
                    </div>
                )}
                <Grid container className={`${classes.info} ${classes.pt}`}>
                    {!hide.includes('title') && (
                        <Grid item xs={12}>
                            <Typography color={title !== '' ? 'textPrimary' : 'error'} className={classes.title}>
                                {(title !== '' && title) || '[' + Language.strings.couponTitle + ']'}
                            </Typography>
                        </Grid>
                    )}
                    {!hide.includes('price') && (
                        <Grid item xs={12}>
                            <Typography color="textPrimary" className={classes.price}>
                                {`${price} pts.`}
                            </Typography>
                        </Grid>
                    )}
                    {!hide.includes('description') && (
                        <Grid item xs={12}>
                            <Typography
                                color={description !== '' ? 'textPrimary' : 'error'}
                                className={classes.description}
                            >
                                {(description !== '' && description) || '[' + Language.strings.couponDescription + ']'}
                            </Typography>
                        </Grid>
                    )}
                    {!hide.includes('terms') && (
                        <Grid item xs={12}>
                            <Typography
                                color="textPrimary"
                                className={`${classes.terms} ${classes.secondaryText} ${classes.pt}`}
                            >
                                {Language.strings.couponTerms}:
                            </Typography>
                            <Typography
                                color={terms !== '' ? 'textPrimary' : 'error'}
                                className={classes.secondaryText}
                            >
                                {(terms !== '' && terms) || '[' + Language.strings.couponTerms + ']'}
                            </Typography>
                        </Grid>
                    )}
                    {!hide.includes('finishDate') && (
                        <Grid item xs={12}>
                            <Typography
                                color={finishDate !== '' ? 'textPrimary' : 'error'}
                                className={`${classes.secondaryText} ${classes.finishDate} ${classes.pt}`}
                            >
                                {`Cupón válido hasta el ${
                                    (finishDate !== '' && dateFormatter.format('DD/MM/YYYY', finishDate)) ||
                                    '[' + Language.strings.couponFinishDate + ']'
                                }`}
                            </Typography>
                        </Grid>
                    )}
                    {!hide.includes('stock') && (
                        <Grid item xs={12}>
                            <Typography color="textPrimary" className={classes.secondaryText}>
                                {`${_.toUpper(Language.strings.couponStock)}: ${stock}`}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </>
    );
}
