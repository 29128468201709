import { IModel } from '../../../../core/domain/interfaces/model_interface';
import { EcoPointModel } from '../../../ecopoints/domain/models/ecopoint_model';
import { ResidueModel } from '../../../residues/domain/models/residue_model';

type CollectionModelArgs = {
    count: number;
    amount: number;
    validationEcopoint?: EcoPointModel | undefined;
    validationDate?: string | undefined;
    residue: ResidueModel;
};

export class CollectionModel implements IModel {
    _type = 'CollectionModel';
    count: number;
    amount: number;
    validationEcopoint?: EcoPointModel;
    validationDate?: string;
    residue: ResidueModel;

    constructor({ count, amount, validationEcopoint, validationDate, residue }: CollectionModelArgs) {
        this.count = count;
        this.amount = amount;
        this.validationDate = validationDate;
        this.validationEcopoint = validationEcopoint;
        this.residue = residue;
    }
}
