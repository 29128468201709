import { Button, Checkbox, Grid, TextField, Typography } from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import React, { useEffect } from 'react';

import { AuthenticationFailure } from '../../../../../core/domain/failures/authentication_failure';
import { BlocBuilder } from '../../../../../core/presentation/bloc';
import { Language } from '../../../../../core/presentation/strings/LanguageManager';
import { LoginPageState } from '../../blocs/login_page_bloc/login_page_states';
import { PasswordInput } from '../../../../../core/presentation/components/password_field/password_field';
import { ValidationFailure } from '../../../../../core/domain/failures/validation_failure';
import { useLocalStorage } from '../../../../../core/presentation/hooks/useLocalStorage';
import { useLoginPageBloc } from '../../../../../core/presentation/contexts/contexts';
import { useStyles } from './styles';

export function LoginForm() {
    const loginPageBloc = useLoginPageBloc();
    const [active, setActive, resetActive] = useLocalStorage(1, 'activeTab');
    const [acceptedTerms, setAcceptedTerms] = useLocalStorage(false, 'terms');
    const classes = useStyles();
    const handleInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        loginPageBloc.changeInput(event.target.name as 'username' | 'password', event.target.value);
    };
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        loginPageBloc.login();
    };
    useEffect(() => {
        resetActive();
    }, []);
    return (
        <BlocBuilder
            bloc={loginPageBloc}
            builder={(state: LoginPageState) => {
                const getErrorString = (input: string) => {
                    if (state._type === 'Failure' && state.failure instanceof ValidationFailure) {
                        if (state.failure.fails[input])
                            return Language.strings.getString(`login_${input}_${state.failure.fails[input].code}`);
                    }
                    return '';
                };
                if (state._type === 'Success') return <Redirect to="/stores" />;
                return (
                    <form onSubmit={handleSubmit}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="username"
                                    label={Language.strings.username}
                                    type="text"
                                    value={state.username}
                                    error={state._type === 'Failure'}
                                    helperText={getErrorString('username')}
                                    onChange={handleInputChanged}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item>
                                <PasswordInput
                                    fullWidth
                                    variant="outlined"
                                    name="password"
                                    label={Language.strings.password}
                                    value={state.password}
                                    error={state._type === 'Failure'}
                                    helperText={getErrorString('password')}
                                    onChange={handleInputChanged}
                                />
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Checkbox
                                        className={classes.checkbox}
                                        color="default"
                                        checked={acceptedTerms}
                                        onChange={() => setAcceptedTerms(!acceptedTerms)}
                                    />
                                    <Typography color="secondary">
                                        Acepto los{' '}
                                        <Link
                                            color="primary"
                                            to={{ pathname: 'https://ecobuy.com.ar/legales' }}
                                            target="_blank"
                                        >
                                            Términos y Condiciones de Uso.
                                        </Link>
                                    </Typography>
                                </Grid>
                            </Grid>

                            {state._type === 'Failure' && state.failure instanceof AuthenticationFailure ? (
                                <Grid item>
                                    <Typography variant="caption" color="error">
                                        {Language.strings.login_failed}
                                    </Typography>
                                </Grid>
                            ) : null}

                            {state._type === 'Failure' &&
                            !(
                                state.failure instanceof ValidationFailure ||
                                state.failure instanceof AuthenticationFailure
                            ) ? (
                                <Grid item>
                                    <Typography variant="caption" color="error">
                                        {Language.strings.unknown_error}
                                    </Typography>
                                </Grid>
                            ) : null}

                            <Grid item>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={state._type === 'Submitting' || !acceptedTerms}
                                    type="submit"
                                >
                                    {state._type === 'Submitting'
                                        ? Language.strings.submitting
                                        : Language.strings.login}
                                </Button>
                            </Grid>
                            <Grid item className={classes.link}>
                                <Link to="/password/request" className="link-dark">
                                    {Language.strings.forgot_password}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                );
            }}
        ></BlocBuilder>
    );
}
