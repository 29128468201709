import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import React, { useState } from 'react';
import di from '../../../app/dependency_injection/di';
import { Routes } from '../../routes/routes';
import createLightTheme from '../../themes/light_theme';
import { useStyles } from './styles';

const version = process.env.REACT_APP_VERSION;

function AppContent() {
    const classes = useStyles();
    return (
        <div className={`App ${classes.app}`}>
            <Routes />
        </div>
    );
}

function App(): JSX.Element {
    const [dependenciesLoaded, setDependenciesLoaded] = useState(false);
    di.loadAsyncDependencies().then(() => {
        setDependenciesLoaded(true);
    });
    console.log(`Ecobuy v ${version}`);
    if (dependenciesLoaded) {
        console.log('Async Dependencies Loaded');
        return (
            <ThemeProvider theme={createLightTheme()}>
                <SnackbarProvider maxSnack={3}>
                    <CssBaseline />
                    <AppContent />
                </SnackbarProvider>
            </ThemeProvider>
        );
    } else {
        console.log('Loading Async Dependencies');
        return <></>;
    }
}

export default App;
