import { Button, Grid } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import React from 'react';
import { useStyles } from './styles';

type UploadButtonProps = {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    icon?: React.ReactElement;
    color?: 'inherit' | 'primary' | 'secondary' | 'default' | undefined;
    className?: string;
    name?: string;
    label?: string;
    variant?: 'contained' | 'outlined' | 'text';
};

export const UploadButton = ({ onChange, name, icon, color, className, label, variant }: UploadButtonProps) => {
    const classes = useStyles();
    return (
        <>
            <input
                accept=".png,.jpg,.jpeg"
                name={name}
                className={classes.input}
                id="icon-button-file"
                type="file"
                onChange={onChange}
            />
            <label htmlFor="icon-button-file" className={classes.label}>
                <Button component="span" color={color} className={className} variant={variant}>
                    <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        justify="space-between"
                        style={{ flexWrap: 'nowrap' }}
                    >
                        <Grid item style={{ display: 'flex' }}>
                            {icon ? icon : <CloudUploadIcon />}
                        </Grid>
                        <Grid item>{label}</Grid>
                    </Grid>
                </Button>
                {/* <IconButton
                    color={color}
                    aria-label="upload picture"
                    component="span"
                    className={className}
                ></IconButton> */}
            </label>
        </>
    );
};
