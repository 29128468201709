import _ from 'lodash';
import { Bloc } from '..';
import { DataTableFilter, DataTableOrder } from '../../../domain/interfaces/datatable_params_interface';
import { DataTableStates } from './datatable_bloc_states';

export class DataTableBloc<Model> extends Bloc<DataTableStates<Model>> {
    constructor(initialState: DataTableStates<Model>) {
        super(initialState);
    }
    changeFilters = async (newFilters: Array<DataTableFilter>): Promise<void> => {
        const { size, filters, order } = this.state;
        if (!_.isEqual(newFilters, filters))
            this.changeState({ _type: 'FiltersChanged', filters: newFilters, page: 0, size, order, selection: [] });
    };
    changeOrder = async (newOrder: DataTableOrder | undefined): Promise<void> => {
        const { page, size, filters, order } = this.state;
        if (!_.isEqual(newOrder, order))
            this.changeState({ _type: 'OrderChanged', order: newOrder, page, size, filters, selection: [] });
    };
    changePage = async (newPage: number): Promise<void> => {
        const { page, size, filters, order } = this.state;
        if (page !== newPage)
            this.changeState({ _type: 'PageChanged', order, page: newPage, size, filters, selection: [] });
    };
    changeSize = async (newSize: number): Promise<void> => {
        const { size, filters, order } = this.state;
        if (size !== newSize)
            this.changeState({ _type: 'SizeChanged', order, size: newSize, page: 0, filters, selection: [] });
    };
    changeSelection = async (newSelection: number[]) => {
        const prevState = this.state;
        if (!_.isEqual(prevState.selection, newSelection)) this.changeState({ ...prevState, selection: newSelection });
    };
}
