import { Marker as _Marker } from 'leaflet';
import React, { useMemo, useRef, useState } from 'react';
import { Marker } from 'react-leaflet';
import { Coordinates } from '../../../domain/interfaces/coordinates_interface';

export type DraggableMarkerProps = {
    initialPosition?: Coordinates;
    onChange?: (value: Coordinates) => void;
};
export const DraggableMarker = ({
    initialPosition = { lat: -31.416638415050677, lng: -64.18355818593822 },
    onChange,
}: DraggableMarkerProps) => {
    const [position, setPosition] = useState(initialPosition);
    const markerRef = useRef<_Marker>(null);
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current;
                if (marker != null) {
                    setPosition(marker.getLatLng());
                    if (onChange) onChange(marker.getLatLng());
                }
            },
        }),
        [],
    );

    return <Marker draggable={true} eventHandlers={eventHandlers} position={position} ref={markerRef}></Marker>;
};
