export interface CouponsStrings {
    coupon: string;
    coupons: string;
    couponRead: string;
    couponCreate: string;
    couponUpdate: string;
    couponDelete: string;
    couponTitle: string;
    couponDescription: string;
    couponTags: string;
    couponTerms: string;
    couponPrice: string;
    couponStock: string;
    couponStartDate: string;
    couponFinishDate: string;
    couponStore: string;
    couponCategory: string;
    couponImage: string;
    couponCreateSuccessful: string;
    couponEditSuccessful: string;
    //Errors

    coupon_id_exists_error: string;
    coupon_title_required_error: string;
    coupon_description_required_error: string;
    coupon_tags_required_error: string;
    coupon_price_required_error: string;
    coupon_price_numeric_error: string;
    coupon_image_required_error: string;
    coupon_image_max_error: string;
    coupon_image_mimes_error: string;
    coupon_terms_required_error: string;
    coupon_startDate_required_error: string;
    coupon_finishDate_required_error: string;
    coupon_stock_required_error: string;
    coupon_stock_numeric_error: string;
    coupon_store_required_error: string;
    coupon_category_required_error: string;
    coupon_createCoupon_exist_seller_error: string;
    coupon_updateCoupon_exist_seller_error: string;
    coupon_deleteCoupon_exist_seller_error: string;
    coupon_createCoupon_user_active_error: string;
    coupon_updateCoupon_user_active_error: string;
    coupon_deleteCoupon_user_active_error: string;
    coupon_createCoupon_ownership_store_error: string;
    coupon_updateCoupon_ownership_store_error: string;
    coupon_deleteCoupon_ownership_store_error: string;
    coupon_createCoupon_upload_error: string;
}

export const couponStrings: CouponsStrings = {
    coupon: 'Cupón',
    coupons: 'Cupones',
    couponRead: 'Ver',
    couponCreate: 'Crear',
    couponUpdate: 'Editar',
    couponDelete: 'Eliminar',
    couponTitle: 'Título',
    couponDescription: 'Descripción',
    couponTags: 'Palabras Claves (separadas por comas)',
    couponTerms: 'Términos y Condiciones',
    couponPrice: 'Puntos',
    couponStock: 'Stock',
    couponStartDate: 'Fecha de Inicio',
    couponFinishDate: 'Fecha de Finalización',
    couponCategory: 'Categoría',
    couponStore: 'Tienda',
    couponImage: 'Subir/Actualizar imagen (4:3)',
    couponCreateSuccessful: '¡El cupón ha sido creado exitosamente!',
    couponEditSuccessful: '¡El cupón ha sido modificado exitosamente!',

    //Errors

    coupon_id_exists_error: 'Error: El Cupón no existe.',
    coupon_title_required_error: 'El campo Título es requerido.',
    coupon_description_required_error: 'El campo Descripción es requerido.',
    coupon_tags_required_error: 'El campo Palabras Claves es requerido.',
    coupon_price_required_error: 'El campo Precio es requerido.',
    coupon_price_numeric_error: 'El campo Precio debe ser un número.',
    coupon_image_required_error: 'Error: El campo Imagen es requerido.',
    coupon_image_max_error: 'Error: El campo Imagen debe ser menor a 5 MB.',
    coupon_image_mimes_error: 'Error: El campo Imagen debe ser jpg, png o jpeg.',
    coupon_terms_required_error: 'El campo Términos y Condiciones es requerido.',
    coupon_startDate_required_error: 'El campo Fecha de Inicio es requerido.',
    coupon_finishDate_required_error: 'El campo Fecha de Finalización es requerido.',
    coupon_stock_required_error: 'El campo Stock es requerido.',
    coupon_stock_numeric_error: 'El campo Stock debe ser un número.',
    coupon_store_required_error: 'El campo Tienda es requerido.',
    coupon_category_required_error: 'El campo Categoría es requerido.',
    coupon_createCoupon_exist_seller_error: 'Error: La cuenta de comerciante no existe.',
    coupon_updateCoupon_exist_seller_error: 'Error: La cuenta de comerciante no existe.',
    coupon_deleteCoupon_exist_seller_error: 'Error: La cuenta de comerciante no existe.',
    coupon_createCoupon_user_active_error: 'Error: Su cuenta está inactiva',
    coupon_updateCoupon_user_active_error: 'Error: Su cuenta está inactiva',
    coupon_deleteCoupon_user_active_error: 'Error: Su cuenta está inactiva',
    coupon_createCoupon_ownership_store_error: 'Error: La Tienda seleccionada no le pertenece.',
    coupon_updateCoupon_ownership_store_error: 'Error: La Tienda seleccionada no le pertenece.',
    coupon_deleteCoupon_ownership_store_error: 'Error: La Tienda seleccionada no le pertenece.',
    coupon_createCoupon_upload_error: 'Error: El campo Imagen es requerido.',
};
