import { Coordinates } from '../../../../core/domain/interfaces/coordinates_interface';
import { IModel } from '../../../../core/domain/interfaces/model_interface';
import { UserModel } from '../../../../core/domain/models/user_model';

export type StoreModelArgs = {
    id: number;
    name: string;
    address: string;
    coordinates: Coordinates;
    phoneNumber: string;
    receiveCoupons: boolean;
    mainStores: number[];
    providerStores: number[];
    isProvider: boolean;
    seller: UserModel;
};

export class StoreModel implements IModel {
    _type = 'StoreModel';

    id: number;
    name: string;
    address: string;
    coordinates: Coordinates;
    phoneNumber: string;
    receiveCoupons: boolean;
    mainStores: number[];
    providerStores: number[];
    isProvider: boolean;
    seller: UserModel;

    constructor({
        id,
        name,
        address,
        coordinates,
        phoneNumber,
        receiveCoupons,
        mainStores,
        providerStores,
        isProvider,
        seller,
    }: StoreModelArgs) {
        this.id = id;
        this.name = name;
        this.address = address;
        this.coordinates = coordinates;
        this.phoneNumber = phoneNumber;
        this.receiveCoupons = receiveCoupons;
        this.mainStores = mainStores;
        this.providerStores = providerStores;
        this.isProvider = isProvider;
        this.seller = seller;
    }
}
