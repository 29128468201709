/* istanbul ignore file */

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { AxiosAuthenticationDataSource } from '../../../features/authentication/data/datasources/axios_authentication_datasource';
import { ICacheDataSource } from '../../../features/authentication/data/datasources/interfaces/cache_datasource_interface';
import { SessionStorageCacheDataSource } from '../../../features/authentication/data/datasources/session_storage_cache_datasource';
import { AuthenticationRepository } from '../../../features/authentication/data/repositories/authentication_repository';
import { ChangePassword } from '../../../features/authentication/domain/usecases/change_password/change_password';
import { GetAuthenticatedUser } from '../../../features/authentication/domain/usecases/get_authenticated_user/get_authenticated_user';
import { Login } from '../../../features/authentication/domain/usecases/login/login';
import { Logout } from '../../../features/authentication/domain/usecases/logout/logout';
import { RequestPasswordRecovery } from '../../../features/authentication/domain/usecases/request_recovery/request_recovery';
import { AuthenticationBloc } from '../../../features/authentication/presentation/blocs/authentication_bloc/authentication_bloc';
import { LoginPageBloc } from '../../../features/authentication/presentation/blocs/login_page_bloc/login_page_bloc';
import { PasswordRecoveryBloc } from '../../../features/authentication/presentation/blocs/password_recovery_bloc/password_recovery_bloc';
import { AxiosCollectionDataSource } from '../../../features/collections/data/datasources/axios_collection_datasource';
import { CollectionRepository } from '../../../features/collections/data/repositories/collection_repository';
import { ICollectionRepository } from '../../../features/collections/domain/repositories/collection_repository_interface';
import { CacheEcoPoint } from '../../../features/collections/domain/usecases/cache_ecopoint';
import { GetCachedEcoPoint } from '../../../features/collections/domain/usecases/get_cached_ecopoint';
import { ValidateCollections } from '../../../features/collections/domain/usecases/validate_collections';
import { CollectionValidationFormBloc } from '../../../features/collections/presentation/blocs/collections_validation_form_bloc';
import { AxiosCategoryDataSource } from '../../../features/coupons/data/datasources/axios_category_datasource';
import { AxiosCouponDataSource } from '../../../features/coupons/data/datasources/axios_coupon_datasource';
import { CategoryRepository } from '../../../features/coupons/data/repositories/category_repository';
import { CouponRepository } from '../../../features/coupons/data/repositories/coupon_repository';
import { CouponModel } from '../../../features/coupons/domain/models/coupon_model';
import { ICategoryRepository } from '../../../features/coupons/domain/repositories/category_repository_interface';
import { ICouponRepository } from '../../../features/coupons/domain/repositories/coupon_repository_interface';
import { CreateCoupon } from '../../../features/coupons/domain/usecases/create_coupon';
import { DeleteCoupon } from '../../../features/coupons/domain/usecases/delete_coupon';
import { GetCategories } from '../../../features/coupons/domain/usecases/get_categories';
import { GetUserCoupons } from '../../../features/coupons/domain/usecases/get_user_coupons';
import { UpdateCoupon } from '../../../features/coupons/domain/usecases/update_coupon';
import { CouponsDataTableBloc } from '../../../features/coupons/presentation/blocs/coupons_datatable_bloc';
import { CouponFormBloc } from '../../../features/coupons/presentation/blocs/coupon_form_bloc';
import { AxiosEcoPointDataSource } from '../../../features/ecopoints/data/datasources/axios_ecopoint_datasource';
import { EcoPointRepository } from '../../../features/ecopoints/data/repositories/ecopoint_repository';
import { EcoPointModel } from '../../../features/ecopoints/domain/models/ecopoint_model';
import { IEcoPointRepository } from '../../../features/ecopoints/domain/repositories/ecopoint_repository_interface';
import { CreateEcoPoint } from '../../../features/ecopoints/domain/usecases/create_ecopoint';
import { DeleteEcoPoint } from '../../../features/ecopoints/domain/usecases/delete_ecopoint';
import { GetUserEcoPoints } from '../../../features/ecopoints/domain/usecases/get_user_ecopoints';
import { UpdateEcoPoint } from '../../../features/ecopoints/domain/usecases/update_ecopoint';
import { EcoPointsDataTableBloc } from '../../../features/ecopoints/presentation/blocs/ecopoint_datatable_bloc';
import { EcoPointFormBloc } from '../../../features/ecopoints/presentation/blocs/ecopoint_form_bloc';
import { AxiosPurchaseDataSource } from '../../../features/purchases/data/datasources/axios_purchase_datasource';
import { PurchaseRepository } from '../../../features/purchases/data/repositories/purchase_repository';
import { IPurchaseRepository } from '../../../features/purchases/domain/repositories/purchase_repository_interface';
import { CacheStore } from '../../../features/purchases/domain/usecases/cache_store';
import { GetCachedStore } from '../../../features/purchases/domain/usecases/get_cached_store';
import { GetStorePurchases } from '../../../features/purchases/domain/usecases/get_store_purchases';
import { RedeemPurchase } from '../../../features/purchases/domain/usecases/redeem_purchase';
import { PurchasesDataTableBloc } from '../../../features/purchases/presentation/blocs/purchase_datatable_bloc';
import { PurchaseFormBloc } from '../../../features/purchases/presentation/blocs/purchase_form_bloc';
import { AxiosResidueDataSource } from '../../../features/residues/data/datasources/axios_residue_datasource';
import { ResidueRepository } from '../../../features/residues/data/repositories/residue_repository';
import { IResidueRepository } from '../../../features/residues/domain/repositories/residue_repository';
import { GetResidueTypes } from '../../../features/residues/domain/usecases/get_residue_types';
import { AxiosStoreDataSource } from '../../../features/stores/data/datasources/axios_store_datasource';
import { StoreRepository } from '../../../features/stores/data/repositories/store_repository';
import { StoreModel } from '../../../features/stores/domain/models/store_model';
import { IStoreRepository } from '../../../features/stores/domain/repositories/store_repository_interface';
import { CreateStore } from '../../../features/stores/domain/usecases/create_store';
import { DeleteStore } from '../../../features/stores/domain/usecases/delete_store';
import { GetProviders } from '../../../features/stores/domain/usecases/get_providers';
import { GetUserStores } from '../../../features/stores/domain/usecases/get_user_stores';
import { UpdateStore } from '../../../features/stores/domain/usecases/update_store';
import { StoresDataTableBloc } from '../../../features/stores/presentation/blocs/stores_datatable_bloc';
import { StoreFormBloc } from '../../../features/stores/presentation/blocs/store_form_bloc';
import { DeletionBloc } from '../../presentation/bloc/deletion_bloc/deletion_bloc';
import { IDateFormatter } from '../../presentation/formaters/interfaces/date_formatter_interface';
import { MomentSpanishDateFormatter } from '../../presentation/formaters/moment_date_formatter';
import { Language } from '../../presentation/strings/LanguageManager';

const api_url = process.env.REACT_APP_API_URL;

class DependencyInjector {
    private static axiosInstance?: AxiosInstance = undefined;

    static async loadAsyncDependencies() {
        Language.set('es');
        await this.createAxiosInstance(new SessionStorageCacheDataSource());
    }

    static async createAxiosInstance(cacheDataSource: ICacheDataSource, config?: AxiosRequestConfig): Promise<void> {
        const defaultHeaders = { 'Access-Control-Allow-Origin': true };
        if (!this.axiosInstance) {
            const token = await cacheDataSource.get('token');
            this.axiosInstance = axios.create({
                ...config,
                baseURL: api_url,
                headers: token ? { ...defaultHeaders, Authorization: 'Bearer ' + token } : defaultHeaders,
            });
        }
        this.axiosInstance.defaults = { ...this.axiosInstance.defaults, timeout: 180000, ...config };
    }

    private static authenticationRepository() {
        const cacheDataSource = new SessionStorageCacheDataSource();
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const apiDataSource = new AxiosAuthenticationDataSource(axios);
        return new AuthenticationRepository(apiDataSource, cacheDataSource);
    }

    static loginPageBloc(): LoginPageBloc {
        const usecase = new Login(this.authenticationRepository());
        return new LoginPageBloc(usecase);
    }
    static authBloc(): AuthenticationBloc {
        const getAuthenticatedUser = new GetAuthenticatedUser(this.authenticationRepository());
        const logout = new Logout(this.authenticationRepository());
        return new AuthenticationBloc(getAuthenticatedUser, logout);
    }
    static passwordRecoveryBloc(): PasswordRecoveryBloc {
        const requestPasswordRecoveryUseCase = new RequestPasswordRecovery(this.authenticationRepository());
        const changePasswordUseCase = new ChangePassword(this.authenticationRepository());
        return new PasswordRecoveryBloc({ requestPasswordRecoveryUseCase, changePasswordUseCase });
    }

    private static storesRepository(): IStoreRepository {
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const datasource = new AxiosStoreDataSource(axios);
        return new StoreRepository(datasource);
    }

    static storesDatatableBloc(): StoresDataTableBloc {
        const usecase = new GetUserStores(this.storesRepository());
        return new StoresDataTableBloc(usecase);
    }

    static storeFormBloc(): StoreFormBloc {
        const createStoreUseCase = new CreateStore(this.storesRepository());
        const updateStoreUseCase = new UpdateStore(this.storesRepository());
        const getUserStoresUseCase = new GetUserStores(this.storesRepository());
        const getProvidersUseCase = new GetProviders(this.storesRepository());
        return new StoreFormBloc(createStoreUseCase, updateStoreUseCase, getUserStoresUseCase, getProvidersUseCase);
    }

    static storeDeletionBloc(): DeletionBloc<StoreModel> {
        const deleteUseCase = new DeleteStore(this.storesRepository());
        return new DeletionBloc<StoreModel>(deleteUseCase, { _type: 'Idle', model: undefined });
    }

    private static ecopointsRepository(): IEcoPointRepository {
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const datasource = new AxiosEcoPointDataSource(axios);
        return new EcoPointRepository(datasource);
    }

    private static residueRepository(): IResidueRepository {
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const datasource = new AxiosResidueDataSource(axios);
        return new ResidueRepository(datasource);
    }

    static ecopointsDatatableBloc(): EcoPointsDataTableBloc {
        const usecase = new GetUserEcoPoints(this.ecopointsRepository());
        return new EcoPointsDataTableBloc(usecase);
    }

    static ecopointFormBloc(): EcoPointFormBloc {
        const createUseCase = new CreateEcoPoint(this.ecopointsRepository());
        const updateUseCase = new UpdateEcoPoint(this.ecopointsRepository());
        const getResidueTypesUseCase = new GetResidueTypes(this.residueRepository());
        return new EcoPointFormBloc(createUseCase, updateUseCase, getResidueTypesUseCase);
    }

    static ecopointDeletionBloc(): DeletionBloc<EcoPointModel> {
        const deleteUseCase = new DeleteEcoPoint(this.ecopointsRepository());
        return new DeletionBloc<EcoPointModel>(deleteUseCase, { _type: 'Idle', model: undefined });
    }

    private static couponsRepository(): ICouponRepository {
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const datasource = new AxiosCouponDataSource(axios);
        return new CouponRepository(datasource);
    }
    private static categoryRepository(): ICategoryRepository {
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const datasource = new AxiosCategoryDataSource(axios);
        return new CategoryRepository(datasource);
    }

    static couponsDatatableBloc(): CouponsDataTableBloc {
        const usecase = new GetUserCoupons(this.couponsRepository());
        return new CouponsDataTableBloc(usecase);
    }

    static couponFormBloc(): CouponFormBloc {
        const createUseCase = new CreateCoupon(this.couponsRepository());
        const updateUseCase = new UpdateCoupon(this.couponsRepository());
        const getUserStoresUSeCase = new GetUserStores(this.storesRepository());
        const getCategoriesUseCase = new GetCategories(this.categoryRepository());
        return new CouponFormBloc(createUseCase, updateUseCase, getUserStoresUSeCase, getCategoriesUseCase);
    }

    static couponDeletionBloc(): DeletionBloc<CouponModel> {
        const deleteUseCase = new DeleteCoupon(this.couponsRepository());
        return new DeletionBloc<CouponModel>(deleteUseCase, { _type: 'Idle', model: undefined });
    }

    static purchaseRepository(): IPurchaseRepository {
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const apiDataSource = new AxiosPurchaseDataSource(axios);
        const cacheDataSource = new SessionStorageCacheDataSource();
        return new PurchaseRepository(apiDataSource, cacheDataSource);
    }

    static purchaseFormBloc(): PurchaseFormBloc {
        const redeemPurchaseUseCase = new RedeemPurchase(this.purchaseRepository());
        const getUserStoresUseCase = new GetUserStores(this.storesRepository());
        const getCachedStoreUseCase = new GetCachedStore(this.purchaseRepository());
        const cacheStoreUseCase = new CacheStore(this.purchaseRepository());

        return new PurchaseFormBloc({
            redeemPurchaseUseCase,
            getUserStoresUseCase,
            getCachedStoreUseCase,
            cacheStoreUseCase,
        });
    }

    static purchasesDataTableBloc(): PurchasesDataTableBloc {
        const getStorePurchases = new GetStorePurchases(this.purchaseRepository());
        return new PurchasesDataTableBloc(getStorePurchases);
    }

    static collectionRepository(): ICollectionRepository {
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const apiDataSource = new AxiosCollectionDataSource(axios);
        const cacheDataSource = new SessionStorageCacheDataSource();
        return new CollectionRepository(apiDataSource, cacheDataSource);
    }

    static collectionValidationFormBloc(): CollectionValidationFormBloc {
        const validateCollectionsUseCase = new ValidateCollections(this.collectionRepository());
        const getUserEcoPointsUseCase = new GetUserEcoPoints(this.ecopointsRepository());
        const getCachedEcoPointUseCase = new GetCachedEcoPoint(this.collectionRepository());
        const cacheEcoPointUseCase = new CacheEcoPoint(this.collectionRepository());
        return new CollectionValidationFormBloc({
            validateCollectionsUseCase,
            getUserEcoPointsUseCase,
            getCachedEcoPointUseCase,
            cacheEcoPointUseCase,
        });
    }

    static dateFormatter(): IDateFormatter {
        return new MomentSpanishDateFormatter();
    }
}
export default DependencyInjector;
