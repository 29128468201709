import { errorToFailure } from '../../../../core/data/utils/error_to_failure';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { CategoryModel } from '../../domain/models/category_model';
import { ICategoryRepository } from '../../domain/repositories/category_repository_interface';
import { ICategoryDataSource } from '../datasources/interfaces/category_datasource_interface';
import { CategoryEntity } from '../entities/category_entity';

export class CategoryRepository implements ICategoryRepository {
    categoryDataSource: ICategoryDataSource;
    constructor(categoryDataSource: ICategoryDataSource) {
        this.categoryDataSource = categoryDataSource;
    }

    async getCategories(): Promise<CategoryModel[] | IFailure> {
        try {
            const result: CategoryEntity[] = await this.categoryDataSource.getCategories();
            return Promise.resolve(result.map((item: CategoryEntity) => new CategoryModel(item)));
        } catch (error) {
            return Promise.resolve(errorToFailure(error));
        }
    }
}
