import { DialogContent } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Toolbar from '@material-ui/core/Toolbar';
import { TransitionProps } from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type FullScreenModalProps = {
    className?: string;
    onClose?: () => void;
    open: boolean;
    title: string;
    content: React.ReactElement | string;
    actions?: React.ReactElement;
    disableClose?: boolean;
};

const FullScreenDialog: React.FC<FullScreenModalProps> = ({
    className,
    onClose,
    open,
    title,
    content,
    actions,
    disableClose,
}: FullScreenModalProps) => {
    const classes = useStyles();

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={disableClose ? undefined : onClose}
            TransitionComponent={Transition}
            className={className}
        >
            <AppBar color="secondary" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                        disabled={disableClose}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {title}
                    </Typography>
                    {actions}
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.content}>{content}</DialogContent>
        </Dialog>
    );
};
export default FullScreenDialog;
