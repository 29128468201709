import { Failure } from '../../../../../core/domain/failures/failure';

export interface CommonState {
    email: string;
    password: string;
}

export interface IIdle {
    _type: 'Idle';
}
export interface IInputChanged {
    _type: 'InputChanged';
}
export interface IRequesting {
    _type: 'Requesting';
}
export interface IRequestSuccessful {
    _type: 'RequestSuccessful';
}
export interface IRequestFailed {
    _type: 'RequestFailed';
    failure: Failure;
}
export interface ISubmitting {
    _type: 'Submitting';
}
export interface IPasswordChanged {
    _type: 'PasswordChanged';
}
export interface IChangeFailed {
    _type: 'ChangeFailed';
    failure: Failure;
}

export type PasswordRecoveryStates =
    | ((IRequesting | IRequestSuccessful | IRequestFailed | IIdle | IInputChanged) & CommonState)
    | ((ISubmitting | IPasswordChanged | IChangeFailed | IIdle | IInputChanged) & CommonState);

export const initialState: PasswordRecoveryStates = { _type: 'Idle', email: '', password: '' };
