import { ValidationFailure } from '../../domain/failures/validation_failure';
import { Language } from '../strings/LanguageManager';

const getErrorString = (input: string, state: any, prefix: string, concatInput = true) => {
    if (state._type === 'Failure' && state.failure instanceof ValidationFailure) {
        if (concatInput) {
            if (state.failure.fails[input])
                return Language.strings.getString(`${prefix}_${input}_${state.failure.fails[input].code}`);
        } else {
            return Language.strings.getString(`${prefix}_${state.failure.fails[input].code}`);
        }
    }
    return '';
};
export default getErrorString;
