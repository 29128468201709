import { Failure } from '../../../../../core/domain/failures/failure';
import { IUseCase } from '../../../../../core/domain/interfaces/usecase_interface';
import { Bloc } from '../../../../../core/presentation/bloc';
import { ChangePasswordParams } from '../../../domain/usecases/change_password/change_password';
import { initialState, PasswordRecoveryStates } from './password_recovery_states';

export type PasswordRecoveryBlocArgs = {
    requestPasswordRecoveryUseCase: IUseCase<void, string>;
    changePasswordUseCase: IUseCase<void, ChangePasswordParams>;
};
export class PasswordRecoveryBloc extends Bloc<PasswordRecoveryStates> {
    requestPasswordRecoveryUseCase: IUseCase<void, string>;
    changePasswordUseCase: IUseCase<void, ChangePasswordParams>;

    constructor({ requestPasswordRecoveryUseCase, changePasswordUseCase }: PasswordRecoveryBlocArgs) {
        super(initialState);
        this.requestPasswordRecoveryUseCase = requestPasswordRecoveryUseCase;
        this.changePasswordUseCase = changePasswordUseCase;
    }

    async requestPasswordRecovery(): Promise<void> {
        const { email, password } = this.state;
        this.changeState({ _type: 'Requesting', email, password });
        const result = await this.requestPasswordRecoveryUseCase.execute(email);
        if (result instanceof Failure)
            return this.changeState({ _type: 'RequestFailed', failure: result, email, password });
        this.changeState({ _type: 'RequestSuccessful', email, password });
    }

    async changePassword(token: string): Promise<void> {
        const { email, password } = this.state;
        this.changeState({ _type: 'Submitting', email, password });
        const result = await this.changePasswordUseCase.execute({ password, token });
        if (result instanceof Failure)
            return this.changeState({ _type: 'ChangeFailed', failure: result, email, password });
        this.changeState({ _type: 'PasswordChanged', email, password });
    }

    async changeInput(input: 'password' | 'email', value: string): Promise<void> {
        this.changeState({ ...this.state, _type: 'InputChanged', [input]: value });
    }
}
