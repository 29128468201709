import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100vw',
            height: '100vh',
            backgroundColor: theme.palette.secondary.main,
        },
        paper: {
            width: '100%',
            margin: '0 1rem',
            maxWidth: '400px',
            ['& .MuiCardHeader-title']: {
                textAlign: 'center',
            },
            ['& .MuiCardHeader-root']: {
                borderBottom: `1px solid ${theme.colors.greyTransparent}`,
            },
            ['& .MuiOutlinedInput-root fieldset']: {
                border: `2px solid ${theme.input.borderColor.main}`,
            },
        },
    }),
);
