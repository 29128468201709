import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pb: {
            marginBottom: '1.5rem',
        },
        paper: { width: '100%', padding: '1rem' },
        code: {
            ['& input']: {
                fontSize: '3rem',
            },
        },
        details: {
            margin: '0 auto 0 auto',
        },
    }),
);
