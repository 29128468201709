import { areAnyNil } from '../../../../core/app/utils/utils';
import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { IEntity } from '../../../../core/data/interfaces/entity_interface';
import { EcoPointEntity } from '../../../ecopoints/data/entities/ecopoint_entity';
import { EcoPointModel } from '../../../ecopoints/domain/models/ecopoint_model';
import { ResidueEntity } from '../../../residues/data/entities/residue_entity';
import { ResidueModel } from '../../../residues/domain/models/residue_model';
import { CollectionModel } from '../../domain/models/collection_model';

type CollectionEntityArgs = {
    id: number;
    count: number;
    amount: number;
    validationEcopoint?: EcoPointEntity;
    validationDate?: string;
    residue: ResidueEntity;
};

export class CollectionEntity implements IEntity {
    _type = 'CollectionEntity';
    id: number;
    count: number;
    amount: number;
    validationEcopoint?: EcoPointEntity | undefined;
    validationDate?: string | undefined;
    residue: ResidueEntity;

    constructor({ id, count, amount, validationEcopoint, validationDate, residue }: CollectionEntityArgs) {
        this.id = id;
        this.count = count;
        this.residue = residue;
        this.amount = amount;
        this.validationDate = validationDate;
        this.validationEcopoint = validationEcopoint;
        this.residue = residue;
    }

    toModel(): CollectionModel {
        const { count, amount, validationDate, residue, validationEcopoint } = this;
        return new CollectionModel({
            count: count,
            amount: amount,
            validationDate,
            residue: new ResidueModel(residue),
            validationEcopoint: validationEcopoint ? new EcoPointModel(validationEcopoint) : undefined,
        });
    }

    static fromObject(obj: any): CollectionEntity {
        try {
            const { id, count, amount, validation_date, residue, ecopoint } = obj;
            if (areAnyNil([id, count, amount, residue])) {
                throw new ConvertionError(JSON.stringify(obj), 'CollectionEntity');
            }
            return new CollectionEntity({
                id,
                count: count,
                amount: amount,
                residue: ResidueEntity.fromObject(residue),
                validationDate: validation_date?.date || undefined,
                validationEcopoint: ecopoint ? EcoPointEntity.fromObject(ecopoint) : undefined,
            });
        } catch (error) {
            throw new ConvertionError(JSON.stringify(obj), 'CollectionEntity');
        }
    }
}
