import {
    FilledInput,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { ChangeEvent, useState } from 'react';
import { useStyles } from './styles';

type PasswordInputProps = {
    autoFocus?: boolean;
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    fullWidth: boolean;
    error: boolean;
    helperText: string;
    disabled?: boolean;
    name: string;
    label: string;
    variant: 'filled' | 'outlined';
};

export const PasswordInput: React.FC<PasswordInputProps> = ({
    value,
    onChange,
    className,
    fullWidth,
    error,
    helperText,
    disabled,
    name,
    variant,
    label,
    autoFocus,
}: PasswordInputProps) => {
    const [show, setShow] = useState(false);
    const classes = useStyles();
    const handleShow = () => {
        setShow(!show);
    };
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <FormControl error={error} variant={variant} className={className} fullWidth={fullWidth} disabled={disabled}>
            <InputLabel htmlFor="adornment-password" className={variant === 'outlined' ? classes.outlinedLabel : ''}>
                {label}
            </InputLabel>
            {variant === 'outlined' ? (
                <OutlinedInput
                    name={name}
                    id="adornment-password"
                    type={show ? 'text' : 'password'}
                    value={value}
                    onChange={onChange}
                    autoFocus={autoFocus}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleShow}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {show ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            ) : (
                <FilledInput
                    name={name}
                    id="filled-adornment-password"
                    type={show ? 'text' : 'password'}
                    value={value}
                    onChange={onChange}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleShow}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {show ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            )}
            <FormHelperText id="component-helper-text">{helperText}</FormHelperText>
        </FormControl>
    );
};
