import { IModel } from '../../../../core/domain/interfaces/model_interface';

type CategoryModelArgs = {
    id: number;
    name: string;
};

export class CategoryModel implements IModel {
    _type = 'CategoryModel';

    id: number;
    name: string;

    constructor({ id, name }: CategoryModelArgs) {
        this.id = id;
        this.name = name;
    }
}
