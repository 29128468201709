import { UserModel } from '../../../../../core/domain/models/user_model';

export interface IIdle {
    _type: 'Idle';
}
export interface ILoaded {
    _type: 'Loaded';
    user: UserModel;
}
export interface ILoading {
    _type: 'Loading';
}
export interface ILoggedOut {
    _type: 'LoggedOut';
}

export type AuthenticationState = IIdle | ILoaded | ILoading | ILoggedOut;

export const initialState: AuthenticationState = { _type: 'Idle' };
