import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { EcoPointModel } from '../models/ecopoint_model';
import { IEcoPointRepository } from '../repositories/ecopoint_repository_interface';

export class DeleteEcoPoint implements IUseCase<void, EcoPointModel> {
    ecopointRepository: IEcoPointRepository;

    constructor(ecopointRepository: IEcoPointRepository) {
        this.ecopointRepository = ecopointRepository;
    }

    async execute(ecopoint: EcoPointModel): Promise<void | IFailure> {
        return this.ecopointRepository.deleteEcoPoint(ecopoint);
    }
}
