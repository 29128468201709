import LocalizedStrings, { LocalizedStringsMethods } from 'react-localization';
import { collectionStrings, CollectionStrings } from './ES/collections';
import { CouponsStrings, couponStrings } from './ES/coupons';
import { dataTableStrings, DataTableStrings } from './ES/datatable';
import { ecopointsStrings, EcoPointsStrings } from './ES/ecopoints';
import { globalStrings, GlobalStrings } from './ES/global';
import { LoginStrings, loginStrings } from './ES/login';
import { PasswordRecoveryStrings, passwordRecoveryStrings } from './ES/password_recovery';
import { PurchasesStrings, purchaseStrings } from './ES/purchases';
import { storeStrings, StoreStrings } from './ES/stores';

export class Language {
    static set = (key: 'es') => {
        Language.strings.setLanguage(key);
    };
    static strings: LocalizedStringsMethods &
        GlobalStrings &
        LoginStrings &
        DataTableStrings &
        PasswordRecoveryStrings &
        StoreStrings &
        EcoPointsStrings &
        PurchasesStrings &
        CollectionStrings &
        CouponsStrings = new LocalizedStrings({
        es: {
            ...globalStrings,
            ...loginStrings,
            ...passwordRecoveryStrings,
            ...dataTableStrings,
            ...storeStrings,
            ...ecopointsStrings,
            ...couponStrings,
            ...purchaseStrings,
            ...collectionStrings,
        },
    });
}
