import { IModel } from '../../../../core/domain/interfaces/model_interface';

export type ResidueModelArgs = {
    name: string;
    image?: string | undefined;
};

export class ResidueModel implements IModel {
    _type = 'ResidueModel';
    name: string;
    image?: string | undefined;

    constructor({ name, image }: ResidueModelArgs) {
        this.name = name;
        this.image = image;
    }
}
