import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        card: {
            margin: '0 auto 16px auto',
            height: 'min-content',
            width: '100%',
            maxWidth: '400px',
        },
        image_viewport: {
            position: 'relative',
            paddingTop: '75%',
            width: '100%',
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '15px 15px 0px 0px',
            position: 'absolute',
            top: 0,
        },
        info: {
            height: '50%',
            padding: '16px 16px 16px 16px',
            borderTop: `1px solid ${theme.colors.greyTransparent}`,
        },
        title: {
            fontSize: '1.4rem',
            fontWeight: 'lighter',
        },
        description: {
            fontSize: '15px',
        },
        secondaryText: {
            fontSize: '11px',
            fontWeight: 'lighter',
        },
        terms: {
            textDecoration: 'underline',
        },
        finishDate: {
            fontWeight: 'bold',
        },
        pt: {
            paddingTop: '1rem',
        },
        price: {
            fontWeight: 'bolder',
            fontSize: '1.3rem',
        },
    });
});
