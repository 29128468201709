import { Failure } from '../../../../core/domain/failures/failure';
import { ValidationFailure } from '../../../../core/domain/failures/validation_failure';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { Bloc } from '../../../../core/presentation/bloc';
import { FormState } from '../../../../core/presentation/bloc/form_states/form_states';
import { Language } from '../../../../core/presentation/strings/LanguageManager';
import { EcoPointModel } from '../../../ecopoints/domain/models/ecopoint_model';
import { IValidateCollectionsValidations } from '../../data/repositories/validations/validate_collections_validations';
import { CollectionModel } from '../../domain/models/collection_model';
import { ValidateCollectionsParams } from '../../domain/usecases/validate_collections';

export type CollectionValidationFormBlocInputs = {
    ecopoint: EcoPointModel | undefined;
    userCode: string;
    ecopoints: EcoPointModel[];
};

export const emptyInputs: FormState<CollectionValidationFormBlocInputs, CollectionModel[]> = {
    _type: 'Idle',
    inputs: { ecopoint: undefined, userCode: '', ecopoints: [] },
    action: 'update',
};

type CollectionsValidationFormBlocArgs = {
    validateCollectionsUseCase: IUseCase<CollectionModel[], ValidateCollectionsParams>;
    getUserEcoPointsUseCase: IUseCase<DataTableResponse<EcoPointModel>, DataTableParams>;
    getCachedEcoPointUseCase: IUseCase<EcoPointModel, void>;
    cacheEcoPointUseCase: IUseCase<boolean, EcoPointModel>;
};
export class CollectionValidationFormBloc extends Bloc<
    FormState<CollectionValidationFormBlocInputs, CollectionModel[]>
> {
    validateCollectionsUseCase: IUseCase<CollectionModel[], ValidateCollectionsParams>;
    getUserEcoPointsUseCase: IUseCase<DataTableResponse<EcoPointModel>, DataTableParams>;
    getCachedEcoPointUseCase: IUseCase<EcoPointModel, void>;
    cacheEcoPointUseCase: IUseCase<boolean, EcoPointModel>;

    constructor({
        validateCollectionsUseCase,
        getUserEcoPointsUseCase,
        getCachedEcoPointUseCase,
        cacheEcoPointUseCase,
    }: CollectionsValidationFormBlocArgs) {
        super(emptyInputs);
        this.validateCollectionsUseCase = validateCollectionsUseCase;
        this.getUserEcoPointsUseCase = getUserEcoPointsUseCase;
        this.getCachedEcoPointUseCase = getCachedEcoPointUseCase;
        this.cacheEcoPointUseCase = cacheEcoPointUseCase;
    }

    async fillSelectors(): Promise<void> {
        const ecopointsResponse = await this.getUserEcoPointsUseCase.execute({
            filters: [],
            order: undefined,
            page: 0,
            size: 0,
        });
        const ecopoints: EcoPointModel[] =
            ecopointsResponse instanceof Failure ? [] : (ecopointsResponse as DataTableResponse<EcoPointModel>).data;
        this.changeState({ ...this.state, inputs: { ...this.state.inputs, ecopoints: ecopoints } });
    }

    async getCachedEcoPoint(): Promise<void> {
        const result = await this.getCachedEcoPointUseCase.execute();
        if (result instanceof EcoPointModel)
            return this.changeState({
                _type: 'InputChanged',
                action: 'update',
                inputs: { ...this.state.inputs, ecopoint: result },
            });
    }

    async changeEcoPoint(ecopoint: EcoPointModel | undefined): Promise<void> {
        if (ecopoint) this.cacheEcoPointUseCase.execute(ecopoint);
        const { inputs, action } = this.state;
        this.changeState({ _type: 'InputChanged', inputs: { ...inputs, ecopoint: ecopoint }, action });
    }

    async changeCode(userCode: string): Promise<void> {
        const { inputs, action } = this.state;
        this.changeState({ _type: 'InputChanged', inputs: { ...inputs, userCode: userCode }, action });
    }

    async submit(): Promise<void> {
        const { action, inputs } = this.state;
        if (action !== 'update') return;
        if (!inputs.ecopoint)
            return this.changeState({
                _type: 'Failure',
                failure: new ValidationFailure<IValidateCollectionsValidations>({
                    ecopoint: {
                        code: 'required_error',
                        message: Language.strings.collection_ecopoint_required_error,
                    },
                }),
                inputs,
                action,
            });

        this.changeState({ action, inputs, _type: 'Submitting' });
        const result = await this.validateCollectionsUseCase.execute({
            userCode: inputs.userCode,
            ecopoint: inputs.ecopoint,
        });
        if (result instanceof Failure) {
            this.changeState({ action, inputs, _type: 'Failure', failure: result });
            return;
        }
        this.changeState({ action, inputs, _type: 'Success', data: result as CollectionModel[] });
    }

    async clear(): Promise<void> {
        this.changeState({ ...this.state, _type: 'Cleared', inputs: { ...this.state.inputs, userCode: '' } });
    }
}
