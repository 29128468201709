import { IFailure } from '../../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../../core/domain/interfaces/usecase_interface';
import { UserModel } from '../../../../../core/domain/models/user_model';
import { IAuthenticationRepository } from '../../repositories/interfaces/authentication_repository_interface';

export type LoginParams = {
    username: string;
    password: string;
};

export class Login implements IUseCase<UserModel, LoginParams> {
    repository: IAuthenticationRepository;

    constructor(repository: IAuthenticationRepository) {
        this.repository = repository;
    }

    async execute({ username, password }: LoginParams): Promise<UserModel | IFailure> {
        return this.repository.login({ username, password });
    }
}
