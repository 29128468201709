import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        outlinedLabel: {
            ['&.MuiInputLabel-shrink']: {
                backgroundColor: 'white',
                padding: '0 5px',
            },
        },
    }),
);
